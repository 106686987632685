import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { t, Trans } from "@lingui/macro";
import { InfoIcon } from "@WahooFitness/redesignr";
import { FourDPQuizResultsType } from "@WahooFitness/cloud-client-types";
import ErrorBoundary from "@/components/ErrorBoundary";
import Loader from "./Loader";
import FitnessProfileAccordion from "@/components/FitnessProfile/FitnessProfileAccordion";
import FourDPChart from "@/components/FitnessProfile/FourDPChart";
import ProfileDescription from "@/components/FitnessProfile/ProfileDescription";
import useQuizResults from "./useQuizResults";
import ResultsHeader from "./ResultsHeader";
import { useInView } from "react-intersection-observer";
import ResultsHeaderBar from "./ResultsHeaderBar";
import { GlobalHeader } from "@/components/GlobalHeader";

interface Props {
  results?: FourDPQuizResultsType;
  error?: string;
  setPage: Dispatch<SetStateAction<number>>;
}

const FourDPQuizResults = ({ results, error, setPage }: Props) => {
  const {
    resultsLoading,
    riderStrength,
    riderTypeData,
    riderWeakness,
    chartValues,
    rankValues,
    saveFitnessProfile,
    openInfoDrawer,
    openFitnessTestDrawer,
    discardResults,
    reloadHandler,
  } = useQuizResults(setPage, results);
  const { palette, breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("sm"));

  const { ref: headerBottom, entry } = useInView({
    initialInView: true,
    trackVisibility: true,
    delay: 200,
  });

  return (
    <ErrorBoundary
      isLoading={resultsLoading || !results}
      error={!!error}
      loadingComponent={<Loader message={t`Calculating your results...`} />}
      onReload={reloadHandler}
    >
      <GlobalHeader />
      <Box display="flex" flexDirection="column" alignItems="center" height="100%">
        <Box
          sx={{
            position: "relative",
            width: "100vw",
            backgroundColor: palette.background.paperElevation4,
          }}
          display="flex"
          pt={2}
          alignItems="center"
          flexDirection="column"
        >
          <Box
            id="inner-container-top-section"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            maxWidth="sm"
            width="100%"
            gap={1.5}
            pb={3}
          >
            <ResultsHeaderBar
              riderType={riderTypeData?.name}
              showHeaderBar={entry && !entry?.isIntersecting}
              riderTypeIcon={riderTypeData?.icon}
              handleEstimateClick={openFitnessTestDrawer}
            />
            <ResultsHeader
              icon={riderTypeData?.iconFilled}
              name={riderTypeData?.name}
              handleEstimateClick={openFitnessTestDrawer}
            />
            <Box ref={headerBottom} />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              pb={2}
              width="100%"
              maxWidth="sm"
              gap={2}
            >
              {chartValues && rankValues && (
                <FourDPChart
                  FTP={chartValues.FTP}
                  MAP={chartValues.MAP}
                  AC={chartValues.AC}
                  NM={chartValues.NM}
                  rankFTP={rankValues.FTP}
                  rankMAP={rankValues.MAP}
                  rankAC={rankValues.AC}
                  rankNM={rankValues.NM}
                  isEmpty={false}
                />
              )}
              <Box display="flex" flexDirection="row" gap={1} onClick={openInfoDrawer}>
                <InfoIcon fontSize="small" color="info" />
                <Typography variant="prose-xs-medium" color={palette.info.main}>
                  <Trans>How we calculate?</Trans>
                </Typography>
              </Box>
            </Box>
            <ProfileDescription
              hasNoCyclingFitnessProfile={!riderTypeData?.description}
              profileDescription={riderTypeData?.description ?? ""}
            />
            <Stack direction={{ xs: "column", sm: "row" }} spacing={1.5} width="100%">
              {riderStrength && (
                <FitnessProfileAccordion
                  title={t`Strength`}
                  name={riderStrength.name}
                  icon={riderStrength.icon}
                  defaultExpanded={true}
                  disabled={true}
                  isSmallScreen={isSmallScreen}
                  details={<Typography>{riderStrength.description}</Typography>}
                />
              )}
              {riderWeakness && (
                <FitnessProfileAccordion
                  title={t`Weakness`}
                  name={riderWeakness.name}
                  icon={riderWeakness.icon}
                  defaultExpanded={true}
                  disabled={true}
                  isSmallScreen={isSmallScreen}
                  details={<Typography>{riderWeakness.description}</Typography>}
                />
              )}
            </Stack>
          </Box>
          <Box
            py={1.5}
            px={2}
            gap={1.5}
            maxWidth="sm"
            display="flex"
            flexDirection="column"
            width="100%"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              onClick={saveFitnessProfile}
            >
              <Trans>Save results</Trans>
            </Button>
            <Button variant="text" size="large" fullWidth color="error" onClick={discardResults}>
              <Trans>Discard results</Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default FourDPQuizResults;
