import { Box, Typography } from "@mui/material";
import { motion, MotionConfig } from "motion/react";

function AnimatedFourDPStepTitle({
  step,
  title,
  subtitle,
}: {
  step: number;
  title: string;
  subtitle: string;
}) {
  return (
    <MotionConfig transition={{ type: "spring", damping: 20, stiffness: 300 }}>
      <Box
        display="flex"
        flexDirection="column"
        pt={[5, 7.5]}
        rowGap={1.5}
        component={motion.div}
        key={step}
        variants={{ hide: { opacity: 0 }, show: { opacity: 1 } }}
        animate="show"
        initial="hide"
        transition={{ staggerChildren: 0.2 }}
        width="100%"
        pb={2.5}
      >
        <Typography
          color="text.primary"
          component={motion.span}
          variant="ui-4xl-bold"
          variants={{ hide: { opacity: 0, y: 4 }, show: { opacity: 1, y: 0 } }}
        >
          {title}
        </Typography>

        <Typography
          variants={{ hide: { opacity: 0, y: 4 }, show: { opacity: 1, y: 0 } }}
          component={motion.span}
          variant="prose-base"
          color="text.secondary"
        >
          {subtitle}
        </Typography>
      </Box>
    </MotionConfig>
  );
}

export default AnimatedFourDPStepTitle;
