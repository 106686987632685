import { motion } from "motion/react";

function AttackGraph({ activeStep, stepId }: { activeStep: number; stepId: number }) {
  return (
    <>
      <motion.path
        d="M12 289.42C12 289.42 47.7494 230.206 65.6241 112C101.396 348.56 137.5 334.011 172.939 156.592C209.043 334.011 240.5 325.473 282.254 184.106C292 206.877 319 269 337 289.419"
        stroke="url(#paint1_linear_3516_20250)"
        strokeWidth="24"
        strokeLinecap="round"
        strokeLinejoin="round"
        initial="hidden"
        variants={{
          show: {
            pathLength: [0.001, 1],
            pathOffset: [0.0001],
            opacity: 1,
            transition: { duration: 2, ease: [0.25, 0.1, 0.25, 1] },
          },
          hidden: {
            pathLength: [1, 0.001],
            pathOffset: [0.0001, 0.9999],
            opacity: 0,
            transition: { pathLength: { opacity: 0.2 } },
          },
        }}
        animate={activeStep === stepId ? "show" : "hidden"}
      />
      <defs>
        <linearGradient
          id="paint1_linear_3516_20250"
          x1="186.302"
          y1="112"
          x2="186.302"
          y2="290"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8400" />
          <stop offset="1" stopColor="#FFCEA1" />
        </linearGradient>
      </defs>
    </>
  );
}

export default AttackGraph;
