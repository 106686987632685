import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import { useDeviceSettings } from "./useDeviceSettings";
import { MenuList } from "@WahooFitness/redesignr";
import ErrorBoundary from "../ErrorBoundary";
import WorkoutProfilesAlert from "./WorkoutProfilesAlert";
import useNavUpOnDeviceDisconnect from "./useNavUpOnDeviceDisconnect";
import { GlobalHeader } from "../GlobalHeader";

const DeviceSettings = ({ appToken }: { appToken: string }) => {
  const {
    configItems,
    configError,
    configIsLoading,
    reloadConfig,
    showWorkoutProfilesAlert,
    onCloseWorkoutProfilesAlert,
  } = useDeviceSettings(appToken);

  useNavUpOnDeviceDisconnect(appToken);

  return (
    <>
      <GlobalHeader title={t`All Settings`} />
      <Box height="100%" width="100%" display="flex" flexDirection="column" overflow="hidden">
        <ErrorBoundary error={configError} isLoading={configIsLoading} onReload={reloadConfig}>
          {showWorkoutProfilesAlert && (
            <WorkoutProfilesAlert onClose={onCloseWorkoutProfilesAlert} />
          )}
          <Box
            display="flex"
            flexDirection="column"
            pb={2}
            pt={1}
            height="100%"
            overflow="scroll"
            gap={4}
          >
            {configItems
              .filter((section) => !section.hidden && section.items.some((item) => !item.hidden))
              .map((section) => (
                <Box key={section.name}>
                  <MenuList
                    title={section.name}
                    listItems={section.items}
                    hidden={section.hidden}
                  />
                  {section.bottomContent && <Box px={4}>{section.bottomContent}</Box>}
                </Box>
              ))}
          </Box>
        </ErrorBoundary>
      </Box>
    </>
  );
};

export { DeviceSettings };
