import {
  getStoredValue,
  setStoredValue,
} from "@WahooFitness/wahoo-offline-mfe/services/StorageService";
import { useCallback } from "react";
import useSWR from "swr";

function useDismiss(key: string): { data: boolean; dismiss: () => void } {
  const { data, mutate } = useSWR(key, getStoredValue);

  const dismiss = useCallback(() => {
    mutate(true, { revalidate: false });
    setStoredValue(key, true);
  }, [key, mutate]);

  return { data: data as boolean, dismiss };
}

export default useDismiss;
