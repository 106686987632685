import { t } from "@lingui/macro";
import { useCallback, useMemo } from "react";
import { useDialogContext, useDrawerContext } from "@WahooFitness/wahoo-offline-mfe";

import useSubscription, { SubscriptionState } from "@/hooks/useSubscription";
import usePaywall from "@/hooks/usePaywall";
import { Typography } from "@mui/material";
import { MenuList, MenuListItemVariant, SystmAppIcon } from "@WahooFitness/redesignr";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { ClickTarget, EventType, SourcePage } from "@/services/Analytics/EventTypes";
import { useLogEvent } from "@/services/Analytics/useLogEvent";

const SYSTM_CONTENT_DETAILS_URL = "https://systm.wahoofitness.com/content-details/";

const useFitnessAssessmentClickHandler = () => {
  const { subscriptionState } = useSubscription();
  const { showPaywall } = usePaywall();
  const { handleClose } = useDialogContext();
  const { setDrawer, handleClose: handleCloseDrawer } = useDrawerContext();
  const { fitnessTestId } = useFlaggedFeatures();
  const { logEvent } = useLogEvent();

  const noActiveSub = useMemo(
    () => [SubscriptionState.CanTrial, SubscriptionState.Expired].includes(subscriptionState),
    [subscriptionState]
  );

  const logFitnessTestClickEvent = useCallback(() => {
    logEvent("athlete_profile", {
      event_type: EventType.click,
      click_target: ClickTarget.fitness_assessment,
      source_page_name: SourcePage.fitness_profile,
    });
  }, [logEvent]);

  const handleFitnessTestClick = () => {
    logFitnessTestClickEvent();
    if (noActiveSub) {
      showPaywall({
        handleClose: handleClose,
      });
    } else {
      setDrawer({
        open: true,
        title: t`Fitness Test`,
        children: (
          <MenuList
            listItems={[
              {
                id: "0",
                content: <Typography variant="ui-lg-bold">{t`Open in SYSTM app`}</Typography>,
                secondaryContent: t`For immersive video & audio experience`,
                variant: MenuListItemVariant.ExternalLink,
                action: handleCloseDrawer,
                linkLocation: `${SYSTM_CONTENT_DETAILS_URL}${fitnessTestId}`,
                icon: <SystmAppIcon height={24} width={24} style={{ borderRadius: 4 }} />,
              },
            ]}
          />
        ),
        hidePuller: true,
        hideDivider: true,
        globalBottomPadding: "16px",
      });
    }
  };

  return { handleFitnessTestClick };
};

export default useFitnessAssessmentClickHandler;
