import Box from "@mui/material/Box";
import useWorkoutPages from "./useWorkoutPages";
import { MenuList } from "@WahooFitness/redesignr";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Trans, t } from "@lingui/macro";
import ErrorBoundary from "@/components/ErrorBoundary";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";
import { GlobalHeader } from "@/components/GlobalHeader";

const WorkoutPages = ({ elevation }: { elevation?: boolean }) => {
  const { navigateBack } = useHeaderContext();
  const {
    pageMenuLists,
    flushPendingSave,
    addCustomPage,
    resetPagesToDefault,
    showDeleteDialog,
    onCloseDeleteDialog,
    deletePage,
    editing,
    toggleEditing,
    movePage,
    pagesAreLoading,
    pagesError,
    maxPageNameLength,
  } = useWorkoutPages(elevation);
  const { setDialog, handleClose } = useDialogContext();

  const [newPageName, setNewPageName] = useState("");
  const handleNewPageNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setNewPageName(event.target.value),
    [setNewPageName]
  );

  const [addNewPageDialogOpen, setAddNewPageDialogOpen] = useState(false);

  const handleOpenAddNewPageDialog = useCallback(
    () => setAddNewPageDialogOpen(true),
    [setAddNewPageDialogOpen]
  );

  const handleCloseAddNewPageDialog = useCallback(
    () => setAddNewPageDialogOpen(false),
    [setAddNewPageDialogOpen]
  );

  const resetClicked = useCallback(() => {
    setDialog({
      open: true,
      title: t`Reset all pages to Wahoo default`,
      body: t`Are you sure you want to reset all pages to the Wahoo default?`,
      actions: [
        { text: t`Cancel` },
        {
          text: t`Reset`,
          action: () => {
            resetPagesToDefault();
            handleClose();
          },
          color: "error",
        },
      ],
    });
  }, [handleClose, resetPagesToDefault, setDialog]);

  useEffect(() => {
    if (!showDeleteDialog) return;
    setDialog({
      open: showDeleteDialog,
      title: t`Delete page`,
      body: t`Are you sure you want to delete this page?`,
      actions: [
        {
          text: t`Cancel`,
          action: () => {
            onCloseDeleteDialog();
            handleClose();
          },
        },
        {
          text: t`OK`,
          action: () => {
            deletePage();
            onCloseDeleteDialog();
            handleClose();
          },
          color: "error",
        },
      ],
    });
  }, [deletePage, handleClose, onCloseDeleteDialog, setDialog, showDeleteDialog]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        movePage(result.source.index, result.destination.index);
      }
    },
    [movePage]
  );

  return (
    <>
      <GlobalHeader
        title={t`Workout pages`}
        headerAction={
          elevation ? undefined : (
            <Button variant="text" onClick={toggleEditing}>
              {editing ? <Trans>Done</Trans> : <Trans>Edit</Trans>}
            </Button>
          )
        }
        backAction={async () => {
          // TODO: this doesn't get called if user does a back swipe on iOS
          await flushPendingSave();
          navigateBack();
        }}
      />
      <Box height="100%" width="100%">
        <ErrorBoundary error={pagesError} isLoading={pagesAreLoading}>
          <Box height="100%" maxWidth="sm" py={2} display="flex" flexDirection="column">
            <DragDropContext onDragEnd={onDragEnd}>
              <StrictModeDroppable droppableId="workout-pages">
                {(provided) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    {pageMenuLists.map((menuList, index) => (
                      <Draggable
                        key={menuList.key}
                        draggableId={menuList.key}
                        index={index}
                        isDragDisabled={!editing}
                      >
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <MenuList key={menuList.key} listItems={menuList.pageListItems} />
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </StrictModeDroppable>
            </DragDropContext>
            {!elevation && (
              <Box display="flex" flexDirection="column" p={2} gap={1}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={handleOpenAddNewPageDialog}
                >
                  <Trans>Add new page</Trans>
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  color="error"
                  fullWidth
                  onClick={resetClicked}
                >
                  <Trans>Reset all pages to Wahoo default</Trans>
                </Button>
              </Box>
            )}
          </Box>
          <Dialog
            open={addNewPageDialogOpen}
            onClose={handleCloseAddNewPageDialog}
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle>{t`Name this page`}</DialogTitle>
            <DialogContent>
              <Box width="100%" pt={1} display="flex" flexDirection="column" gap={2}>
                <Typography variant="prose-base">{t`Enter a name for the new page (max ${maxPageNameLength}).`}</Typography>
                <TextField
                  value={newPageName}
                  label={t`Page name`}
                  onChange={handleNewPageNameChange}
                  fullWidth
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddNewPageDialog} size="large" variant="text">
                <Trans>Cancel</Trans>
              </Button>
              <Button
                onClick={() => addCustomPage(newPageName)}
                size="large"
                variant="text"
                disabled={newPageName.length < 1 || newPageName.length > maxPageNameLength}
              >
                <Trans>Next</Trans>
              </Button>
            </DialogActions>
          </Dialog>
        </ErrorBoundary>
      </Box>
    </>
  );
};

export default WorkoutPages;
