import { t } from "@lingui/macro";
import { Box } from "@mui/system";
import ErrorBoundary from "../ErrorBoundary";
import { GlobalHeader } from "../GlobalHeader";
import useMembership from "./useMembership";

function Membership() {
  const { userSubscription, subscriptionError, subscriptionIsLoading } = useMembership();
  return (
    <ErrorBoundary isLoading={subscriptionIsLoading} error={subscriptionError} onReload={() => {}}>
      <GlobalHeader title={t`My subscription`} />
      <Box pt={2}>{JSON.stringify(userSubscription, null, 2)}</Box>
    </ErrorBoundary>
  );
}

export default Membership;
