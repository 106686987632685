import { Trans, t } from "@lingui/macro";
import { Box, Button, Paper, Skeleton, TextField, Typography } from "@mui/material";
import { MenuList } from "@WahooFitness/redesignr";
import { postRoute, useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import WSMSensorConnectionState from "@WahooFitness/wsm-native/dist/esm/types/connection_state";
import { FocusEvent, useCallback, useEffect, useState } from "react";
import WarmingUp from "../WarmUp";
import BatteryLevelIcon from "./BatteryLevelIcon";
import DisconnectedAlert from "./DisconnectedAlert";
import SignalStrengthIcon from "./SignalStrengthIcon";
import { useDeviceConfig } from "./useDeviceConfig";
import BoltProductImage from "./BoltProductImage";
import { GlobalHeader } from "../GlobalHeader";

function DeviceConfig({ appToken }: { appToken: string }) {
  const {
    deviceConfigEnabled,
    deviceConfigLoading,
    device,
    unpairDevice,
    setDeviceName,
    connectionStateDisplay,
    sections,
  } = useDeviceConfig(appToken);

  const { setDialog, handleClose } = useDialogContext();

  const handleUnpairDevice = useCallback(async () => {
    setDialog({
      open: true,
      title: t`Unpair ELEMNT`,
      body: t`Are you sure you want to unpair your ELEMNT?`,
      actions: [
        { text: t`Cancel`, action: handleClose },
        {
          text: t`Unpair`,
          color: "error",
          action: async () => {
            await unpairDevice();
            postRoute("home");
          },
        },
      ],
    });
  }, [handleClose, setDialog, unpairDevice]);

  const [displayNameInitialized, setDisplayNameInitialized] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [displayNameInputValue, setDisplayNameInputValue] = useState("");

  useEffect(() => {
    if (!displayNameInitialized && device?.displayName) {
      setDisplayName(device?.displayName || device?.name || "");
      setDisplayNameInputValue(device?.displayName || device?.name || "");
      setDisplayNameInitialized(true);
    }
  }, [device?.displayName, device?.name, displayNameInitialized]);

  const onDisplayNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.includes("\n")) {
        return;
      }
      setDisplayName(event.target.value || device?.name || "");
      setDisplayNameInputValue(event.target.value);
      setDeviceName(event.target.value);
    },
    [device?.name, setDeviceName]
  );

  const onDisplayNameFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  }, []);

  if (deviceConfigLoading) {
    return <WarmingUp />;
  }

  if (!deviceConfigEnabled) {
    return (
      <Box>
        <Trans>Page unavailable</Trans>
      </Box>
    );
  }

  if (!device) {
    return <Box>Device not paired</Box>;
  }

  return (
    <>
      <GlobalHeader title="" backAction={() => postRoute("home")} />
      <Box
        display="flex"
        flexDirection="column"
        overflow="hidden"
        width="100%"
        height="100%"
        px={2}
      >
        <DisconnectedAlert device={device} />
        <Box maxWidth="sm" display="flex" flexDirection="column" overflow="scroll" py={2} gap={4}>
          <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
            <BoltProductImage
              boltType={device.boltType}
              appToken={appToken}
              height={150}
              fallback={<Skeleton variant="rectangular" height={150} width={94} />}
            />
            <Typography variant="ui-2xl-medium">{displayName}</Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="flex-end"
              gap={2}
            >
              <Box display="flex" flexDirection="row" gap={0.5}>
                {device?.connectionState === WSMSensorConnectionState.CONNECTED && (
                  <SignalStrengthIcon rssi={device?.rssi} />
                )}
                <Typography variant="ui-lg-medium" color="text.secondary">
                  {connectionStateDisplay}
                </Typography>
              </Box>
              {device?.connectionState === WSMSensorConnectionState.CONNECTED &&
                device?.battery?.percent !== undefined &&
                (device.battery.percent !== -1 || device.battery.charging) && (
                  <Box display="flex" flexDirection="row" gap={0.5}>
                    <BatteryLevelIcon
                      batteryPercentage={device.battery.percent}
                      isCharging={!!device.battery.charging}
                    />
                    <Typography variant="ui-lg-medium" color="text.secondary">
                      {t`${device.battery.percent}%`}
                    </Typography>
                  </Box>
                )}
            </Box>
          </Box>
          <Box width="100%">
            <Paper elevation={4}>
              <Box p={2}>
                <TextField
                  variant="filled"
                  label={t`Name`}
                  value={displayNameInputValue}
                  onChange={onDisplayNameChange}
                  onFocus={onDisplayNameFocus}
                  fullWidth
                  multiline
                />
              </Box>
            </Paper>
          </Box>
          {sections.map((section) => (
            <MenuList key={section.id} listItems={section.items} disableGutters />
          ))}
          <Button variant="text" color="error" size="large" onClick={handleUnpairDevice}>
            <Trans>Unpair ELEMNT</Trans>
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default DeviceConfig;
