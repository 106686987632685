import { t } from "@lingui/macro";
import { MenuList } from "@WahooFitness/redesignr";
import { Box } from "@mui/system";
import { GlobalHeader } from "../GlobalHeader";

const DeviceInfo = ({
  firmwareVersion,
  serialNumber,
}: {
  firmwareVersion: string;
  serialNumber: string;
}) => {
  return (
    <>
      <GlobalHeader title={t`Device info`} />
      <Box
        maxWidth="sm"
        width="100%"
        height="100%"
        overflow="scroll"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <MenuList
          width="100%"
          listItems={[
            {
              id: "firmware-version",
              content: t`ELEMNT version`,
              secondaryContent: firmwareVersion,
            },
            {
              id: "serial-number",
              content: t`ELEMNT serial number`,
              secondaryContent: serialNumber,
            },
          ]}
        />
      </Box>
    </>
  );
};

export default DeviceInfo;
