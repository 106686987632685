import React, { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import useSettings from "./useSettings";
import LogOutButton from "./LogOutButton";
import SubmitFeedbackButton from "./SubmitFeedbackButton";
import About from "./About";
import { Profile } from "@WahooFitness/cloud-client-types";
import { t } from "@lingui/macro";
import { useUserContext, postRoute, hasMinProfile } from "@WahooFitness/wahoo-offline-mfe";
import { MenuList } from "@WahooFitness/redesignr";
import ErrorBoundary from "../ErrorBoundary";
import { GlobalHeader } from "../GlobalHeader";

const Settings: React.FC = () => {
  const { currentUser } = useUserContext();
  const { settingsItems, isLoading: settingsAreLoading } = useSettings(currentUser);

  const goHome = useCallback(() => postRoute("home"), []);

  const showSubmitFeedback = useMemo(
    () => hasMinProfile(Profile.beta, currentUser?.app?.profile),
    [currentUser?.app?.profile]
  );

  return (
    <>
      <GlobalHeader title={t`My account`} backAction={goHome} />
      <Box pb={2} pt={1}>
        <ErrorBoundary error={undefined} isLoading={settingsAreLoading}>
          {settingsItems.map((section) => (
            <MenuList key={section[0].id} listItems={section} />
          ))}
          <Box display="flex" flexDirection="column" rowGap={2} my={2} mx={2}>
            {showSubmitFeedback && <SubmitFeedbackButton />}
            <LogOutButton />
          </Box>
          <About profile={currentUser?.app?.profile} />
        </ErrorBoundary>
      </Box>
    </>
  );
};

export default Settings;
