import { OverflowMenuHorizontal } from "@carbon/icons-react";
import { t, Trans } from "@lingui/macro";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useCallback, useState } from "react";
import useEditMaps from "./useEditMaps";
import { useNavigate } from "react-router";
import useTilePackNames from "./useTilePackNames";
import useNavUpOnDeviceDisconnect from "../useNavUpOnDeviceDisconnect";
import MapRegionEmbed from "./MapRegionEmbed";
import { WSMBoltTilePack } from "@WahooFitness/wsm-native/dist/esm/types/bolt_map";
import { GlobalHeader } from "@/components/GlobalHeader";

const MapRegion = ({ appToken, tilePackId }: { appToken: string; tilePackId: number }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  useNavUpOnDeviceDisconnect(appToken);

  const [tilePacks, setTilePacks] = useState<WSMBoltTilePack[]>([]);

  const {
    editing,
    onDeleteClick,
    disableEditing,
    enableEditing,
    handleMapSelect,
    pendingDeleteTilePackIds,
  } = useEditMaps(appToken, tilePacks);

  const handleEditClick = useCallback(() => {
    setMenuAnchorEl(null);
    enableEditing();
  }, [enableEditing]);

  const { tilePackNameTranslations } = useTilePackNames();

  const navigate = useNavigate();
  const handleUpdateAllClick = useCallback(() => {
    navigate(`/device-config/${appToken}/maps/update`);
    closeMenu();
  }, [appToken, closeMenu, navigate]);

  const handleTilePackLoad = useCallback((tilePack: WSMBoltTilePack) => {
    setTilePacks(tilePack.tilePacks || []);
  }, []);

  return (
    <>
      <GlobalHeader
        title={tilePackNameTranslations[tilePackId]}
        headerAction={
          editing ? (
            <Button variant="text" onClick={onDeleteClick}>{t`Delete`}</Button>
          ) : (
            <IconButton onClick={openMenu}>
              <OverflowMenuHorizontal size={24} />
            </IconButton>
          )
        }
        backText={editing ? t`Done` : undefined}
        backAction={editing ? disableEditing : undefined}
      />
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        p={2}
        justifyContent="space-between"
      >
        <MapRegionEmbed
          appToken={appToken}
          tilePackId={tilePackId}
          onTilePackLoad={handleTilePackLoad}
          editing={editing}
          handleMapSelect={handleMapSelect}
          pendingDeleteTilePackIds={pendingDeleteTilePackIds}
        />
        <Menu open={menuOpen} onClose={closeMenu} anchorEl={menuAnchorEl}>
          <MenuItem onClick={handleEditClick}>
            <Trans>Edit</Trans>
          </MenuItem>
          <MenuItem onClick={handleUpdateAllClick}>
            <Trans>Update all</Trans>
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default MapRegion;
