import { HeartRateZones as HeartRateZonesClient } from "@WahooFitness/cloud-client-ts";
import { HeartRateZonesResponseType } from "@WahooFitness/cloud-client-types";
import { useCallback, useEffect, useMemo } from "react";
import { StoredZones, Zone, ZoneSet } from "./useZones";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import {
  HeartRateAlgorithmEnum,
  HeartRateAlgorithmLookupType,
} from "@WahooFitness/cloud-client-types/HeartRateAlgorithmLookupType";
import { clearNulls } from "@/services/clearNulls";
import { t } from "@lingui/macro";
import {
  useCloudContext,
  useConfigContext,
  useUserContext,
  useNativeMessagingContext,
  useOfflineSWR,
} from "@WahooFitness/wahoo-offline-mfe";
import { copyZones } from "./ZonesUtils";
import { useSettingsRemoteConfig } from "@/hooks/useSettingsRemoteConfig";

const useHeartRateZones: () => StoredZones = () => {
  const { workoutProfiles, updateProfile, refetchProfiles } = useWorkoutProfilesContext();

  const { wahooToken } = useConfigContext();
  const { addRefreshListener, removeRefreshListener } = useNativeMessagingContext();
  const { mutateUser } = useUserContext();
  const { getSettingsRemoteConfigObject } = useSettingsRemoteConfig();

  const { getCloudClient } = useCloudContext();
  const heartRateZonesClient = useMemo(
    () => getCloudClient(HeartRateZonesClient),
    [getCloudClient]
  );

  const {
    data: heartRateZoneData,
    error,
    isLoading,
    mutate: mutateHeartRateZones,
  } = useOfflineSWR(["getHeartRateZones", wahooToken], async ([_key, wahooToken]) => {
    return clearNulls(await heartRateZonesClient.getForCurrentUser(wahooToken));
  });

  useEffect(() => {
    const refreshListener = addRefreshListener(mutateHeartRateZones);
    return () => removeRefreshListener(refreshListener);
  }, [addRefreshListener, mutateHeartRateZones, removeRefreshListener]);

  const extractZonesForDisplay = useCallback((zoneSet: HeartRateZonesResponseType) => {
    const zoneCount =
      zoneSet.zone_count ||
      Object.entries(zoneSet).filter(([key, value]) => key.startsWith("zone_") && value !== null)
        .length;
    return Array.from(Array(zoneCount).keys()).map((key) => {
      const prevKey = `zone_${key}` as keyof HeartRateZonesResponseType;
      const currKey = `zone_${key + 1}` as keyof HeartRateZonesResponseType;
      return {
        bottom: key ? Number(zoneSet[prevKey]) + 1 : 0,
        top: key === zoneCount - 1 ? ("max" as Zone["top"]) : Number(zoneSet[currKey]) || 0,
        name: t`Zone ${key + 1}`,
      };
    });
  }, []);

  const extractZonesForSave = useCallback((zoneSet: ZoneSet) => {
    const {
      zones,
      zone_1: _zone_1,
      zone_2: _zone_2,
      zone_3: _zone_3,
      zone_4: _zone_4,
      zone_5: _zone_5,
      zone_6: _zone_6,
      zone_7: _zone_7,
      zone_8: _zone_8,
      zone_9: _zone_9,
      zone_10: _zone_10,
      ...rest
    } = zoneSet;
    const update: Omit<ZoneSet, "zones"> = rest || {};
    zones.forEach((zone, index) => {
      if (index > 0) {
        (update[`zone_${index}` as keyof Omit<ZoneSet, "zones">] as number) = zone.bottom - 1;
      }
    });
    update.zone_count = zones.length;
    return update;
  }, []);

  const savedZoneSets: Record<number, ZoneSet> = useMemo(() => {
    const zoneSetMap = {} as Record<number, ZoneSet>;
    if (!heartRateZoneData) {
      return zoneSetMap;
    }
    for (const zoneSetIndex in heartRateZoneData) {
      const zoneSet = heartRateZoneData[zoneSetIndex];
      const zones = extractZonesForDisplay(zoneSet);
      zoneSetMap[zoneSet.id] = {
        ...zoneSet,
        zones,
        // This assigns 0 to the primary zone set, so it always displays first,
        // The indices for the non-primary zone sets are all incremented by 1 so
        // they won't be 0, but their order is maintained
        displayIndex: zoneSet.primary ? 0 : +zoneSetIndex + 1,
        name: zoneSet.name || t`Set ${+zoneSetIndex + 1}`,
        zone_count: zones.length,
      };
    }
    return zoneSetMap;
  }, [heartRateZoneData, extractZonesForDisplay]);

  const updatedAt = useMemo(() => {
    const updatedAtMap = {} as Record<number, Date | undefined>;
    if (!heartRateZoneData) {
      return updatedAtMap;
    }
    for (const zoneSet of heartRateZoneData) {
      updatedAtMap[zoneSet.id] = (zoneSet.updated_at && new Date(zoneSet.updated_at)) || undefined;
    }
    return updatedAtMap;
  }, [heartRateZoneData]);

  const updateZoneSet = useCallback(
    async (zoneSet: ZoneSet) => {
      const update = extractZonesForSave(zoneSet);
      if (wahooToken && zoneSet.id) {
        const newZoneSet = await heartRateZonesClient.put(wahooToken, update, zoneSet.id);
        mutateHeartRateZones(
          heartRateZoneData?.map((set) => {
            if (set.id === zoneSet.id) {
              return { ...set, ...update };
            }
            return set;
          }),

          { revalidate: false }
        );
        return newZoneSet;
      }
    },
    [extractZonesForSave, heartRateZoneData, heartRateZonesClient, mutateHeartRateZones, wahooToken]
  );

  const createZoneSet = useCallback(
    async (zoneSet: ZoneSet) => {
      const update = extractZonesForSave(zoneSet);
      if (wahooToken) {
        const newZoneSet = await heartRateZonesClient.postForCurrentUser(wahooToken, update);
        const newZoneSets = [...(heartRateZoneData || []), newZoneSet];
        mutateHeartRateZones(newZoneSets, { revalidate: false });
        mutateUser();
        return newZoneSet;
      }
    },
    [
      extractZonesForSave,
      heartRateZoneData,
      heartRateZonesClient,
      mutateHeartRateZones,
      mutateUser,
      wahooToken,
    ]
  );

  const saveZoneSet = useCallback(
    async (zoneSet: ZoneSet) => {
      if (zoneSet.id === -1) {
        return createZoneSet(zoneSet);
      } else {
        return updateZoneSet(zoneSet);
      }
    },
    [createZoneSet, updateZoneSet]
  );

  const autoCalcZoneSet = useCallback(
    async (currentZoneSet: ZoneSet, params: HeartRateAlgorithmLookupType) => {
      if (wahooToken) {
        const newZoneSet = await heartRateZonesClient.algorithmLookup(wahooToken, {
          heart_rate_zone_algorithm_id: params.threshold_heart_rate
            ? HeartRateAlgorithmEnum.Threshold
            : undefined,
          threshold_heart_rate: params.threshold_heart_rate,
        });
        return {
          ...currentZoneSet,
          ...newZoneSet,
          // cloud is passing back a decimal, but doesn't accept a decimal
          threshold_heart_rate: newZoneSet.threshold_heart_rate
            ? Math.round(newZoneSet.threshold_heart_rate)
            : undefined,
          zones: extractZonesForDisplay(newZoneSet),
        };
      }
    },
    [extractZonesForDisplay, heartRateZonesClient, wahooToken]
  );

  const clearAlgorithmInfo = useCallback((zoneSets: Record<number, ZoneSet>, zoneSetId: number) => {
    zoneSets[zoneSetId].algorithm_version = undefined;
    zoneSets[zoneSetId].heart_rate_zone_algorithm_id = undefined;
    zoneSets[zoneSetId].resting = undefined;
    return zoneSets;
  }, []);

  const maxZoneValue = 254;

  const zoneCountChoices = useMemo(
    () => getSettingsRemoteConfigObject<number[]>("HEART_RATE_ZONE_COUNT_CHOICES") ?? [5],
    [getSettingsRemoteConfigObject]
  );

  const autoCalculateVariant = "thr";

  const profileField = "heart_rate_zone_id";

  const primaryZoneSetId = useMemo(
    () => heartRateZoneData?.find((zone) => zone.primary)?.id,
    [heartRateZoneData]
  );

  const deleteZoneSet = useCallback(
    async (zoneSetId: number) => {
      await Promise.all(
        workoutProfiles
          .filter((profile) => profile.heart_rate_zone_id === zoneSetId)
          .map(
            async (profile) =>
              await updateProfile(profile.id, "heart_rate_zone_id", primaryZoneSetId)
          )
      );
      await heartRateZonesClient.delete(wahooToken, zoneSetId);
      if (heartRateZoneData) {
        mutateHeartRateZones(heartRateZoneData.filter((zoneSet) => zoneSet.id !== zoneSetId));
        mutateUser();
      }
      refetchProfiles();
    },
    [
      heartRateZoneData,
      heartRateZonesClient,
      mutateHeartRateZones,
      mutateUser,
      primaryZoneSetId,
      refetchProfiles,
      updateProfile,
      wahooToken,
      workoutProfiles,
    ]
  );

  const reloadZones = useCallback(() => mutateHeartRateZones(), [mutateHeartRateZones]);

  const maxAutoCalculateValues = useMemo(() => ({ thr: 200 }), []);

  const createValidZones = useCallback(
    (zoneSetId: number, startingIndex: number, zoneSets: Record<number, ZoneSet>) => {
      const correctedZones = copyZones(zoneSets);
      const zoneSet = correctedZones[zoneSetId].zones;
      zoneSet[startingIndex].bottom = Math.round(zoneSet[startingIndex].bottom);
      zoneSet[startingIndex - 1].top = zoneSet[startingIndex].bottom - 1;

      // ensure zones are in ascending order. Assumes only the zone at startingIndex
      // has been changed, and the zones were in a valid state before the change
      for (let i = startingIndex; i < zoneSet.length; i++) {
        if (zoneSet[i].bottom <= zoneSet[i - 1].bottom) {
          zoneSet[i].bottom = +zoneSet[i - 1].bottom + 1;
          zoneSet[i - 1].top = +zoneSet[i - 1].bottom;
          // if the value at the starting index was just corrected, there is no need to continue.
          // The values above the starting index can't be less than the value at the starting index if the starting index value was lowered.
          if (i === startingIndex) {
            break;
          }
        }
        // if the value at startingIndex was already above the value below it, it may have been raised, so the values above it need to be checked
        else if (i === startingIndex) {
          continue;
        }
        // if i is an index above startingIndex, and the value was already above the value below it, then everything should already be ascending
        else {
          break;
        }
      }

      //ensure corrections didn't put zones above the max value
      for (let i = zoneSet.length - 1; i > 0; i--) {
        const topValue = zoneSet[i].top === "max" ? maxZoneValue : zoneSet[i].top;
        if (typeof topValue === "number" && zoneSet[i].bottom > topValue) {
          zoneSet[i].bottom = +topValue;
          zoneSet[i - 1].top = +topValue - 1;
        } else {
          break;
        }
      }
      return correctedZones;
    },
    [maxZoneValue]
  );

  const zonesSetIsValid: (zoneSet: ZoneSet, zoneCount: number) => boolean = useCallback(
    (zoneSet: ZoneSet, zoneCount: number) => {
      return (
        zoneSet.zones.every(
          (zone, index, array) => index === 0 || zone.bottom > array[index - 1].bottom
        ) && zoneCountChoices?.includes(zoneCount)
      );
    },
    [zoneCountChoices]
  );

  return {
    savedZoneSets,
    updatedAt,
    saveZoneSet,
    maxZoneValue,
    zoneCountChoices,
    autoCalculateVariant,
    profileField,
    autoCalcZoneSet,
    primaryZoneSetId,
    deleteZoneSet,
    clearAlgorithmInfo,
    error,
    isLoading,
    reloadZones,
    maxAutoCalculateValues,
    zonesSetIsValid,
    createValidZones,
    extractZonesForDisplay,
  };
};

export default useHeartRateZones;
