import { useCallback, useMemo, useState } from "react";
import { StepContent } from "../AthleteOnboarding/CustomStepper";
import { t } from "@lingui/macro";
import SprintGraph from "./Graphs/Sprint";
import AttackGraph from "./Graphs/Attack";
import BreakawayGraph from "./Graphs/Breakaway";
import EndureGraph from "./Graphs/Endure";
import PotentialArrow from "./Graphs/PotentialArrow";
import { Tick } from "./Graphs/XAxis";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import FourDPPages from "../4dpQuiz/FourDPPages";
import { useLogEvent } from "@/services/Analytics/useLogEvent";
import { ClickTarget, SourcePage } from "@/services/Analytics/EventTypes";
import { FourDPLogoWithCopySvg } from "./FourDPLogoWithCopySvg";

export type EducationStepContent = StepContent & {
  buttonName: string;
  headerTitle: string;
  hideStepper?: boolean;
  description?: string;
  power?: string;
  endAdornment?: JSX.Element;
  ticks: Tick[];
};
function useFourDpEducation(
  isStandAlone: boolean,
  setPage?: (page: number) => void,
  finishedEducation?: boolean,
  setFinishedEducation?: (finishedEducation: boolean) => void
) {
  const [activeStep, setActiveStep] = useState(finishedEducation ? 4 : 0);
  const { navigateBack } = useHeaderContext();
  const { logEvent } = useLogEvent();

  const steps: EducationStepContent[] = useMemo(
    () => [
      {
        stepId: 0,
        stepName: "intro",
        headline: t`Intro to the core metrics of Athlete Profile.`,
        subheader: t`Your cycling Athlete Profile, based on 4-Dimensional Power (4DP), looks at four key metrics to give you a full picture of your abilities. These metrics help Wahoo calibrate workouts to your abilities, suggest the best plans for you, and provide clear feedback after each activity.`,
        component: <FourDPLogoWithCopySvg activeStep={activeStep} stepId={0} />,
        skippable: false,
        onContinue: () => {},
        disabled: false,
        buttonName: t`Next`,
        headerTitle: isStandAlone ? t`Estimate your Athlete Profile.` : t`Values explained`,
        hideStepper: true,
        ticks: [],
      },
      {
        stepId: 1,
        stepName: "sprint",
        headline: "Sprint",
        subheader: t`Neuromuscular Power (NM)`,
        component: <SprintGraph activeStep={activeStep} stepId={1} />,
        skippable: false,
        onContinue: () => {},
        disabled: false,
        buttonName: t`Next`,
        headerTitle: t`Values explained`,
        description: t`Sprint, also known as Neuromuscular Power (NM), measures your ability to produce maximum power in a short burst. It’s assessed through a 5-second, all-out effort where you balance pushing as hard as possible while pedaling as fast as possible. Sprint is crucial for identifying if you have natural sprinter traits and whether you need to improve your pedaling efficiency.`,
        power: t`5-Seconds Max Power`,
        ticks: [
          {
            label: t`Time`,
            height: 20,
            labelVariant: "ui-sm",
          },
          ...Array.from({ length: 4 }, () => ({
            height: 9,
          })),
          {
            label: t`5 sec`,
            height: 20,
            labelVariant: "ui-sm-bold",
          },
        ],
      },
      {
        stepId: 2,
        stepName: "attack",
        headline: "Attack",
        subheader: t`Anaerobic Capacity (AC)`,
        component: <AttackGraph activeStep={activeStep} stepId={2} />,
        skippable: false,
        onContinue: () => {},
        disabled: false,
        buttonName: t`Next`,
        headerTitle: t`Values explained`,
        description: t`Attack or Anaerobic Capacity (AC), determined through a 1-minute effort completed while fatigued, is a combination of your maximal capacity and your ability to recover from hard, repeated efforts.`,
        power: t`1-Min Repeatable Power`,
        ticks: [
          {
            label: t`Time`,
            height: 20,
            labelVariant: "ui-sm",
          },
          {
            height: 20,
            label: t`1 min`,
            labelVariant: "ui-sm-bold",
          },
          {
            height: 20,
            label: t`2 min`,
            labelVariant: "ui-sm-bold",
          },
          {
            height: 20,
            label: t`3 min`,
            labelVariant: "ui-sm-bold",
          },
        ],
      },
      {
        stepId: 3,
        stepName: "breakaway",
        headline: "Breakaway",
        subheader: t`Maximum Aerobic Power (MAP)`,
        component: <BreakawayGraph activeStep={activeStep} stepId={3} />,
        skippable: false,
        onContinue: () => {},
        disabled: false,
        buttonName: t`Next`,
        headerTitle: t`Values explained`,
        description: t`Breakaway or Maximum Aerobic Power (MAP) represents the highest power you can sustain for 5 minutes. This effort is synonymous with your VO2 Max as it occurs when at your maximum oxygen consumption. Without a high Breakaway, your ability to raise your threshold is limited, one reason why focusing solely on FTP limits your potential.`,
        power: t`5-min Fresh Power`,
        ticks: [
          {
            label: t`Time`,
            height: 20,
            labelVariant: "ui-sm",
          },
          ...Array.from({ length: 4 }, () => ({
            height: 9,
          })),
          {
            label: t`5 min`,
            height: 20,
            labelVariant: "ui-sm-bold",
          },
        ],
      },
      {
        stepId: 4,
        stepName: "endure",
        headline: "Endure",
        subheader: t`Functional Threshold Power (FTP)`,
        component: <EndureGraph activeStep={activeStep} stepId={4} />,
        skippable: false,
        onContinue: () => {},
        disabled: false,
        buttonName: isStandAlone ? t`Close` : t`Start questionnaire`,
        headerTitle: t`Values explained`,
        description: t`Endure or Functional Threshold Power (FTP), is the highest power you can sustain for roughly an hour and has traditionally been seen as the ultimate endurance performance metric. However, at Wahoo, we believe a true understanding of your athletic abilities requires looking beyond FTP alone.`,
        power: t`60-min Power`,
        endAdornment: <PotentialArrow />,
        ticks: [
          {
            label: t`Time`,
            height: 20,
            labelVariant: "ui-sm",
          },
          ...Array.from({ length: 11 }, () => ({
            height: 9,
          })),
          {
            label: t`60 min`,
            height: 20,
            labelVariant: "ui-sm-bold",
          },
        ],
      },
    ],
    [activeStep, isStandAlone]
  );

  const logFourDPEducationEvent = useCallback(
    (clickTarget: ClickTarget) => {
      logEvent("4dp_education", {
        click_target: clickTarget,
        source_page_name: SourcePage.fourdp_education,
        step_name: steps?.[activeStep]?.stepName ?? undefined,
        step_ordering: steps?.[activeStep]?.stepId,
      });
    },
    [activeStep, logEvent, steps]
  );
  const handleNext = useCallback(() => {
    if (activeStep === steps.length - 1 && isStandAlone) {
      logFourDPEducationEvent(ClickTarget.close);
      navigateBack();
    } else if (activeStep === steps.length - 1) {
      logFourDPEducationEvent(ClickTarget.start_questionnaire);
      setFinishedEducation?.(true);
      setPage?.(FourDPPages.quiz);
    } else {
      logFourDPEducationEvent(ClickTarget.next);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [
    activeStep,
    isStandAlone,
    logFourDPEducationEvent,
    navigateBack,
    setFinishedEducation,
    setPage,
    steps.length,
  ]);

  const handleBack = useCallback(() => {
    logFourDPEducationEvent(ClickTarget.back);

    if (activeStep === 0) {
      navigateBack();
    }
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  }, [activeStep, logFourDPEducationEvent, navigateBack]);

  return {
    steps,
    activeStep,
    handleBack,
    handleNext,
  };
}

export default useFourDpEducation;
