/**
 * This component is used to wrap a standard React Router 6 Outlet in a frame-motion animation.
 * It will slide the new page in from the right and slide the old page out to the left.
 *
 * There is lots of magic happening in here to handle cases where we need to disable the page
 * transition animations. For example, when the app auto-routes to a sub-page because the "route"
 * is set in the initial context, or when the user swipes back on iOS.
 */

import { useHeaderContext } from "@/hooks/useHeaderContext";
import { PLATFORM_ENUM } from "@WahooFitness/cloud-client-types";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import { AnimatePresence, motion } from "motion/react";
import { cloneElement, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useOutlet, useBlocker } from "react-router";

type DisabledAnimation = {
  enter: boolean;
  exit: boolean;
};

type CustomVariantType = {
  historyAction: string;
  disabled: DisabledAnimation;
};

const ANIMATION_DURATION = 0.2;

const variants = {
  enter: ({ historyAction, disabled }: CustomVariantType) => {
    if (disabled.enter) {
      return {
        transition: { duration: 0 },
        x: 0,
        zIndex: 1,
        opacity: 1,
      };
    }
    return {
      transition: { duration: ANIMATION_DURATION },
      x: historyAction === "POP" ? "-100vw" : "100vw",
      zIndex: 0,
      opacity: 0,
    };
  },
  center: ({ historyAction: _, disabled }: CustomVariantType) => {
    return {
      transition: { duration: disabled.enter ? 0 : ANIMATION_DURATION },
      x: 0,
      zIndex: 1,
      opacity: 1,
    };
  },
  exit: ({ historyAction, disabled }: CustomVariantType) => {
    if (disabled.exit) {
      return {
        transition: { duration: 0 },
        x: 0,
        zIndex: 1,
        opacity: 1,
      };
    }
    return {
      transition: { duration: ANIMATION_DURATION },
      x: historyAction === "POP" ? "100vw" : "-100vw",
      zIndex: 0,
      opacity: 0,
    };
  },
};

const AnimatedOutlet = (): React.JSX.Element => {
  const location = useLocation();
  const { platform } = useConfigContext();
  const element = useOutlet();
  const [historyAction, setHistoryAction] = useState("PUSH");
  // This gets set to true when the user clicks the "back" button in the NavHeader component
  const [backButtonPressed, setBackButtonPressed] = useState(false);
  const { setBackButtonPressedListener } = useHeaderContext();

  // This gives the backButtonPressed setter to the header context so it can be used to disable animations
  // when navigating back on iOS
  useEffect(() => {
    setBackButtonPressedListener(() => setBackButtonPressed);
  }, [setBackButtonPressedListener]);

  const handleAnimationDone = useCallback(() => {
    // Clear the backButtonPressed flag so it is ready for the next back action
    if (backButtonPressed) {
      setBackButtonPressed(false);
    }
  }, [backButtonPressed]);

  // Get the next history action from the router, but don't block
  useBlocker(({ historyAction }) => {
    setHistoryAction(historyAction);
    return false;
  });

  // The rules for page animations are:
  // 1. If the user swipes back on iOS, disable the enter and exit animation
  // 2. If a route was set in the initial context, disable the enter animation
  const disabled = useMemo((): DisabledAnimation => {
    return {
      enter:
        (location.state?.rerouted && historyAction !== "POP") ||
        (platform === PLATFORM_ENUM.ios && historyAction === "POP" && !backButtonPressed),
      exit: platform === PLATFORM_ENUM.ios && historyAction === "POP" && !backButtonPressed,
    };
  }, [location.state?.rerouted, historyAction, platform, backButtonPressed]);

  return (
    <AnimatePresence
      mode="popLayout"
      initial={false}
      custom={{ historyAction, disabled }}
      onExitComplete={handleAnimationDone}
    >
      <motion.div
        initial="enter"
        animate="center"
        exit="exit"
        variants={variants}
        custom={{ historyAction, disabled }}
        key={location.pathname}
        style={{ height: "100%" }}
      >
        {element && cloneElement(element, { key: location.pathname })}
      </motion.div>
    </AnimatePresence>
  );
};

export { AnimatedOutlet };
