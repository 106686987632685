import { Box, Button, Chip, Paper, Stack, Typography, useTheme } from "@mui/material";
import { Time } from "@carbon/icons-react";

const ProfileCard = ({
  icon,
  tag,
  title,
  description,
  buttonText,
  time,
  onClick,
  useCardStyles = true,
}: {
  icon: string;
  tag?: string;
  title: string;
  description: string;
  buttonText?: string;
  time: string;
  onClick?: () => void;
  useCardStyles?: boolean;
}) => {
  const { palette } = useTheme();

  return (
    <Box
      component={useCardStyles ? Paper : Box}
      elevation={useCardStyles ? 4 : 0}
      p={1.5}
      display="flex"
      flexDirection="column"
      gap={1.5}
      alignItems="center"
      width="100%"
      border={useCardStyles ? "initial" : `1px solid ${palette.divider}`}
      borderRadius={2}
    >
      <Stack direction="row" gap={1.5} alignItems="flex-start" width="100%">
        <img src={icon} alt={title} style={{ height: 72, width: 72 }} />
        <Box flex={1}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="ui-md-bold">{title}</Typography>
            <Chip
              label={tag}
              size="small"
              sx={{
                borderRadius: 0.5,
                backgroundColor: "action.selected",
                color: "text.primary",
              }}
            />
          </Box>
          <Typography variant="prose-base" color="text.secondary" mb={1}>
            {description}
          </Typography>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Time color="text.secondary" height={16} width={16} />
            <Typography variant="ui-md" color="text.primary">
              {time}
            </Typography>
          </Box>
        </Box>
      </Stack>
      {buttonText && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ marginTop: 1.5 }}
          fullWidth
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

export default ProfileCard;
