import { Box } from "@mui/material";
import { motion } from "motion/react";
import { ReactNode } from "react";

function StepWrapper({ children }: { children: ReactNode }) {
  const variants = {
    hide: { opacity: 1 },
    show: { opacity: 1 },
  };

  return (
    <Box component={motion.div} variants={variants} initial="hide" animate="show" exit="hide">
      {children}
    </Box>
  );
}

export default StepWrapper;
