import { useCallback, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import WarmingUp from "@/components/WarmUp";
import { t } from "@lingui/macro";
import { useRoutesContext } from "@/hooks/useRoutesContext";
import AddRouteDrawer from "../AddRouteDrawer";
import { AddFilled } from "@carbon/icons-react";
import { useDrawerContext } from "@WahooFitness/wahoo-offline-mfe";
import useCurrentTime from "@/hooks/useCurrentTime";
import { WSMRoute } from "@WahooFitness/wsm-native/dist/esm/types/route";

import RouteList from "./RouteList";
import { GlobalHeader } from "@/components/GlobalHeader";

const RouteListContainer = ({ isCurated }: { isCurated: boolean }) => {
  const {
    routeList,
    filtersApplied,
    clearAllFilters,
    refreshRouteList,
    pendingPublicRouteIds,
    pendingRemovedPublicRouteIds,
    filterParams,
    updateFilter,
    curatedRoutes,
    sortOptions,
    sortDirections,
    sortParams,
    setSortDirection,
    setSortKey,
    selectRouteDrawerOpen,
    closeSelectRouteDrawer,
  } = useRoutesContext();
  const { setDrawer, handleClose } = useDrawerContext();

  const openAddRouteDrawer = useCallback(() => {
    setDrawer({
      open: true,
      title: t`Add a route`,
      hidePuller: true,
      children: <AddRouteDrawer handleClose={handleClose} />,
    });
  }, [handleClose, setDrawer]);

  const HeaderActionComponent = useMemo(
    () => (
      <Box mr={1}>
        <IconButton onClick={openAddRouteDrawer}>
          <AddFilled size={24} />
        </IconButton>
      </Box>
    ),
    [openAddRouteDrawer]
  );

  const currentTime = useCurrentTime(1000);

  const isPublic = useCallback(
    (route: WSMRoute) =>
      (route.expires_at &&
        new Date(route.expires_at) > currentTime &&
        !pendingRemovedPublicRouteIds.includes(route.id)) ||
      pendingPublicRouteIds.includes(route.id),
    [currentTime, pendingPublicRouteIds, pendingRemovedPublicRouteIds]
  );

  const publicRouteList = useMemo(() => {
    if (filtersApplied || isCurated) {
      return undefined;
    }
    return routeList.filter(isPublic);
  }, [filtersApplied, isCurated, isPublic, routeList]);

  const mainRouteList = useMemo(() => {
    if (isCurated) {
      return curatedRoutes;
    }
    if (filtersApplied) {
      return routeList;
    }
    return filtersApplied
      ? routeList
      : routeList.filter((route) => !publicRouteList?.includes(route));
  }, [curatedRoutes, filtersApplied, isCurated, publicRouteList, routeList]);

  if (!routeList) return <WarmingUp />;

  return (
    <>
      <GlobalHeader
        title={isCurated ? t`Routes by Wahoo` : t`My Routes`}
        headerAction={isCurated ? undefined : HeaderActionComponent}
      />
      <RouteList
        refreshRouteList={refreshRouteList}
        filterParams={filterParams}
        updateFilter={updateFilter}
        setSortKey={setSortKey}
        filtersApplied={filtersApplied}
        publicRouteList={publicRouteList}
        clearAllFilters={clearAllFilters}
        mainRouteList={mainRouteList}
        selectRouteDrawerOpen={selectRouteDrawerOpen}
        closeSelectRouteDrawer={closeSelectRouteDrawer}
        sortParams={sortParams}
        sortDirections={sortDirections}
        sortOptions={sortOptions}
        setSortDirection={setSortDirection}
        shouldHideSourceIcons={isCurated}
      />
    </>
  );
};

export default RouteListContainer;
