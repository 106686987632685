import { useAnalyticsContext, useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import { useCallback } from "react";

export const useLogEvent = () => {
  const { componentId, fitnessAppId } = useConfigContext();
  const { logEvent: logAnalyticsEvent } = useAnalyticsContext();

  const logEvent = useCallback(
    (eventName: string, eventProperties?: Record<string, unknown>) => {
      logAnalyticsEvent(eventName, {
        ...eventProperties,
        component_app_id: componentId,
        fitness_app_id: fitnessAppId,
        source_page_name: eventProperties?.source_page_name,
      });
    },
    [componentId, fitnessAppId, logAnalyticsEvent]
  );

  return {
    logEvent,
  };
};
