import { t } from "@lingui/macro";
import { Avatar, useTheme } from "@mui/material";
import { FitnessStrengths, FitnessType, FitnessWeakness } from "@WahooFitness/cloud-client-types";
import {
  RiderTypeAttackerIcon,
  RiderTypeClimberIcon,
  RiderTypePursuiterIcon,
  RiderTypeRouleurIcon,
  RiderTypeSprinterIcon,
  RiderTypeTimeTrialistIcon,
} from "@WahooFitness/redesignr";
import { useMemo } from "react";

const useLocalizedFitnessData = (iconSize = 20) => {
  const { palette } = useTheme();
  const riderTypes = useMemo(
    () => [
      {
        id: FitnessType.Attacker,
        stringId: "Attacker",
        icon: (
          <RiderTypeAttackerIcon
            sx={{ width: "100%", height: "100%", color: palette.riderTypeAttacker.main }}
          />
        ),
        iconFilled: (
          <Avatar
            sx={{
              bgcolor: palette.riderTypeAttacker.semiTransparent,
              width: "100%",
              height: "100%",
            }}
          >
            <RiderTypeAttackerIcon
              sx={{
                color: palette.riderTypeAttacker.main,
                width: `${iconSize}px`,
                height: `${iconSize}px`,
              }}
            />
          </Avatar>
        ),
        name: t`Attacker`,
        description: t`When it comes to short, maximal efforts you seem to have an endless supply of matches to burn. You may not be able to sustain these all-out bursts, but your powers of recovery mean you can serve them up in rapid succession. You are relentless in your ability to deliver attack after blistering attack, and formidable in situations where you need to respond to multiple surges with little time to recover.`,
      },
      {
        id: FitnessType.Pursuiter,
        stringId: "Pursuiter",
        icon: (
          <RiderTypePursuiterIcon
            sx={{ width: "100%", height: "100%", color: palette.riderTypePursuiter.main }}
          />
        ),
        iconFilled: (
          <Avatar
            sx={{
              bgcolor: palette.riderTypePursuiter.semiTransparent,
              width: "100%",
              height: "100%",
            }}
          >
            <RiderTypePursuiterIcon
              sx={{
                color: palette.riderTypePursuiter.main,
                width: `${iconSize}px`,
                height: `${iconSize}px`,
              }}
            />
          </Avatar>
        ),
        name: t`Pursuiter`,
        description: t`If you have five minutes to give it everything, no one else has a chance. You can put your head down and do serious damage on the track or shorter time trials.`,
      },
      {
        id: FitnessType.TimeTrialist,
        stringId: "TimeTrialist",
        icon: (
          <RiderTypeTimeTrialistIcon
            sx={{ width: "100%", height: "100%", color: palette.riderTypeTimeTrialist.main }}
          />
        ),
        iconFilled: (
          <Avatar
            sx={{
              bgcolor: palette.riderTypeTimeTrialist.semiTransparent,
              width: "100%",
              height: "100%",
            }}
          >
            <RiderTypeTimeTrialistIcon
              sx={{
                color: palette.riderTypeTimeTrialist.main,
                width: `${iconSize}px`,
                height: `${iconSize}px`,
              }}
            />
          </Avatar>
        ),
        name: t`Time Trialist`,
        description: t`The pain train is leaving the station, and you're driving. When you take your turn on the front, you do overtime. Your ability to tirelessly put down steady power, kilometer after kilometer, makes other riders wonder if you'll ever slow down. If it's a long haul at a high speed, you'll deliver the goods.`,
      },
      {
        id: FitnessType.Climber,
        stringId: "Climber",
        icon: (
          <RiderTypeClimberIcon
            sx={{ width: "100%", height: "100%", color: palette.riderTypeClimber.main }}
          />
        ),
        iconFilled: (
          <Avatar
            sx={{
              bgcolor: palette.riderTypeClimber.semiTransparent,
              width: "100%",
              height: "100%",
            }}
          >
            <RiderTypeClimberIcon
              sx={{
                color: palette.riderTypeClimber.main,
                width: `${iconSize}px`,
                height: `${iconSize}px`,
              }}
            />
          </Avatar>
        ),
        name: t`Climber`,
        description: t`When the road points up, that's when you get down to business. You seem to defy gravity, dancing up impossibly-steep climbs that leave everyone else begging for supplemental oxygen. When things flatten out, you need to seek shelter behind the biggest rider you can find and bide your time until things get pointy again. Leave the sprinting to the bodybuilders, the hills are your domain.`,
      },
      {
        id: FitnessType.Rouleur,
        stringId: "Rouleur",
        icon: (
          <RiderTypeRouleurIcon
            sx={{ width: "100%", height: "100%", color: palette.riderTypeRouleur.main }}
          />
        ),
        iconFilled: (
          <Avatar
            sx={{
              bgcolor: palette.riderTypeRouleur.semiTransparent,
              width: "100%",
              height: "100%",
            }}
          >
            <RiderTypeRouleurIcon
              sx={{
                color: palette.riderTypeRouleur.main,
                width: `${iconSize}px`,
                height: `${iconSize}px`,
              }}
            />
          </Avatar>
        ),
        name: t`Rouleur`,
        description: t`Is there anything you can't do? You are a master of all trades, a Swiss Army Knife, a polymath of the peloton, a...you get the picture. The very definition of versatility, you are able to deliver across a wide range of efforts and terrain. It takes a very specialized rider to best you, whether in a sprint, on a punchy climb, or in an all-day breakaway. When everyone else falters you are left standing, usually on top of the podium.`,
      },
      {
        id: FitnessType.Sprinter,
        stringId: "Sprinter",
        icon: (
          <RiderTypeSprinterIcon
            sx={{ width: "100%", height: "100%", color: palette.riderTypeSprinter.main }}
          />
        ),
        iconFilled: (
          <Avatar
            sx={{
              bgcolor: palette.riderTypeSprinter.semiTransparent,
              width: "100%",
              height: "100%",
            }}
          >
            <RiderTypeSprinterIcon
              sx={{
                color: palette.riderTypeSprinter.main,
                width: `${iconSize}px`,
                height: `${iconSize}px`,
              }}
            />
          </Avatar>
        ),
        name: t`Sprinter`,
        description: t`When you hit the switch and unleash your devastating sprint, few can hold your wheel. Even when riding with others who would otherwise be an equal match, you can turn on the gas and get a gap with relative ease. But like any rocket, your range is limited. Save the fireworks for the grande finale.`,
      },
    ],
    [
      palette.riderTypeAttacker.main,
      palette.riderTypeAttacker.semiTransparent,
      palette.riderTypeClimber.main,
      palette.riderTypeClimber.semiTransparent,
      palette.riderTypePursuiter.main,
      palette.riderTypePursuiter.semiTransparent,
      palette.riderTypeRouleur.main,
      palette.riderTypeRouleur.semiTransparent,
      palette.riderTypeSprinter.main,
      palette.riderTypeSprinter.semiTransparent,
      palette.riderTypeTimeTrialist.main,
      palette.riderTypeTimeTrialist.semiTransparent,
      iconSize,
    ]
  );

  const riderTypeStrengths = [
    {
      id: FitnessStrengths.SprintEfforts,
      name: t`Sprinting`,
      summary: t`Nice! Your explosive power is impressive!`,
      description: t`Your Sprint, or Neuromuscular Power (NM), determines how effectively you can recruit and coordinate muscle fibers during maximal efforts like sprints. Based upon you 5-second efforts, your Sprint is exceptional relative to your FTP.`,
    },
    {
      id: FitnessStrengths.RepeatedEfforts,
      name: t`Repeated Efforts`,
      summary: t`Nice! Repeated efforts are your strong suit.`,
      description: t`The ability to deliver repeated Attacks is a function of your high Anaerobic Capacity (AC). You have a great ability to refill your Anaerobic tank and hit it again.`,
    },
    {
      id: FitnessStrengths.VO2Efforts,
      name: t`VO2 Efforts`,
      summary: t`Nice! Your 5-minute power is impressive!`,
      description: t`Going hard for 5-minutes is your happy place. Relative to your sustained power (Endure/FTP), your Breakaway, or Maximum Aerobic Power, is above average. To the track!`,
    },
    {
      id: FitnessStrengths.SustainedEfforts,
      name: t`Sustained Efforts`,
      summary: t`Nice! Your sustained power is impressive!`,
      description: t`Your 20-minute FTP (Endure), is exceptional relative to your other abilities. If it's a long haul at speed, you deliver the goods.`,
    },
    {
      id: FitnessStrengths.PrettyMuchEverything,
      name: t`Pretty Much Everything`,
      summary: t`You're strong across the board.`,
      description: t`You’re good at everything, so you need to train everything. Workouts that feature a variety of efforts in a single session, like Blender, There is No Try and Local Hero on SYSTM are just what you need to maintain your legendary status.`,
    },
  ];

  const riderTypeWeaknesses = [
    {
      id: FitnessWeakness.NeuromuscularPower,
      name: t`Sprinting`,
      summary: t`Your Sprint could use some improvement.`,
      description: t`Sprint (NM) determines how effectively you can recruit and coordinate muscle fibers during maximal efforts. To improve, try working on muscle coordination and your efficiency at lower outputs.`,
    },
    {
      id: FitnessWeakness.RepeatedEfforts,
      name: t`Repeated Efforts`,
      summary: t`You need to improve your ability to quickly recover from hard efforts and/or handle surges.`,
      description: t`Compared to your FTP, your Attack (AC) is low. To improve, focus on repeatability—how well you refill your tank and go again. Micro-interval workouts will train your body to recharge more efficiently and sustain high efforts.`,
    },
    {
      id: FitnessWeakness.VO2,
      name: t`VO2`,
      summary: t`You need to raise your aerobic ceiling.`,
      description: t`VO2 efforts like those in Nine Hammers in SYSTM tap into your Breakaway Power (MAP): how much oxygen you can take in and the maximum amount of power you can generate with it. Breakaway also acts as a ceiling on your FTP. If you want to raise your FTP, you have to raise your Breakaway first.`,
    },
    {
      id: FitnessWeakness.SustainedEfforts,
      name: t`Sustained Efforts`,
      summary: t`You need to get more efficient at using oxygen for long, steady efforts.`,
      description: t`Your "steady-state economy" is low relative to your other abilities. That refers to your ability to use oxygen to drive sustained efforts at or near your Functional Threshold Power (FTP)  Focusing on steady-state efforts as well as raising your Breakaway (MAP) —will improve your ability to hold higher power for longer.`,
    },
  ];

  const getRiderTypeById = (id?: FitnessType) => {
    if (!id) {
      return;
    }
    return riderTypes.find((riderType) => riderType.id === id);
  };

  const getRiderTypeStrengthById = (id?: FitnessStrengths) => {
    if (!id) {
      return;
    }
    return riderTypeStrengths.find((strength) => strength.id === id);
  };

  const getRiderTypeWeaknessById = (id?: FitnessWeakness) => {
    if (!id) {
      return;
    }
    return riderTypeWeaknesses.find((weakness) => weakness.id === id);
  };

  return {
    getRiderTypeById,
    getRiderTypeStrengthById,
    getRiderTypeWeaknessById,
  };
};

export default useLocalizedFitnessData;
