import { t } from "@lingui/macro";
import WifiNetworks from "./WifiNetworks";
import { GlobalHeader } from "@/components/GlobalHeader";

const WifiConfig = ({ appToken }: { appToken: string }) => {
  return (
    <>
      <GlobalHeader title={t`Wi-Fi`} />
      <WifiNetworks appToken={appToken} />
    </>
  );
};

export default WifiConfig;
