import { useSettingsRemoteConfig } from "@/hooks/useSettingsRemoteConfig";
import { Step } from "@WahooFitness/redesignr/Components/WalkthroughVideoPlayer/StepTypes";
import { postEvent } from "@WahooFitness/wahoo-offline-mfe";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

function useVideoPlayer() {
  const [tosVersion, setTosVersion] = useState<string>("1");
  const [tosText, setTosText] = useState<string[]>([""]);
  async function getTOS() {
    const file = await fetch(
      "https://cdn.wahooligan.com/wahoo-cloud/static/documents/wahoo_tos_run.txt"
    );
    const body = await file.text();
    const tosParagraphArray = body.split("\n\n");
    const tosVersionMeta = tosParagraphArray.shift() || "";
    const re = /<META version=(\d+)>/;
    const matches = tosVersionMeta.match(re);
    setTosVersion(matches?.[1] || "1");
    setTosText(tosParagraphArray);
  }

  useEffect(() => {
    getTOS();
  }, []);

  const { getSettingsRemoteConfigObject } = useSettingsRemoteConfig();
  const videoList = useMemo(
    () =>
      getSettingsRemoteConfigObject<{ url: string; steps: Step[] }[]>(
        "KICKR_RUN_ONBOARDING_VIDEO"
      ) || [],
    [getSettingsRemoteConfigObject]
  );

  const params = useParams();
  const videoIndex = Number(params.videoIdx ?? 0);
  const navigate = useNavigate();
  const { search } = useLocation();

  const navToFirstVideo = useCallback(() => {
    postEvent("kickrRunShippingMode", { unlock: "true", tosVersion });
    navigate("/onboarding/guided-video/0");
  }, [navigate, tosVersion]);

  const forwardNav = useCallback(() => {
    const newVideoIndex = videoIndex + 1;
    if (newVideoIndex >= videoList.length) {
      navigate(`/onboarding/onboarding-completed${search}`);
    } else {
      navigate(`/onboarding/guided-video/${newVideoIndex}${search}`);
    }
  }, [navigate, search, videoIndex, videoList.length]);

  const currentVideo = useMemo(() => videoList[videoIndex], [videoIndex, videoList]);

  return {
    navToFirstVideo,
    forwardNav,
    currentVideo,
    tosText,
  };
}

export default useVideoPlayer;
