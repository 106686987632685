import { t } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import FitnessAssessmentCard from "./FitnessAssessmentCard";
import { FitnessProfileAlgorithm } from "@WahooFitness/cloud-client-types";
import { useMemo } from "react";

function EstimateDrawer({ algorithmId }: { algorithmId?: FitnessProfileAlgorithm }) {
  const text = useMemo(() => {
    switch (algorithmId) {
      case FitnessProfileAlgorithm.HalfMontyAssessment:
        return t`Your Athlete Profile is generated based on the Half Monty Fitness Assessment you completed in SYSTM. This test accurately measures your Endurance (FTP) and Breakaway (MAP) but estimates your Attack (AC) and Sprint (NM). For the most accurate Athlete Profile, we recommend completing the Full Frontal Fitness Assessment in the SYSTM App.`;
      case FitnessProfileAlgorithm.FourDPQuiz:
      default:
        return t`These values are estimates based on your questionnaire answers, not actual training data. For the most accurate results, complete the Fitness Assessment in the SYSTM App.`;
    }
  }, [algorithmId]);
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2} pb={4} gap={3}>
      <Typography variant="prose-base">{text}</Typography>
      <FitnessAssessmentCard />
    </Box>
  );
}

export default EstimateDrawer;
