import ErrorBoundary from "@/components/ErrorBoundary";
import noop from "@/services/noop";
import { t, Trans } from "@lingui/macro";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { AddIcon, EditIcon, MenuList, BottomSheet } from "@WahooFitness/redesignr";
import { useEffect } from "react";
import useAlertList from "./useAlertList";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";
import { GlobalHeader } from "@/components/GlobalHeader";

export const EditButton = ({
  editing,
  toggleEditing,
}: {
  editing: boolean;
  toggleEditing: () => void;
}) => (
  <Box mr={1}>
    {editing ? (
      <Button
        size="small"
        variant="text"
        color="info"
        onClick={toggleEditing}
        data-testid="editAlertsButton"
      >
        <Typography variant="prose-base">{t`Done`}</Typography>
      </Button>
    ) : (
      <IconButton onClick={toggleEditing} data-testid="doneEditingButton">
        <EditIcon />
      </IconButton>
    )}
  </Box>
);

const AlertList = ({
  workoutProfileId,
  hasOwnHeader,
  editing: editingOverride,
}: {
  workoutProfileId: number;
  hasOwnHeader?: boolean;
  editing?: boolean;
}) => {
  const {
    editing,
    toggleEditing,
    alertsLoading,
    alertsError,
    currentEditorView,
    editorOpen,
    clearEditor,
    generalListTitle,
    generalListItems,
    navigationAlertListTitle,
    navigationAlertListItems,
    plannedWorkoutAlertListTitle,
    plannedWorkoutAlertListItems,
    customAlertListTitle,
    customAlertListItems,
    handleAddAlert,
    showElemntAlertCountWarning,
  } = useAlertList(workoutProfileId);

  useEffect(() => {
    if (editingOverride !== undefined && editingOverride !== editing) {
      toggleEditing();
    }
  }, [editing, editingOverride, toggleEditing]);

  const { globalBottomPadding } = useConfigContext();

  return (
    <ErrorBoundary isLoading={alertsLoading} error={alertsError}>
      <GlobalHeader
        title={t`Alerts`}
        headerAction={<EditButton editing={editing} toggleEditing={toggleEditing} />}
        hasOwnHeader={hasOwnHeader}
      />
      <Box maxWidth="sm" width="100%" height="100%" overflow="scroll">
        <Box px={2} py={1}>
          <Box px={2}>{generalListTitle}</Box>
          <MenuList listItems={generalListItems} disableGutters />
        </Box>
        {navigationAlertListItems.length > 0 && (
          <Box px={2} py={1}>
            <Box px={2}>{navigationAlertListTitle}</Box>
            <MenuList listItems={navigationAlertListItems} disableGutters />
          </Box>
        )}
        {plannedWorkoutAlertListItems.length > 0 && (
          <Box px={2} py={1}>
            <Box px={2}>{plannedWorkoutAlertListTitle}</Box>
            <MenuList listItems={plannedWorkoutAlertListItems} disableGutters />
          </Box>
        )}
        {customAlertListItems.length > 0 && (
          <Box>
            <Box px={2} py={1}>
              <Box px={2}>{customAlertListTitle}</Box>
              <MenuList listItems={customAlertListItems} disableGutters />
            </Box>
          </Box>
        )}
        <Box width="100%" p={2}>
          <Button
            size="large"
            variant="contained"
            fullWidth
            startIcon={<AddIcon />}
            onClick={handleAddAlert}
            data-testid="addAlertButton"
          >{t`Add a custom alert`}</Button>
        </Box>
        {showElemntAlertCountWarning && (
          <Box margin={1} textAlign="center">
            <Typography variant="prose-sm" color="text.secondary">
              <Trans>ELEMNT supports the top three alerts added to a single trigger.</Trans>
            </Typography>
          </Box>
        )}
      </Box>
      <BottomSheet
        open={editorOpen}
        onClose={clearEditor}
        onOpen={noop}
        height="75%"
        bottomPadding={globalBottomPadding}
        sx={{ zIndex: 20000 }}
      >
        {currentEditorView}
      </BottomSheet>
    </ErrorBoundary>
  );
};

export default AlertList;
