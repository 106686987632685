import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  HeartRateAlgorithmLookupType,
  HeartRateZonesResponseType,
  PaceZonesType,
  PaceZoneThresholdAlgorithmType,
  PowerAlgorithmLookupType,
  PowerZoneResponseType,
  WorkoutProfileType,
} from "@WahooFitness/cloud-client-types";
import { useSnackbarContext, useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import useHeartRateZones from "./useHeartRateZones";
import usePowerZones from "./usePowerZones";
import { t } from "@lingui/macro";
import usePaceZones from "./usePaceZones";
import { copyZones, mapValueByZoneSetId } from "./ZonesUtils";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";

export type Zone = {
  bottom: number;
  top: number | "max";
  name: string;
};

export type ZoneSet = Partial<HeartRateZonesResponseType> &
  Partial<PowerZoneResponseType> &
  Partial<PaceZonesType> & {
    zone_count: number;
    zones: Zone[];
    displayIndex: number;
  };

export type StoredZones = {
  savedZoneSets: Record<number, ZoneSet>;
  updatedAt?: Record<number, Date | undefined>;
  saveZoneSet: (
    zones: ZoneSet
  ) => Promise<
    | (Partial<HeartRateZonesResponseType> &
        Partial<PowerZoneResponseType> &
        Partial<PaceZonesType>)
    | undefined
  >;
  zoneCountChoices?: number[];
  autoCalculateVariant: "thr" | "ftp" | "threshold_speed";
  profileField: keyof WorkoutProfileType;
  autoCalcZoneSet: (
    currentZoneSet: ZoneSet,
    params: HeartRateAlgorithmLookupType & PowerAlgorithmLookupType & PaceZoneThresholdAlgorithmType
  ) => Promise<ZoneSet | undefined> | undefined;
  primaryZoneSetId: number | undefined;
  deleteZoneSet: (zoneSetId: number) => Promise<void>;
  clearAlgorithmInfo: (
    zoneSets: Record<number, ZoneSet>,
    zoneSetId: number
  ) => Record<number, ZoneSet>;
  error: any;
  isLoading: boolean;
  reloadZones: () => void;
  maxAutoCalculateValues: Record<string, number>;
  zonesSetIsValid: (zoneSet: ZoneSet, zoneCount: number) => boolean;
  createValidZones: (
    zoneSetId: number,
    startingIndex: number,
    zoneSets: Record<number, ZoneSet>
  ) => Record<number, ZoneSet>;
  extractZonesForDisplay: (zoneSet: any) => Zone[];
};

const useZones = (zoneType: "power" | "heartRate" | "speed") => {
  const { enqueueSnackbar } = useSnackbarContext();
  const { setDialog, handleClose: closeDialog } = useDialogContext();
  const { updateZoneSetAssociations, selectedAssociationList, setSelectedAssociationList } =
    useWorkoutProfilesContext();
  const { subtractPaceSecondFromMps } = useUnitFormatter();
  const useStoredZones = useMemo(() => {
    switch (zoneType) {
      case "power":
        return usePowerZones;
      case "heartRate":
        return useHeartRateZones;
      default:
        return usePaceZones;
    }
  }, [zoneType]);

  const {
    savedZoneSets,
    updatedAt,
    saveZoneSet,
    zoneCountChoices,
    autoCalculateVariant,
    profileField,
    autoCalcZoneSet,
    primaryZoneSetId,
    deleteZoneSet,
    clearAlgorithmInfo,
    isLoading,
    error,
    reloadZones,
    maxAutoCalculateValues,
    zonesSetIsValid,
    createValidZones,
  } = useStoredZones();

  const [zoneSets, setZoneSets] = useState(copyZones(savedZoneSets));
  const [duplicateZoneSetNameError, setDuplicateZoneSetNameError] = useState(false);

  useEffect(() => {
    if (savedZoneSets) {
      setZoneSets(copyZones(savedZoneSets));
    }
  }, [savedZoneSets]);

  const zoneSetNames = useMemo(() => {
    return (
      savedZoneSets &&
      Object.values(savedZoneSets)
        .map((zoneSet) => zoneSet.name)
        .filter((name) => name)
    );
  }, [savedZoneSets]);

  const [addingNewZone, setAddingNewZone] = useState<boolean>(false);
  const defaultNewZoneSet = useMemo(() => {
    const primary = primaryZoneSetId ? zoneSets[primaryZoneSetId] : Object.values(zoneSets)[0];
    let nameIndex = 1;
    let nameCandidate = t`New Set ${nameIndex}`;
    while (zoneSetNames?.includes(nameCandidate)) {
      nameCandidate = t`New Set ${++nameIndex}`;
    }
    return {
      ...primary,
      name: nameCandidate,
      id: -1,
      primary: false,
      resting: undefined,
      maximum: undefined,
      zones: primary?.zones || [],
    };
  }, [primaryZoneSetId, zoneSets, zoneSetNames]);

  const handleClose = useCallback(() => {
    setAddingNewZone(false);
    setDuplicateZoneSetNameError(false);
    setZoneSets((currentZoneSets) => {
      if (!Object.keys(currentZoneSets).includes("-1")) {
        return currentZoneSets;
      }
      const newZoneSets = { ...currentZoneSets };
      delete newZoneSets[-1];
      return newZoneSets;
    });
  }, []);

  const handleNameChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, zoneSetId: number) => {
      if (
        (zoneSetId === -1 || savedZoneSets[zoneSetId].name !== ev.target.value.trim()) &&
        zoneSetNames.includes(ev.target.value.trim())
      ) {
        setDuplicateZoneSetNameError(true);
      } else {
        setDuplicateZoneSetNameError(false);
      }
      const newZoneSets = copyZones(zoneSets);
      newZoneSets[zoneSetId].name = ev.target.value;
      setZoneSets(newZoneSets);
    },
    [zoneSetNames, savedZoneSets, zoneSets]
  );

  const handleZoneRangeChange = useCallback(
    (value: number, index: number, zoneSetId: number) => {
      if (isNaN(value)) {
        return;
      }
      const newZones = [...zoneSets[zoneSetId].zones];
      newZones[index].bottom = value;
      let newZoneSets = copyZones(zoneSets);
      newZoneSets[zoneSetId].zones = newZones;
      newZoneSets = clearAlgorithmInfo(newZoneSets, zoneSetId);
      setZoneSets(newZoneSets);
    },
    [clearAlgorithmInfo, zoneSets]
  );

  const zoneCounts = useMemo(() => {
    const counts = {} as Record<number, number>;
    for (const zone in zoneSets) {
      counts[zone] = zoneSets[zone].zones.length;
    }
    return counts;
  }, [zoneSets]);

  const [editing, setEditing] = useState<number | undefined>(undefined);

  const disableEditing = useCallback(() => {
    setEditing(undefined);
    setZoneSets(copyZones(savedZoneSets));
    setDuplicateZoneSetNameError(false);
  }, [savedZoneSets]);

  const handleDiscardChanges = useCallback(() => {
    disableEditing();
    setSelectedAssociationList(undefined);
  }, [disableEditing, setSelectedAssociationList]);

  const enableEditing = useCallback(
    (zoneSetId: number) => {
      function discardAndClose() {
        handleDiscardChanges();
        closeDialog();
        setZoneSets(copyZones(savedZoneSets));
        setEditing(zoneSetId);
        setSelectedAssociationList(undefined);
      }
      if (editing !== undefined) {
        setDialog({
          open: true,
          title: t`Save open edits?`,
          body: t`You are currently editing a different zone set. You must save or discard changes before editing another zone set.`,
          actions: [
            { text: t`Continue editing` },
            { text: t`Discard`, color: "error", action: discardAndClose },
          ],
        });
      } else {
        setZoneSets(copyZones(savedZoneSets));
        setEditing(zoneSetId);
        setSelectedAssociationList(undefined);
      }
    },
    [
      editing,
      savedZoneSets,
      setSelectedAssociationList,
      handleDiscardChanges,
      setDialog,
      closeDialog,
    ]
  );

  const handleAddNew = useCallback(() => {
    function discardAndClose() {
      handleDiscardChanges();
      closeDialog();
      setAddingNewZone(true);
      setZoneSets({ ...zoneSets, [-1]: defaultNewZoneSet });
    }
    if (editing !== undefined) {
      setDialog({
        open: true,
        title: t`Save open edits?`,
        body: t`You are currently editing a different zone set. You must save or discard changes before editing another zone set.`,
        actions: [
          { text: t`Continue editing` },
          { text: t`Discard`, color: "error", action: discardAndClose },
        ],
      });
    } else {
      setAddingNewZone(true);
      setZoneSets({ ...zoneSets, [-1]: defaultNewZoneSet });
    }
  }, [closeDialog, defaultNewZoneSet, editing, handleDiscardChanges, setDialog, zoneSets]);

  const zonesAreChanged = useMemo(() => {
    return mapValueByZoneSetId(
      zoneSets,
      (zoneSet, zoneSetId) =>
        !savedZoneSets[zoneSetId] ||
        (zoneSet.name && savedZoneSets[zoneSetId].name !== zoneSet.name) ||
        savedZoneSets[zoneSetId].zones.length !== zoneSet.zones.length ||
        savedZoneSets[zoneSetId].zones.some(
          (zone, index) => zone.bottom !== zoneSet.zones[index].bottom
        )
    );
  }, [savedZoneSets, zoneSets]);

  // TODO: ATP-476 - heart rate zones are currently having their nulls cleared on load. Once power zones are updated
  // this can be removed, but the cloud endpoint needs to be updated to include a `primary` field before we can
  // start using the new endpoint.
  const clearNulls = useCallback((zoneSet: ZoneSet) => {
    (Object.keys(zoneSet) as Array<keyof ZoneSet>).forEach((key) => {
      (zoneSet[key] as any) ??= undefined;
    });
    return zoneSet;
  }, []);

  const [savingZones, setSavingZones] = useState(false);

  const handleSaveZoneSet = useCallback(
    async (zoneSetId: number) => {
      if (zonesAreChanged[zoneSetId] || selectedAssociationList) {
        try {
          setSavingZones(true);
          const zoneSetForSave = clearNulls(zoneSets[zoneSetId]);
          const newZoneSet = await saveZoneSet(zoneSetForSave);
          if (newZoneSet?.id && selectedAssociationList) {
            await updateZoneSetAssociations(profileField, newZoneSet.id, primaryZoneSetId);
          }
          if (addingNewZone) {
            handleClose();
          }
        } catch {
          let field = "";
          switch (zoneType) {
            case "power":
              field = t`Power zones`;
              break;
            case "heartRate":
              field = t`Heart rate zones`;
              break;
            default: {
              field = t`Running pace zones`;
              break;
            }
          }
          enqueueSnackbar({
            message: t`An error occurred when saving ${field}. Please check your internet connection and try again.`,
            severity: "error",
          });
          return;
        } finally {
          setSavingZones(false);
        }
      }
      setEditing(undefined);
      setSelectedAssociationList();
    },
    [
      zonesAreChanged,
      selectedAssociationList,
      setSelectedAssociationList,
      clearNulls,
      zoneSets,
      saveZoneSet,
      addingNewZone,
      updateZoneSetAssociations,
      profileField,
      primaryZoneSetId,
      handleClose,
      enqueueSnackbar,
      zoneType,
    ]
  );

  const handleZoneCountChange = useCallback(
    (zoneSetId: number, value: number) => {
      let newZones;
      const oldZoneSet = zoneSets[zoneSetId];
      if (value < oldZoneSet.zones.length) {
        newZones = oldZoneSet.zones.slice(0, value);
        newZones[newZones.length - 1].top = "max";
      } else {
        newZones = oldZoneSet.zones.concat(Array(value - oldZoneSet.zones.length));
        for (let index = oldZoneSet.zones.length; index < value; index++) {
          if (index > 0) {
            newZones[index - 1].top = newZones[index - 1].bottom;
          }
          let bottom;
          if (zoneType === "speed") {
            bottom = subtractPaceSecondFromMps(newZones[index - 1]?.bottom);
          } else {
            bottom = (newZones[index - 1]?.bottom || 0) + 1;
          }
          newZones[index] = {
            bottom,
            top: "max",
            name: t`Zone ${index + 1}`,
          };
        }
      }
      setZoneSets({ ...zoneSets, [zoneSetId]: { ...oldZoneSet, zones: newZones } });
    },
    [subtractPaceSecondFromMps, zoneSets, zoneType]
  );

  const handleAutoCalcZones = useCallback(
    async (
      zoneSet: ZoneSet,
      params: HeartRateAlgorithmLookupType &
        PowerAlgorithmLookupType &
        PaceZoneThresholdAlgorithmType
    ) => {
      try {
        const newZones = await autoCalcZoneSet(zoneSet, params);
        if (newZones && zoneSet.id) {
          setZoneSets({ ...zoneSets, [zoneSet.id]: newZones });
        }
      } catch {
        let field: string;
        switch (zoneType) {
          case "power":
            field = t`Power zones`;
            break;
          case "heartRate":
            field = t`Heart rate zones`;
            break;
          default:
            field = t`Running pace zones`;
            break;
        }
        enqueueSnackbar({
          message: t`An error occurred when auto calculating your ${field}. Please check your internet connection and try again.`,
          severity: "error",
        });
        return;
      }
    },
    [autoCalcZoneSet, enqueueSnackbar, zoneSets, zoneType]
  );

  const doDelete = useCallback(
    async (zoneSetId: number) => {
      await deleteZoneSet(zoneSetId);
      setEditing(undefined);
      closeDialog();
    },
    [closeDialog, deleteZoneSet]
  );

  const handleDeleteZoneSet = useCallback(
    async (zoneSetId: number) => {
      const zoneSetName = zoneSets[zoneSetId].name;
      const type = zoneType === "power" ? t`power` : t`heart rate`;
      setDialog({
        open: true,
        title: t`Delete ${zoneSetName} zone set?`,
        body: t`Any workout profiles assigned to this zone set, will now be assigned to the default ${type} zone set.`,
        actions: [
          { text: t`Cancel` },
          { text: t`Delete zone set`, color: "error", action: async () => doDelete(zoneSetId) },
        ],
      });
    },
    [doDelete, setDialog, zoneSets, zoneType]
  );

  const fixInvalidZones = useCallback(
    (zoneSetId: number, startingIndex: number) => {
      const validZones = createValidZones(zoneSetId, startingIndex, zoneSets);
      setZoneSets(validZones);
    },
    [createValidZones, zoneSets]
  );

  const replaceZoneSet = useCallback(
    (zoneSetId: number, zoneSet: ZoneSet) => {
      const existingZoneSetString = JSON.stringify(zoneSets[zoneSetId]);
      const newZoneSetString = JSON.stringify(zoneSet);
      if (existingZoneSetString !== newZoneSetString) {
        const newZoneSets = { ...zoneSets };
        newZoneSets[zoneSetId] = zoneSet;
        setZoneSets(newZoneSets);
      }
    },
    [zoneSets]
  );

  return {
    isLoading: isLoading,
    error: error,
    reloadZones,
    updatedAt,
    savedZoneSets,
    zoneSets,
    handleNameChange,
    handleZoneRangeChange,
    handleZoneCountChange,
    handleDiscardChanges,
    handleSaveZoneSet,
    fixInvalidZones,
    editing,
    enableEditing,
    disableEditing,
    zonesSetIsValid,
    zoneCounts,
    zoneCountChoices,
    autoCalculateVariant,
    handleAutoCalcZones,
    primaryZoneSetId,
    addingNewZone,
    handleAddNew,
    handleClose,
    handleDeleteZoneSet,
    duplicateZoneSetNameError,
    maxAutoCalculateValues,
    savingZones,
    replaceZoneSet,
  };
};

export default useZones;
