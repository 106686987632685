import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import CustomStepper from "../AthleteOnboarding/CustomStepper";
import useFourDpEducation from "./useFourDPEducation";
import { Trans } from "@lingui/macro";
import StepWrapper from "./StepWrapper";
import AnimatedFourDPStepTitle from "./AnimatedFourDPStepTitle";
import { GlobalHeader } from "../GlobalHeader";
import GraphComponent from "./AnimatedFourDPGraphComponent";
import { Close } from "@carbon/icons-react";
import { useHeaderContext } from "@/hooks/useHeaderContext";
import { Dispatch, SetStateAction } from "react";
import XAxis from "./Graphs/XAxis";
import { motion } from "motion/react";

function FourDPEducation({
  isStandAlone,
  setPage,
  finishedEducation,
  setFinishedEducation,
}: {
  isStandAlone: boolean;
  setPage?: Dispatch<SetStateAction<number>>;
  finishedEducation?: boolean;
  setFinishedEducation?: (finishedEducation: boolean) => void;
}) {
  const { palette } = useTheme();
  const { steps, activeStep, handleNext, handleBack } = useFourDpEducation(
    isStandAlone,
    setPage,
    finishedEducation,
    setFinishedEducation
  );
  const { navigateBack } = useHeaderContext();

  if (!steps) {
    return;
  }

  return (
    <>
      <GlobalHeader
        title={steps[activeStep].headerTitle}
        backAction={handleBack}
        disableBackButton={isStandAlone && activeStep === 0}
        disableBoxShadow
        headerAction={
          isStandAlone ? (
            <IconButton onClick={navigateBack}>
              <Close size="24px" color={palette.text.primary} />
            </IconButton>
          ) : undefined
        }
      />

      <Box height="100%" sx={{ boxShadow: `0 0 0 100vmax ${palette.background.paperElevation4}` }}>
        {!steps[activeStep].hideStepper && (
          <Box
            display="flex"
            position="absolute"
            left={0}
            pl={2}
            py={1}
            sx={{ backgroundColor: palette.background.paperElevation4 }}
          >
            <CustomStepper steps={steps.slice(1)} activeStep={activeStep - 1} />
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          sx={{ backgroundColor: palette.background.paperElevation4 }}
          px={2}
        >
          <StepWrapper>
            <AnimatedFourDPStepTitle
              title={steps[activeStep].headline}
              subtitle={steps[activeStep].subheader}
              step={activeStep}
            />

            <GraphComponent steps={steps} activeStep={activeStep} />
          </StepWrapper>
          <Box position="relative" width="100%">
            {steps[activeStep].ticks.length > 0 && <XAxis ticks={steps[activeStep].ticks} />}
          </Box>

          <Box width="100%" mt={3}>
            <Typography
              mt={2}
              key={`description-${steps[activeStep].stepId}`}
              color="text.primary"
              component={motion.span}
              variant="prose-sm"
              initial={{ opacity: 0, y: 4 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", damping: 20, stiffness: 300 }}
            >
              {steps[activeStep].description}
            </Typography>
          </Box>
          <Box p={3} width="100%" mt={["auto", 11.25]} maxWidth="sm">
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={handleNext}
              disabled={steps[activeStep].disabled}
            >
              <Trans>{steps[activeStep].buttonName}</Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FourDPEducation;
