import { motion } from "motion/react";

function EndureGraph({ activeStep, stepId }: { activeStep: number; stepId: number }) {
  return (
    <>
      <motion.path
        d="M12 290C12 290 21.0597 269.059 21.0597 234H339"
        stroke="url(#paint3_linear_3516_20250)"
        strokeWidth="24"
        strokeLinecap="round"
        strokeLinejoin="round"
        initial="hidden"
        variants={{
          show: {
            pathLength: [0.001, 1],
            pathOffset: [0.0001],
            opacity: 1,
            transition: { duration: 2, ease: [0.25, 0.1, 0.25, 1] },
          },
          hidden: {
            pathLength: [1, 0.001],
            pathOffset: [0.0001, 0.9999],
            opacity: 0,
            transition: { pathLength: { opacity: 0.2 } },
          },
        }}
        animate={activeStep === stepId ? "show" : "hidden"}
      />
      <defs>
        <linearGradient
          id="paint3_linear_3516_20250"
          x1="98.8109"
          y1="234"
          x2="98.8109"
          y2="290"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#02C4F5" />
          <stop offset="1" stopColor="#A7EDFF" />
        </linearGradient>
      </defs>
    </>
  );
}

export default EndureGraph;
