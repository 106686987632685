import { ConnectedApps } from "@WahooFitness/cloud-client-ts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ConnectedAppType, ConnectedAppUserType } from "@WahooFitness/cloud-client-types";
import { DataConnected } from "@carbon/icons-react";
import {
  useSnackbarContext,
  useConfigContext,
  useCloudContext,
  useNativeMessagingContext,
  useOfflineSWR,
} from "@WahooFitness/wahoo-offline-mfe";
import { t } from "@lingui/macro";
import { MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import useNativeHealth from "./useNativeHealth";
import { Box } from "@mui/material";
import { useSettingsRemoteConfig } from "@/hooks/useSettingsRemoteConfig";

export interface ConnectedAppListItem extends MenuListItemType {
  authorized: Partial<ConnectedAppUserType> | null;
  supportedCapabilities: Array<keyof ConnectedAppUserType>;
  handleDeauth: () => void;
  nativeHealth?: boolean;
}

const EMAIL_APP_ID = 13;

export const availableCapabilities = [
  "share_summaries",
  "import_summaries",
  "import_plans",
  "import_routes",
  "import_segments",
];

const useThirdPartySharing = () => {
  const {
    wahooToken,
    cloudConfig: { wahooLinksUrl },
  } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const { addRefreshListener, removeRefreshListener } = useNativeMessagingContext();
  const { getSettingsRemoteConfigObject } = useSettingsRemoteConfig();
  const supportedSharingApps = useMemo(() => {
    return getSettingsRemoteConfigObject<number[]>("SUPPORTED_SHARING_APPS") ?? [];
  }, [getSettingsRemoteConfigObject]);

  const connectedAppsClient = useMemo(() => getCloudClient(ConnectedApps), [getCloudClient]);

  const {
    data: connectedAppsData,
    error: connectedAppsError,
    isLoading: connectedAppsLoading,
    mutate: mutateConnectedApps,
  } = useOfflineSWR(["getConnectedApps", wahooToken], ([_key, token]) =>
    connectedAppsClient.get(token)
  );

  useEffect(() => {
    const refreshListener = addRefreshListener(mutateConnectedApps);
    return () => removeRefreshListener(refreshListener);
  }, [addRefreshListener, mutateConnectedApps, removeRefreshListener]);

  const { enqueueSnackbar } = useSnackbarContext();
  const { addNativeHealth } = useNativeHealth();

  const [emailAppData, emailAppIndex] = useMemo(() => {
    const index =
      connectedAppsData?.findIndex((connectedApp) => connectedApp.id === EMAIL_APP_ID) || -1;
    return [connectedAppsData?.[index], index];
  }, [connectedAppsData]);

  const emailAuthorized = useMemo(
    () => !!emailAppData?.connected_app_user,
    [emailAppData?.connected_app_user]
  );

  const replaceEmailConnectedAppUser = useCallback(
    (
      connectedApps: ConnectedAppType[] | undefined,
      connectedAppUser: ConnectedAppUserType | null
    ) => {
      const copy = connectedApps && [...connectedApps];
      if (copy) {
        copy[emailAppIndex] = { ...copy[emailAppIndex], connected_app_user: connectedAppUser };
      }
      return copy;
    },
    [emailAppIndex]
  );

  const handleDeauth = useCallback(
    async (appId: number, appName: string) => {
      try {
        await connectedAppsClient.deauthorizeForUser(appId, wahooToken);
      } catch {
        enqueueSnackbar({
          message: t`Failed to deauthorize ${appName}. Please check your internet connection and try again.`,
          severity: "error",
        });
      }
      mutateConnectedApps((current) => {
        if (appId !== EMAIL_APP_ID) {
          return undefined;
        }
        return replaceEmailConnectedAppUser(current, null);
      });
    },
    [
      connectedAppsClient,
      enqueueSnackbar,
      mutateConnectedApps,
      replaceEmailConnectedAppUser,
      wahooToken,
    ]
  );

  const updateAutoShareSummaries = useCallback(
    async (appUserId: number, autoShareSummaries: boolean) => {
      try {
        await connectedAppsClient.putConnectedAppUser(
          appUserId,
          { auto_share_summaries: autoShareSummaries },
          wahooToken
        );

        mutateConnectedApps((current) => {
          return current?.map((app) => {
            if (app.connected_app_user?.id === appUserId) {
              return {
                ...app,
                connected_app_user: {
                  ...app.connected_app_user,
                  auto_share_summaries: autoShareSummaries,
                },
              };
            }
            return app;
          });
        });
      } catch {
        enqueueSnackbar({
          message: t`An error occurred when updating auto share summaries. Please try again later.`,
          severity: "error",
        });
      }
    },
    [connectedAppsClient, enqueueSnackbar, mutateConnectedApps, wahooToken]
  );

  const appList = useMemo(
    () =>
      addNativeHealth(
        connectedAppsData
          ?.filter((app) => {
            return (
              supportedSharingApps.includes(app.id) &&
              Object.entries(app).some(
                ([key, value]) => availableCapabilities.includes(key) && value === true
              ) &&
              app.id !== EMAIL_APP_ID
            );
          })
          .map((app) => {
            const authUrl = new URL(
              app.connected_app_user ? app.deauthorize_url : app.authorize_url
            );
            authUrl.searchParams.append("auth_token", wahooToken as string);
            authUrl.searchParams.append(
              "return_to",
              `${wahooLinksUrl}/apps/wahoo_app/settings/third-party-sharing?force=1`
            );
            const supportedCapabilities = (
              availableCapabilities as Array<keyof ConnectedAppUserType>
            ).filter((capability) => app[capability as keyof ConnectedAppType]);
            return {
              authorized: app.connected_app_user,
              supportedCapabilities,
              id: String(app.id),
              icon: app.icon?.thumb.url ? (
                <Box
                  overflow="hidden"
                  borderRadius={2}
                  border={1}
                  borderColor="divider"
                  height={24}
                  width={24}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img width="24" height="24" alt={app.name} src={app.icon.url} />
                </Box>
              ) : (
                <DataConnected width="24" height="24" />
              ),
              content: app.name,
              variant: MenuListItemVariant.ExternalLink,
              linkLocation: authUrl.toString(),
              handleDeauth: () => handleDeauth(app.id, app.name),
            };
          })
      ) || [],
    [
      addNativeHealth,
      connectedAppsData,
      supportedSharingApps,
      wahooToken,
      wahooLinksUrl,
      handleDeauth,
    ]
  );

  const getStringContent = useCallback((value: string | React.ReactElement | undefined): string => {
    if (!value) {
      return "";
    }
    if (typeof value === "string") {
      return value;
    }
    const children = value.props?.children;
    if (Array.isArray(children)) {
      return children.map(getStringContent).join("");
    }
    return getStringContent(children);
  }, []);

  const availableApps: MenuListItemType[] = useMemo(
    () =>
      appList
        .filter((app) => !app.authorized)
        .sort((a, b) => getStringContent(a.content).localeCompare(getStringContent(b.content))),
    [appList, getStringContent]
  );

  const authorizedApps: ConnectedAppListItem[] = useMemo(
    () =>
      appList
        .filter((app) => !!app.authorized)
        .sort((a, b) => getStringContent(a.content).localeCompare(getStringContent(b.content))),
    [appList, getStringContent]
  );

  const [emailSharingUpdateInProgress, setEmailSharingUpdateInProgress] = useState(false);

  const authorizeEmail = useCallback(async () => {
    setEmailSharingUpdateInProgress(true);
    try {
      const result = await connectedAppsClient.createConnectedAppUser(
        { connected_app_id: EMAIL_APP_ID, auto_share_summaries: true },
        wahooToken
      );
      mutateConnectedApps((current) => {
        return replaceEmailConnectedAppUser(current, result);
      });
    } catch {
      enqueueSnackbar({
        message: t`Failed to authorize ${emailAppData?.name}. Please check your internet connection and try again.`,
        severity: "error",
      });
    }
    setEmailSharingUpdateInProgress(false);
  }, [
    connectedAppsClient,
    emailAppData?.name,
    enqueueSnackbar,
    mutateConnectedApps,
    replaceEmailConnectedAppUser,
    wahooToken,
  ]);

  const deauthorizeEmail = useCallback(async () => {
    setEmailSharingUpdateInProgress(true);
    emailAppData && (await handleDeauth(emailAppData.id, emailAppData.name));
    setEmailSharingUpdateInProgress(false);
  }, [emailAppData, handleDeauth]);

  return {
    connectedAppsData,
    connectedAppsLoading,
    connectedAppsError,
    mutateConnectedApps,
    authorizedApps,
    availableApps,
    emailAuthorized,
    authorizeEmail,
    deauthorizeEmail,
    emailSharingUpdateInProgress,
    updateAutoShareSummaries,
  };
};

export default useThirdPartySharing;
