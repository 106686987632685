import { WarningAlt } from "@carbon/icons-react";
import { Typography } from "@mui/material";
import WarmingUp from "./WarmUp";
import { Trans } from "@lingui/macro";

const PageLoadError = ({ isLoading = false }: { isLoading?: boolean }) => {
  return (
    <>
      <Typography color={"warning.main"}>
        <WarningAlt height={30} width={30} />
      </Typography>
      <Typography variant="prose-md" align="center">
        <Trans>Sorry, something went wrong. But you are going to be ok!</Trans>
      </Typography>
      {isLoading && <WarmingUp noTopSpacing />}
    </>
  );
};

export default PageLoadError;
