import { Box } from "@mui/system";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import ErrorBoundary from "../ErrorBoundary";
import FourDPQuiz from "./FourDPQuiz";
import { FourDPQuizResultsType } from "@WahooFitness/cloud-client-types";

function FourDPQuizContainer({
  setResults,
  setError,
  setPage,
  skipEducation,
}: {
  setResults: Dispatch<SetStateAction<FourDPQuizResultsType | undefined>>;
  setError: Dispatch<SetStateAction<string | undefined>>;
  setPage: Dispatch<SetStateAction<number>>;
  skipEducation: boolean;
}) {
  const { currentUser, userIsLoading, userError, mutateUser } = useUserContext();
  const handleReload = useCallback(() => {
    mutateUser();
  }, [mutateUser]);

  return (
    <ErrorBoundary error={userError} isLoading={userIsLoading} onReload={handleReload}>
      <Box display="flex" flexDirection="column" width="100%" height="100%">
        {currentUser && (
          <FourDPQuiz
            user={currentUser}
            setError={setError}
            setResults={setResults}
            setPage={setPage}
            skipEducation={skipEducation}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
}

export default FourDPQuizContainer;
