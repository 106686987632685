import { Box, Chip, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useCallback } from "react";
import ErrorBoundary from "../ErrorBoundary";
import { t, Trans } from "@lingui/macro";
import useFitnessProfile from "./useFitnessProfile";
import ProfileCard from "./ProfileCard";
import AthleteProfileFeature from "@/assets/AthleteProfileFeature.png";
import FourDPChart from "./FourDPChart";
import ProfileDescription from "./ProfileDescription";
import FitnessProfileAccordion from "./FitnessProfileAccordion";
import { InfoIcon } from "@WahooFitness/redesignr";
import { GlobalHeader } from "../GlobalHeader";
import RiderTypeMismatchAlert from "./RiderTypeMismatchAlert";
import FitnessAssessmentCard from "./FitnessAssessmentCard";
import { useNavigate } from "react-router";
import { Help } from "@carbon/icons-react";
import { useLogEvent } from "@/services/Analytics/useLogEvent";
import { ClickTarget, SourcePage } from "@/services/Analytics/EventTypes";

const RiderType = () => {
  const {
    userFitnessProfilesIsLoading,
    userFitnessProfilesError,
    mutateFitnessProfile,
    riderTypeName,
    riderTypeIcon,
    hasNoCyclingFitnessProfile,
    profileDescription,
    cyclingFitnessProfilesData,
    handleQuestionnaireClick,
    showQuestionnaireCard,
    riderStrength,
    riderWeakness,
    openInfoDrawer,
    isRiderTypeEstimate,
    handleDismissRiderTypeAlert,
    userDismissedRiderTypeAlert,
    hasRiderTypeMismatch,
    openEstimateExplanationDrawer,
    show4DPEducation,
  } = useFitnessProfile();
  const { breakpoints, palette } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("sm"));
  const navigate = useNavigate();
  const { logEvent } = useLogEvent();
  const handleReload = useCallback(() => {
    mutateFitnessProfile();
  }, [mutateFitnessProfile]);

  const chartValues = {
    FTP: cyclingFitnessProfilesData?.dim_1 || 0,
    MAP: cyclingFitnessProfilesData?.dim_2 || 0,
    AC: cyclingFitnessProfilesData?.dim_3 || 0,
    NM: cyclingFitnessProfilesData?.dim_4 || 0,
    rankFTP: cyclingFitnessProfilesData?.dim_1_rank || 0,
    rankMAP: cyclingFitnessProfilesData?.dim_2_rank || 0,
    rankAC: cyclingFitnessProfilesData?.dim_3_rank || 0,
    rankNM: cyclingFitnessProfilesData?.dim_4_rank || 0,
  };

  return (
    <ErrorBoundary
      isLoading={userFitnessProfilesIsLoading}
      error={userFitnessProfilesError}
      onReload={handleReload}
    >
      <GlobalHeader
        title={t`Athlete Profile`}
        headerAction={
          show4DPEducation ? (
            <IconButton
              onClick={() => {
                logEvent("4dp_education", {
                  click_target: ClickTarget.question_icon,
                  source_page_name: SourcePage.fitness_profile,
                });
                navigate("/fourdp-education?standAlone");
              }}
            >
              <Help size="24px" color={palette.text.primary} />
            </IconButton>
          ) : undefined
        }
        disableBoxShadow
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          sx={{
            position: "relative",
            width: "100vw",
            backgroundColor: palette.background.paperElevation4,
          }}
          display="flex"
          pt={2}
          alignItems="center"
          flexDirection="column"
        >
          <Box
            id="inner-container-top-section"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            maxWidth="sm"
            width="100%"
            gap={1.5}
            pb={3}
          >
            <Stack direction="row" alignItems="center" px={2} width="100%">
              <Typography variant="ui-4xl-bold">
                {hasNoCyclingFitnessProfile ? t`Not set` : riderTypeName}
              </Typography>
              {!hasNoCyclingFitnessProfile && (
                <Box width={24} height={24} ml={1.5}>
                  {riderTypeIcon}
                </Box>
              )}
              {isRiderTypeEstimate && (
                <Chip
                  label={
                    <Box display="flex" gap={0.75} alignItems="center">
                      <Typography>
                        <Trans>Estimate</Trans>
                      </Typography>
                      <InfoIcon fontSize="small" />
                    </Box>
                  }
                  size="small"
                  sx={{
                    borderRadius: 0.5,
                    backgroundColor: "action.selected",
                    color: "text.primary",
                    maxWidth: "fit-content",
                    ml: "auto",
                  }}
                  onClick={openEstimateExplanationDrawer}
                />
              )}
            </Stack>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              pb={2}
              width="100%"
              maxWidth="sm"
              gap={2}
            >
              <FourDPChart
                FTP={chartValues.FTP}
                MAP={chartValues.MAP}
                AC={chartValues.AC}
                NM={chartValues.NM}
                rankFTP={chartValues.rankFTP}
                rankMAP={chartValues.rankMAP}
                rankAC={chartValues.rankAC}
                rankNM={chartValues.rankNM}
                isEmpty={hasNoCyclingFitnessProfile}
              />
              {!hasNoCyclingFitnessProfile && (
                <Box display="flex" flexDirection="row" gap={1} onClick={openInfoDrawer}>
                  <InfoIcon fontSize="small" color="info" />
                  <Typography variant="prose-xs-medium" color={palette.info.main}>
                    <Trans>How we calculate?</Trans>
                  </Typography>
                </Box>
              )}
              {!userDismissedRiderTypeAlert && hasRiderTypeMismatch && (
                <RiderTypeMismatchAlert onClose={handleDismissRiderTypeAlert} />
              )}
            </Box>
            <ProfileDescription
              hasNoCyclingFitnessProfile={hasNoCyclingFitnessProfile}
              profileDescription={profileDescription}
            />
            {riderStrength && riderWeakness && (
              <Stack direction={{ xs: "column", sm: "row" }} spacing={1.5} width="100%" mt={2.5}>
                <FitnessProfileAccordion
                  title={t`Strength`}
                  name={riderStrength.name}
                  icon={riderStrength.icon}
                  isSmallScreen={isSmallScreen}
                  defaultExpanded={!isSmallScreen}
                  details={<Typography>{riderStrength.description}</Typography>}
                />

                <FitnessProfileAccordion
                  title={t`Weakness`}
                  name={riderWeakness.name}
                  icon={riderWeakness.icon}
                  isSmallScreen={isSmallScreen}
                  defaultExpanded={!isSmallScreen}
                  details={<Typography>{riderWeakness.description}</Typography>}
                />
              </Stack>
            )}
          </Box>
        </Box>
        <Stack
          justifyContent="center"
          maxWidth="sm"
          px={2}
          gap={2}
          py={4}
          sx={{
            backgroundColor: palette.background.default,
          }}
        >
          <Typography variant="ui-md-bold" color="text.primary" mt={2}>
            {hasNoCyclingFitnessProfile ? t`Start` : t`Update`} profiling
          </Typography>
          {showQuestionnaireCard && (
            <ProfileCard
              icon={AthleteProfileFeature}
              title={t`Questionnaire`}
              tag={t`Estimate`}
              time={t`5 min`}
              buttonText={t`Start questionnaire`}
              description={t`Estimate your strength and weaknesses as an athlete.`}
              onClick={handleQuestionnaireClick}
            />
          )}
          <FitnessAssessmentCard />
        </Stack>
      </Box>
    </ErrorBoundary>
  );
};

export default RiderType;
