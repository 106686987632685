import useZones, { ZoneSet } from "@/components/Zones/useZones";
import { PowerZones as PowerZonesClient } from "@WahooFitness/cloud-client-ts";
import { Box } from "@mui/system";
import { FourDPQuizResultsType, PowerAlgorithmEnum } from "@WahooFitness/cloud-client-types";
import {
  useCloudContext,
  useConfigContext,
  useDrawerContext,
  useGuardedUpdater,
  useOfflineSWR,
  useSnackbarContext,
} from "@WahooFitness/wahoo-offline-mfe";
import { useCallback, useMemo, useState } from "react";
import usePowerZones from "@/components/Zones/usePowerZones";
import { t } from "@lingui/macro";
import ZoneViewComponent from "./ZoneViewComponent";
import { ClickTarget, SourcePage } from "@/services/Analytics/EventTypes";
import { useLogEvent } from "@/services/Analytics/useLogEvent";
import { useHeaderContext } from "@/hooks/useHeaderContext";

const usePowerZoneUpdate = (results?: FourDPQuizResultsType) => {
  const { savedZoneSets } = useZones("power");
  const { extractZonesForDisplay } = usePowerZones();
  const { setDrawer } = useDrawerContext();
  const { enqueueSnackbar } = useSnackbarContext();
  const { getCloudClient } = useCloudContext();
  const { navigateBack } = useHeaderContext();
  const { wahooToken } = useConfigContext();
  const { update } = useGuardedUpdater();
  const { logEvent } = useLogEvent();

  const powerZonesClient = useMemo(() => getCloudClient(PowerZonesClient), [getCloudClient]);
  const lookupObject = useMemo(() => {
    if (results) {
      return {
        power_zone_algorithm_id: PowerAlgorithmEnum["4DP"],
        ftp: results.profile.fourdp.FTP,
        ac: results.profile.fourdp.AC,
        map: results.profile.fourdp.MAP,
        nm: results.profile.fourdp.NM,
        zone_count: 7,
      };
    }
  }, [results]);

  const {
    data: calculated4DPPowerZones,
    isLoading: calculated4DPPowerZonesLoading,
    error: calculated4DPPowerZonesError,
  } = useOfflineSWR(
    ["getPowerZonesFrom4DP", wahooToken, lookupObject],
    async ([_key, wahooToken, lookupObject]) => {
      return await powerZonesClient.algorithmLookup(wahooToken, lookupObject);
    }
  );

  const displayCalculated4DPPowerZones = useMemo(() => {
    if (!calculated4DPPowerZones) {
      return undefined;
    }
    return extractZonesForDisplay(calculated4DPPowerZones);
  }, [calculated4DPPowerZones, extractZonesForDisplay]);

  const [selectedZoneSet, setSelectedZoneSet] = useState<string | undefined>(undefined);
  const hasMultipleZoneSets = useMemo(() => Object.keys(savedZoneSets).length > 1, [savedZoneSets]);
  const zoneKeys: number[] = useMemo(
    () => Object.keys(savedZoneSets).map((key) => Number(key)),
    [savedZoneSets]
  );

  const updateZoneSet = useCallback(() => {
    if (
      wahooToken &&
      selectedZoneSet &&
      calculated4DPPowerZones &&
      !isNaN(Number(selectedZoneSet))
    ) {
      update(
        () => powerZonesClient.put(wahooToken, Number(selectedZoneSet), calculated4DPPowerZones),
        {
          afterUpdate: () =>
            enqueueSnackbar({
              message: t`Zone set successfully updated`,
              severity: "success",
            }),
          errorMessage: t`Failed to update zone set`,
        }
      );
    }
  }, [
    calculated4DPPowerZones,
    enqueueSnackbar,
    powerZonesClient,
    selectedZoneSet,
    update,
    wahooToken,
  ]);

  const handleZoneInfoClick = useCallback(
    (zoneSet: ZoneSet) => {
      setDrawer({
        open: true,
        title: zoneSet.name,
        children: (
          <Box px={2} pb={3}>
            <ZoneViewComponent zones={zoneSet.zones} />
          </Box>
        ),
        hidePuller: true,
        globalBottomPadding: "16px",
      });
    },
    [setDrawer]
  );

  const options = useMemo(
    () => [
      ...Object.keys(savedZoneSets).map((key) => ({
        key: key,
        text: { title: savedZoneSets[Number(key)].name ?? "" },
      })),
      { key: "none", text: { title: "None" } },
    ],
    [savedZoneSets]
  );
  const onChange = useCallback((ev: any) => {
    setSelectedZoneSet(ev.target.value);
  }, []);

  const handleExit = useCallback(() => {
    navigateBack();
  }, [navigateBack]);

  const handleSave = useCallback(async () => {
    logEvent("4dp_quiz", {
      click_target: ClickTarget.save,
      source_page_name: SourcePage.fourdp_quiz_powerzones,
      selected_zone_set: selectedZoneSet,
    });
    if (selectedZoneSet && !isNaN(Number(selectedZoneSet))) {
      await updateZoneSet();
    }
    handleExit();
  }, [handleExit, logEvent, selectedZoneSet, updateZoneSet]);

  return {
    selectedZone: selectedZoneSet,
    onChange,
    options,
    hasMultipleZoneSets,
    savedZoneSets,
    handleZoneInfoClick,
    handleSave,
    zoneKeys,
    calculated4DPPowerZones,
    calculated4DPPowerZonesLoading,
    calculated4DPPowerZonesError,
    displayCalculated4DPPowerZones,
  };
};

export default usePowerZoneUpdate;
