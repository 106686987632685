import { Box, Stack } from "@mui/system";

import { Divider, Typography, useTheme } from "@mui/material";

export type Tick = {
  label?: string;
  height: number;
  labelVariant?: string;
};

function XAxis({ ticks }: { ticks: Tick[] }) {
  const { palette } = useTheme();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        position="relative"
        flexDirection="column"
      >
        <Divider
          id="first"
          sx={{
            position: "absolute",
            top: "50%",
            left: 0,
            right: 0,
            height: "1px",
            bgcolor: "text.disabled",
          }}
        />
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems={"center"}>
          {ticks.map((tick, index) => (
            <Divider
              id="mini"
              key={index}
              sx={{
                height: tick.height,
                backgroundColor: "text.disabled",
                border: `1px solid ${palette.text.disabled}`,
                zIndex: 1,
              }}
            />
          ))}
        </Stack>
      </Box>
      <Stack direction="row" justifyContent="space-between" width="100%" mt={2}>
        {ticks.map((tick, index) => (
          <Typography key={index} variant={tick.labelVariant as any}>
            {tick.label?.toUpperCase()}
          </Typography>
        ))}
      </Stack>
    </>
  );
}

export default XAxis;
