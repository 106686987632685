import { t, Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import RouteGroup from "../RouteList/RouteGroup";
import ErrorBoundary from "@/components/ErrorBoundary";
import { useRoutesContext } from "@/hooks/useRoutesContext";
import { PullToRefresh } from "@WahooFitness/wahoo-offline-mfe";
import { GlobalHeader } from "@/components/GlobalHeader";

const PublicRoutesList = () => {
  const { startPublicRouteSync, publicRoutes, publicRoutesAreSynced } = useRoutesContext();

  useEffect(() => {
    startPublicRouteSync();
  }, [startPublicRouteSync]);

  return (
    <Box m={2} height="100%" display="flex" flexDirection="column" gap={1}>
      <PullToRefresh onRefresh={startPublicRouteSync} />
      <GlobalHeader title={t`Public routes`} />
      <ErrorBoundary isLoading={!publicRoutesAreSynced} error={false}>
        {publicRoutes && publicRoutes.length ? (
          <>
            <Typography variant="prose-sm" color="text.secondary">
              <Trans>
                You will only see routes that start within 10km of your current location.
              </Trans>
            </Typography>
            <RouteGroup
              routes={publicRoutes}
              title={t`${publicRoutes.length} routes`}
              fromPublicRouteList
            />
          </>
        ) : (
          <Box
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Typography variant="ui-lg-medium">
              <Trans>No public routes found</Trans>
            </Typography>
            <Typography variant="prose-sm" color="text.secondary">
              <Trans>
                You will only see routes that start within 10km of your current location.
              </Trans>
            </Typography>
          </Box>
        )}
      </ErrorBoundary>
    </Box>
  );
};

export default PublicRoutesList;
