import { t } from "@lingui/macro";
import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useState, useEffect, useRef, useCallback } from "react";

interface FourDPChartProps {
  NM: number;
  FTP: number;
  AC: number;
  MAP: number;
  rankMAP: number;
  rankFTP: number;
  rankAC: number;
  rankNM: number;
  isEmpty?: boolean;
}

const FourDPChart = ({
  NM,
  FTP,
  AC,
  MAP,
  rankMAP,
  rankFTP,
  rankAC,
  rankNM,
  isEmpty,
}: FourDPChartProps) => {
  const { palette } = useTheme();
  const componentHeight = isEmpty ? 270 : 320;
  const BAR_PADDING = isEmpty ? 8 : 8;
  const BAR_HEIGHT = isEmpty ? 20 : 24;
  const MAX_RADIUS = (componentHeight - (BAR_HEIGHT + BAR_PADDING) * 2) / 2;
  const minWidth = (MAX_RADIUS + BAR_HEIGHT) * 2;

  const calculateCircleValues = useCallback(
    (value: number, index: number) => {
      const radius = MAX_RADIUS - (BAR_HEIGHT + BAR_PADDING) * index;
      const circumference = 2 * Math.PI * radius;
      const maxFillRatio = 0.75;
      const clampedValue = Math.max(-1, Math.min(1, value));
      const arcLength = circumference * ((clampedValue + 1) / 2) * maxFillRatio;
      const dashoffset = circumference - arcLength;
      const dashoffsetBorder = circumference - arcLength - 1;
      const dashoffsetMax = circumference * (1 - maxFillRatio);
      return { radius, circumference, dashoffsetMax, dashoffset, dashoffsetBorder, clampedValue };
    },
    [MAX_RADIUS, BAR_HEIGHT, BAR_PADDING]
  );

  const circles = useMemo(
    () => [
      {
        id: 0,
        name: "Endure",
        color: palette["4dpFtp"].main,
        colorGradientStart: palette["4dpFtp"].gradientStart,
        colorGradientEnd: palette["4dpFtp"].gradientEnd,
        rank: rankFTP,
        wattage: FTP,
      },
      {
        id: 1,
        name: "Breakaway",
        color: palette["4dpMap"].main,
        colorGradientStart: palette["4dpMap"].gradientStart,
        colorGradientEnd: palette["4dpMap"].gradientEnd,
        rank: rankMAP,
        wattage: MAP,
      },
      {
        id: 2,
        name: "Attack",
        color: palette["4dpAc"].main,
        colorGradientStart: palette["4dpAc"].gradientStart,
        colorGradientEnd: palette["4dpAc"].gradientEnd,
        rank: rankAC,
        wattage: AC,
      },
      {
        id: 3,
        name: "Sprint",
        color: palette["4dpNm"].main,
        colorGradientStart: palette["4dpNm"].gradientStart,
        colorGradientEnd: palette["4dpNm"].gradientEnd,
        rank: rankNM,
        wattage: NM,
      },
    ],
    [AC, FTP, MAP, NM, palette, rankAC, rankFTP, rankMAP, rankNM]
  );

  const circleData = useMemo(
    () =>
      circles.map((circle, index) => ({
        ...circle,
        ...calculateCircleValues(circle.rank, index),
      })),
    [circles, calculateCircleValues]
  );
  const chartRef = useRef<SVGSVGElement>(null);
  const [componentWidth, setComponentWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const BoundingWidth = chartRef.current?.getBoundingClientRect().width || 0;
      const width = Math.max(BoundingWidth, minWidth);
      setComponentWidth(width);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [minWidth]);

  return (
    <Box
      ref={chartRef}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
    >
      <svg
        width={componentWidth}
        height={componentHeight}
        viewBox={`0 0 ${componentWidth} ${componentHeight}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* background circles */}
        {circleData.map((circle, index) => (
          <circle
            key={index}
            cx={componentWidth / 2}
            cy={componentHeight / 2}
            r={circle.radius}
            stroke={palette.secondary.main}
            strokeWidth={BAR_HEIGHT}
            fill="none"
            strokeDasharray={circle.circumference}
            strokeDashoffset={circle.dashoffsetMax}
            transform={`rotate(-90 ${componentWidth / 2} ${componentHeight / 2})`}
          />
        ))}

        {/* border circles */}
        {!isEmpty &&
          circleData.map((circle, index) => (
            <circle
              key={index}
              cx={componentWidth / 2}
              cy={componentHeight / 2}
              r={circle.radius}
              stroke={palette.background.paperElevation4}
              strokeWidth={BAR_HEIGHT + 5}
              fill="none"
              strokeDasharray={circle.circumference}
              strokeDashoffset={circle.circumference}
              strokeLinecap={circle.clampedValue === 1 ? "butt" : "round"}
              transform={`rotate(-90 ${componentWidth / 2} ${componentHeight / 2})`}
            >
              <animate
                attributeName="stroke-dashoffset"
                values={`${circle.circumference};${circle.dashoffset}`}
                dur="1.0s"
                begin="0.3s"
                calcMode="spline"
                keySplines="0.05, 0.1, 0.25, 1"
                fill="freeze"
              />
            </circle>
          ))}

        {/* rider type circles */}
        {!isEmpty && (
          <>
            <defs>
              {circleData.map((circle, index) => (
                <linearGradient id={`gradient-${index}`} key={index} gradientTransform="rotate(5)">
                  <stop offset="0%" stopColor={circle.colorGradientStart} stopOpacity="1" />
                  <stop offset="100%" stopColor={circle.colorGradientEnd} stopOpacity="1" />
                </linearGradient>
              ))}
            </defs>
            {circleData.map((circle, index) => (
              <circle
                key={index}
                cx={componentWidth / 2}
                cy={componentHeight / 2}
                r={circle.radius}
                stroke={`url(#gradient-${index})`}
                strokeWidth={BAR_HEIGHT}
                fill="none"
                strokeDasharray={circle.circumference}
                strokeDashoffset={circle.circumference}
                strokeLinecap={circle.clampedValue === 1 ? "butt" : "round"}
                transform={`rotate(-90 ${componentWidth / 2} ${componentHeight / 2})`}
              >
                <animate
                  attributeName="stroke-dashoffset"
                  values={`${circle.circumference};${circle.dashoffset}`}
                  dur="1.0s"
                  begin="0.3s"
                  calcMode="spline"
                  keySplines="0.05, 0.1, 0.25, 1"
                  fill="freeze"
                />
              </circle>
            ))}
          </>
        )}

        {/* label squares */}
        {circleData.map((circle, index) => (
          <rect
            key={index}
            x="0"
            y={BAR_PADDING + 0.5 * BAR_HEIGHT + (BAR_PADDING + BAR_HEIGHT) * index}
            width={componentWidth / 2}
            height={BAR_HEIGHT}
            fill={isEmpty ? palette.secondary.main : circle.colorGradientEnd}
          />
        ))}
        {isEmpty && (
          <>
            <defs>
              {circleData.map((circle, index) => (
                <linearGradient id={`empty-gradient-${index}`} key={index}>
                  <stop offset="0%" stopColor={circle.colorGradientEnd} stopOpacity="1" />
                  <stop offset="100%" stopColor={circle.colorGradientStart} stopOpacity="1" />
                </linearGradient>
              ))}
            </defs>
            {circleData.map((_circle, index) => (
              <rect
                key={index}
                x="0"
                y={BAR_PADDING + 0.5 * BAR_HEIGHT + (BAR_PADDING + BAR_HEIGHT) * index}
                width={10}
                height={BAR_HEIGHT}
                fill={`url(#empty-gradient-${index})`}
              />
            ))}
          </>
        )}

        {/* labels */}
        {circleData.map((circle, index) => (
          <g key={index}>
            <text
              x="16"
              y={
                BAR_PADDING +
                0.5 * BAR_HEIGHT +
                (BAR_PADDING + BAR_HEIGHT) * index +
                BAR_HEIGHT / 2 +
                5
              }
              textAnchor="start"
              fontSize="14"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight={isEmpty ? "500" : "600"}
              fill={isEmpty ? palette.text.primary : palette.common.black}
            >
              {circle.name.toUpperCase()}
            </text>

            <text
              x={componentWidth / 2 - 16}
              y={
                BAR_PADDING +
                0.5 * BAR_HEIGHT +
                (BAR_PADDING + BAR_HEIGHT) * index +
                BAR_HEIGHT / 2 +
                5
              }
              fontSize="14"
              textAnchor="end"
              fontFamily="Inter"
              fontStyle="normal"
              fontWeight="700"
              fill={isEmpty ? palette.text.primary : palette.common.black}
            >
              {isEmpty ? 0 : circle.wattage}
              <tspan fontWeight="400"> w</tspan>
            </text>
          </g>
        ))}

        {/* center-dotted lines */}
        <line
          x1={(componentWidth - (MAX_RADIUS + BAR_HEIGHT - 2) * 2) / 2}
          y1={componentHeight / 2}
          x2={componentWidth / 2}
          y2={componentHeight / 2}
          stroke={palette.divider}
          strokeWidth="2"
          strokeDasharray="4,4"
        />
        <line
          x1={componentWidth / 2}
          y1={(componentHeight - (MAX_RADIUS + BAR_HEIGHT - 2) * 2) / 2}
          x2={componentWidth / 2}
          y2={componentHeight / 2}
          stroke={palette.divider}
          strokeWidth="2"
          strokeDasharray="4,4"
        />
        <line
          x1={componentWidth / 2}
          y1={componentHeight / 2}
          x2={componentWidth / 2 + (MAX_RADIUS + BAR_HEIGHT - 2) / Math.sqrt(2)}
          y2={componentHeight / 2 + (MAX_RADIUS + BAR_HEIGHT - 2) / Math.sqrt(2)}
          stroke={palette.divider}
          strokeWidth="2"
          strokeDasharray="4,4"
        />

        {/* circle labels */}
        <defs>
          <path
            id="modestPath"
            d={`M ${componentWidth / 2},${componentHeight / 2 - MAX_RADIUS - BAR_HEIGHT - 2} 
          A ${MAX_RADIUS + BAR_HEIGHT + 2},${MAX_RADIUS + BAR_HEIGHT + 2} 0 0,1 
          ${componentWidth / 2 + MAX_RADIUS + BAR_HEIGHT + 2},${componentHeight / 2}`}
            fill="none"
          />
          <path
            id="averagePath"
            d={`M ${componentWidth / 2},${componentHeight / 2 + MAX_RADIUS + BAR_HEIGHT + 12} 
          A ${MAX_RADIUS + BAR_HEIGHT + 12},${MAX_RADIUS + BAR_HEIGHT + 12} 0 0,0 
          ${componentWidth / 2 + MAX_RADIUS + BAR_HEIGHT + 12},${componentHeight / 2}`}
            fill="none"
          />
          <path
            id="exceptionalPath"
            d={`M ${componentWidth / 2 - MAX_RADIUS - BAR_HEIGHT - 12},${componentHeight / 2} 
          A ${MAX_RADIUS + BAR_HEIGHT + 12},${MAX_RADIUS + BAR_HEIGHT + 12} 0 0,0 
          ${componentWidth / 2},${componentHeight / 2 + MAX_RADIUS + BAR_HEIGHT + 12}`}
            fill="none"
          />
        </defs>

        <text fill={palette.text.secondary} fontFamily="inter" fontSize="12" textAnchor="bottom">
          <textPath href="#modestPath" startOffset="50%">
            <tspan letterSpacing="1.5">{t`MODEST`}</tspan>
          </textPath>
        </text>
        <text fill={palette.text.secondary} fontFamily="inter" fontSize="12" textAnchor="middle">
          <textPath href="#averagePath" startOffset="50%">
            <tspan letterSpacing="1.5">{t`AVERAGE`}</tspan>
          </textPath>
        </text>
        <text fill={palette.text.secondary} fontFamily="inter" fontSize="12" textAnchor="middle">
          <textPath href="#exceptionalPath" startOffset="50%">
            <tspan letterSpacing="1.5">{t`EXCEPTIONAL`}</tspan>
          </textPath>
        </text>
      </svg>
    </Box>
  );
};

export default FourDPChart;
