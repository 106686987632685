import ErrorBoundary from "@/components/ErrorBoundary";
import { Trans, t } from "@lingui/macro";
import { Box, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { BlueSwitch, MenuList, MenuListItemVariant } from "@WahooFitness/redesignr";
import { GlobalHeader } from "@/components/GlobalHeader";

const LEDSettings = ({ workoutProfileId }: { workoutProfileId: number }) => {
  const { palette } = useTheme();

  const { getWorkoutProfile, workoutProfilesLoading, workoutProfilesError, updateProfile } =
    useWorkoutProfilesContext();

  const workoutProfile = useMemo(
    () => getWorkoutProfile(workoutProfileId),
    [getWorkoutProfile, workoutProfileId]
  );

  const handleChangeLEDsMode = useCallback(
    (_: any, value: number) => {
      if (value !== null && value !== undefined && workoutProfile) {
        updateProfile(workoutProfile.id, "leds_mode", value);
      }
    },
    [updateProfile, workoutProfile]
  );

  const toggleDescription = useMemo(() => {
    switch (workoutProfile?.leds_mode) {
      case 0:
        return t`Metric-based LEDs are disabled`;
      case 1:
        return t`Shows your current speed compared to the ride average.`;
      case 5:
        return t`Shows your current power zone.`;
      case 6:
        return t`Shows your current heart rate zone.`;
      default:
        return t`Metric-based LEDs are disabled`;
    }
  }, [workoutProfile?.leds_mode]);

  const sections = useMemo(
    () => [
      {
        id: "leds_mode",
        content: t`LED metric indicator`,
        secondaryContent: (
          <Box display="flex" flexDirection="column" rowGap={1}>
            <ToggleButtonGroup
              exclusive
              fullWidth
              value={workoutProfile?.leds_mode ?? 0}
              onChange={handleChangeLEDsMode}
            >
              <ToggleButton value={0}>
                <Trans>Off</Trans>
              </ToggleButton>
              <ToggleButton value={1}>
                <Trans>Speed</Trans>
              </ToggleButton>
              <ToggleButton value={5}>
                <Trans>Power</Trans>
              </ToggleButton>
              <ToggleButton value={6}>
                <Trans>HR</Trans>
              </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="prose-sm" color={palette.text.secondary}>
              {toggleDescription}
            </Typography>
          </Box>
        ),
        variant: MenuListItemVariant.Accordion,
        expanded: true,
        actionComponent: <></>,
      },
      {
        id: "segments_commandeer_leds",
        content: t`Strava Live Segments`,
        secondaryContent: t`Indicates how far ahead or behind you are compared to the target time`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={workoutProfile?.segments_commandeer_leds}
            data-testid="segments_commandeer_leds"
          />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(
              workoutProfile.id,
              "segments_commandeer_leds",
              !workoutProfile.segments_commandeer_leds
            );
          }
        },
      },
      {
        id: "plans_commandeer_leds",
        content: t`Planned workouts`,
        secondaryContent: t`Indicates how close you are to the chosen target metric`,
        variant: MenuListItemVariant.Action,
        actionComponent: (
          <BlueSwitch
            checked={workoutProfile?.plans_commandeer_leds}
            data-testid="plans_commandeer_leds"
          />
        ),
        action: () => {
          if (workoutProfile) {
            updateProfile(
              workoutProfile.id,
              "plans_commandeer_leds",
              !workoutProfile.plans_commandeer_leds
            );
          }
        },
      },
    ],
    [handleChangeLEDsMode, palette.text.secondary, toggleDescription, updateProfile, workoutProfile]
  );

  return (
    <ErrorBoundary isLoading={workoutProfilesLoading} error={workoutProfilesError}>
      <GlobalHeader title={t`LEDs`} />
      <Box
        maxWidth="sm"
        width="100%"
        height="100%"
        overflow="scroll"
        display="flex"
        flexDirection="column"
      >
        <MenuList listItems={sections} />
        <Typography textAlign="center" variant="prose-sm" color={palette.text.secondary}>
          <Trans>Settings only apply to ELEMNTs that have LEDs.</Trans>
        </Typography>
      </Box>
    </ErrorBoundary>
  );
};

export default LEDSettings;
