import { useMemo } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Trans } from "@lingui/macro";
import { UserType } from "@WahooFitness/cloud-client-types";
import CustomStepper from "./CustomStepper";
import useAthleteOnboarding from "./useAthleteOnboarding";
import AthleteOnboardingFeatureScreen from "./AppFeatureScreen";
import { GlobalHeader } from "../GlobalHeader";

const AthleteOnboarding = ({ user }: { user: UserType }) => {
  const {
    activeStep,
    steps,
    handleNext,
    handleBack,
    handleSkip,
    showFeatureScreen,
    selectedSportOptions,
  } = useAthleteOnboarding(user);
  const { palette } = useTheme();
  const SkipButton = useMemo(
    () => (
      <Button variant="text" onClick={handleSkip}>
        <Trans>Skip</Trans>
      </Button>
    ),
    [handleSkip]
  );

  if (showFeatureScreen) {
    return (
      <>
        <GlobalHeader />
        <AthleteOnboardingFeatureScreen
          selectedSportOptions={selectedSportOptions}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      </>
    );
  }

  return (
    // the use of a boxShadow here is a way to extend the background color
    // of the page beyond the maxWidth of the component to cover the whole screen
    <>
      <GlobalHeader
        title=""
        backAction={handleBack}
        disableBackButton={activeStep === 0}
        headerAction={steps[activeStep].skippable ? SkipButton : undefined}
        disableBoxShadow
      />
      <Box height="100%" sx={{ boxShadow: `0 0 0 100vmax ${palette.background.paperElevation4}` }}>
        <Box
          display="flex"
          position="absolute"
          left={0}
          pl={2}
          py={1}
          sx={{ backgroundColor: palette.background.paperElevation4 }}
        >
          <CustomStepper steps={steps} activeStep={activeStep} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          sx={{ backgroundColor: palette.background.paperElevation4 }}
        >
          <Box display="flex" flexDirection="column" px={2} pt={[5, 17.5]} rowGap={1.5}>
            <Typography variant="ui-4xl-bold">{steps[activeStep].headline}</Typography>
            <Typography variant="prose-base" color="text.secondary">
              {steps[activeStep].subheader}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" pt={[4.5, 9]} width="100%" px={2}>
            {steps[activeStep].component}
          </Box>
          <Box p={3} width="100%" mt={["auto", 11.25]} maxWidth="sm">
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={handleNext}
              disabled={steps[activeStep].disabled}
            >
              <Trans>Continue</Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AthleteOnboarding;
