import { WarningAlt } from "@carbon/icons-react";
import { Trans } from "@lingui/macro";
import { Box, Button, Typography } from "@mui/material";
import { FancyIcon } from "@WahooFitness/redesignr";

type DiscardResultsDrawerProps = {
  onCancel: () => void;
  onDiscard: () => void;
};
function DiscardResultsDrawer({ onCancel, onDiscard }: DiscardResultsDrawerProps) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" px={2} py={3} gap={3}>
      <FancyIcon icon={<WarningAlt />} size="medium" color="warning" />

      <Typography variant="ui-md-medium" textAlign="center">
        <Trans>Discard results?</Trans>
      </Typography>
      <Typography variant="prose-base" color="text.secondary" px={3.5} textAlign="center">
        <Trans>Your results will not be saved to your profile. This action cannot be undone.</Trans>
      </Typography>
      <Box
        gap={1.5}
        maxWidth="sm"
        display="flex"
        flexDirection="column"
        width="100%"
        justifyContent="center"
      >
        <Button variant="outlined" size="large" fullWidth color="error" onClick={onDiscard}>
          <Trans>Discard results</Trans>
        </Button>
        <Button variant="outlined" size="large" fullWidth color="primary" onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
      </Box>
    </Box>
  );
}

export default DiscardResultsDrawer;
