import { SettingsRemoteConfigKey } from "@/services/SettingsDefaultRemoteConfig";
import { useVersionedFeatures } from "@WahooFitness/wahoo-offline-mfe";

// We wrap the context remote config getters so we can have type safe
// remote config keys that have local defaults.
export const useSettingsVersionedFeatures = (key: SettingsRemoteConfigKey) => {
  const { enabled, featuresAreLoading } = useVersionedFeatures(key);

  return {
    enabled,
    featuresAreLoading,
  };
};
