import useLocalizedFitnessData from "@/components/FitnessProfile/useLocalizedFitnessData";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import StrengthArrow from "@/assets/StrengthArrow.png";
import WeaknessArrow from "@/assets/WeaknessArrow.png";
import {
  FitnessProfileAlgorithm,
  FitnessProfileType,
  FourDPQuizResultsType,
  WahooWorkoutTypeFamily,
} from "@WahooFitness/cloud-client-types";
import {
  useCloudContext,
  useConfigContext,
  useDrawerContext,
  useGuardedUpdater,
} from "@WahooFitness/wahoo-offline-mfe";
import { FitnessProfiles } from "@WahooFitness/cloud-client-ts";
import { t } from "@lingui/macro";
import FourDPPages from "../FourDPPages";
import { useLogEvent } from "@/services/Analytics/useLogEvent";
import { ClickTarget, SourcePage } from "@/services/Analytics/EventTypes";
import HowWeCalculateDrawer from "@/components/FitnessProfile/HowWeCalculateDrawer";
import { useNavigate } from "react-router";
import DiscardResultsDrawer from "./DiscardResultsDrawer";
import FitnessTestDrawer from "./FitnessTestDrawer";
import { useHeaderContext } from "@/hooks/useHeaderContext";

const useQuizResults = (
  setPage: Dispatch<SetStateAction<number>>,
  results?: FourDPQuizResultsType
) => {
  const [resultsLoading, setResultsLoading] = useState(false);
  const { getCloudClient } = useCloudContext();
  const { wahooToken } = useConfigContext();
  const { update } = useGuardedUpdater();
  const { logEvent } = useLogEvent();
  const { setDrawer } = useDrawerContext();
  const navigate = useNavigate();
  useEffect(() => {
    setResultsLoading(true);
    setTimeout(() => {
      setResultsLoading(false);
    }, 2000);
  }, []);

  const reloadHandler = useCallback(() => {
    navigate("/fitness-profile");
  }, [navigate]);

  const { navigateBack } = useHeaderContext();

  const { getRiderTypeById, getRiderTypeStrengthById, getRiderTypeWeaknessById } =
    useLocalizedFitnessData(32);

  const riderTypeData = useMemo(
    () => getRiderTypeById(results?.profile?.athlete?.type),
    [results, getRiderTypeById]
  );

  const riderTypeStrength = useMemo(
    () => getRiderTypeStrengthById(results?.profile?.athlete?.strength),
    [results, getRiderTypeStrengthById]
  );

  const riderTypeWeakness = useMemo(
    () => getRiderTypeWeaknessById(results?.profile?.athlete?.weakness),
    [results, getRiderTypeWeaknessById]
  );

  const riderStrength = useMemo(() => {
    if (riderTypeStrength) {
      return {
        name: riderTypeStrength?.name,
        description: riderTypeStrength?.description,
        icon: StrengthArrow,
      };
    }
  }, [riderTypeStrength]);

  const riderWeakness = useMemo(() => {
    if (riderTypeWeakness) {
      return {
        name: riderTypeWeakness?.name,
        description: riderTypeWeakness?.description,
        icon: WeaknessArrow,
      };
    }
  }, [riderTypeWeakness]);

  const fitnessProfileClient = getCloudClient(FitnessProfiles);

  const navigateToZones = useCallback(() => {
    setPage(FourDPPages.zones);
  }, [setPage]);

  const saveFitnessProfile = useCallback(async () => {
    if (!results?.profile) return;
    logEvent("4dp_quiz", {
      click_target: ClickTarget.save,
      source_page_name: SourcePage.fourdp_quiz_results,
    });
    const fitnessProfile: Omit<FitnessProfileType, "user_id"> = {
      workout_type_family_id: WahooWorkoutTypeFamily.Cycling,
      fitness_type_id: results.profile.athlete.type,
      fitness_weakness_id: results.profile.athlete.weakness,
      fitness_strength_id: results.profile.athlete.strength,
      dim_1: results.profile.fourdp.FTP,
      dim_2: results.profile.fourdp.MAP,
      dim_3: results.profile.fourdp.AC,
      dim_4: results.profile.fourdp.NM,
      threshold_hr: null,
      preferred_cadence: null,
      dim_1_rank: results.profile.rank.FTP,
      dim_2_rank: results.profile.rank.MAP,
      dim_3_rank: results.profile.rank.AC,
      dim_4_rank: results.profile.rank.NM,
      fitness_profile_algorithm_id: FitnessProfileAlgorithm.FourDPQuiz,
    };
    return update(() => fitnessProfileClient.createFitnessProfile(fitnessProfile, wahooToken), {
      afterUpdate: () => navigateToZones(),
      errorMessage: t`There was a problem saving your profile. Check your network and try again.`,
    });
  }, [fitnessProfileClient, logEvent, navigateToZones, results, update, wahooToken]);

  const handleCloseDrawer = useCallback(() => {
    setDrawer({ open: false });
  }, [setDrawer]);

  const handleDiscardResults = useCallback(() => {
    logEvent("4dp_quiz", {
      click_target: ClickTarget.discard_results,
      source_page_name: SourcePage.fourdp_quiz_results,
    });
    setDrawer({ open: false });
    navigateBack();
  }, [logEvent, navigateBack, setDrawer]);

  const openInfoDrawer = useCallback(() => {
    logEvent("4dp_quiz", {
      click_target: ClickTarget.how_we_calculate,
      source_page_name: SourcePage.fourdp_quiz_results,
    });
    setDrawer({
      open: true,
      title: t`How we calculate?`,
      hidePuller: true,
      children: <HowWeCalculateDrawer />,
      elevation: 4,
    });
  }, [logEvent, setDrawer]);

  const discardResults = useCallback(() => {
    setDrawer({
      open: true,
      hidePuller: true,
      children: (
        <DiscardResultsDrawer onCancel={handleCloseDrawer} onDiscard={handleDiscardResults} />
      ),
      elevation: 4,
      hideHeader: true,
    });
  }, [setDrawer, handleCloseDrawer, handleDiscardResults]);

  const openFitnessTestDrawer = useCallback(() => {
    setDrawer({
      open: true,
      title: t`Why is this an estimate?`,
      hidePuller: true,
      children: <FitnessTestDrawer />,
      elevation: 4,
    });
  }, [setDrawer]);

  return {
    resultsLoading,
    riderTypeData,
    riderWeakness,
    riderStrength,
    chartValues: results?.profile?.fourdp,
    rankValues: results?.profile?.rank,
    saveFitnessProfile,
    openInfoDrawer,
    openFitnessTestDrawer,
    discardResults,
    reloadHandler,
  };
};

export default useQuizResults;
