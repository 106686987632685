import { Trans } from "@lingui/macro";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { Component, ErrorInfo, ReactElement, ReactNode } from "react";
import PageLoadError from "./PageLoadError";
import WarmingUp from "./WarmUp";
import Bugsnag from "@bugsnag/js";

interface Props {
  error?: any;
  isLoading?: boolean;
  fallback?: ReactElement;
  loadingComponent?: ReactElement;
  onReload?: () => void;
  showError?: boolean;
  children?: ReactNode;
}

interface State {
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: undefined,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    // These are unhandled errors.
    Bugsnag.notify(error, function (event) {
      event.addMetadata("ErrorBoundary", { inBoundary: true });
    });
  }

  public render() {
    const error = this.state.error ?? this.props.error;
    const loader = this.props.loadingComponent ?? <WarmingUp noTopSpacing fullHeight />;
    if (error) {
      return (
        <Box display="flex" flexDirection="column" height="100%" mx={2}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            gap={1}
          >
            {!this.props.showError &&
              (this.props.fallback ?? <PageLoadError isLoading={this.props.isLoading} />)}
            {this.props.showError && (
              <div>
                <p>
                  <Trans>Page Load Error (you are seeing this because you are special)</Trans>
                </p>
                <p>
                  {error.name}: {error.message}
                </p>
                {error.stack &&
                  error.stack.split("\n").map((line: string) => <p key={line}>{line}</p>)}
              </div>
            )}
            {this.props.onReload && (
              <Box mx={4} my={3}>
                <Button size="large" variant="contained" onClick={this.props.onReload} fullWidth>
                  <Trans>Reload</Trans>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      );
    }

    if (this.props.isLoading) {
      return loader;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
