import { Button, IconButton, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { FancyIcon, InfoIcon, PowerFilled } from "@WahooFitness/redesignr";
import { t, Trans } from "@lingui/macro";
import SelectComponent from "./SelectComponent";
import usePowerZoneUpdate from "./usePowerZoneUpdate";
import { FourDPQuizResultsType } from "@WahooFitness/cloud-client-types";
import Loader from "../Results/Loader";
import ErrorBoundary from "@/components/ErrorBoundary";
import { useEffect, useState } from "react";
import ZoneViewComponent from "./ZoneViewComponent";

interface Props {
  results?: FourDPQuizResultsType;
}

const PowerZoneUpdate = ({ results }: Props) => {
  const { palette } = useTheme();
  const {
    selectedZone,
    onChange,
    options,
    hasMultipleZoneSets,
    savedZoneSets,
    handleZoneInfoClick,
    handleSave,
    zoneKeys,
    calculated4DPPowerZonesLoading,
    calculated4DPPowerZonesError,
    displayCalculated4DPPowerZones,
  } = usePowerZoneUpdate(results);

  const [loadingDelay, setLoadingDelay] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingDelay(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ErrorBoundary
      isLoading={calculated4DPPowerZonesLoading || loadingDelay}
      error={calculated4DPPowerZonesError}
      loadingComponent={<Loader message={t`Calculating your power zones...`} />}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
          backgroundColor: palette.background.paperElevation4,
        }}
        display="flex"
        flexDirection="column"
        p={2}
        pt={1.5}
        alignItems="center"
        overflow="scroll"
        maxWidth="sm"
      >
        <Box display="flex" flexDirection="column" alignItems="center" width="100%" p={1.5} pb={2}>
          <FancyIcon
            icon={<PowerFilled sx={{ color: palette.dataPower.main }} />}
            size="medium"
            color="warning"
          />
          <Typography variant="ui-lg-medium" color="textPrimary" textAlign="center" mt={1}>
            <Trans>Recommended power zones based on your Athlete Profile.</Trans>
          </Typography>
        </Box>
        <Box bgcolor={palette.secondary.main} borderRadius="8px" px={2} width="100%">
          <ZoneViewComponent zones={displayCalculated4DPPowerZones ?? []} />
        </Box>
        <>
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            justifyContent="space-between"
            width="100%"
            mt={3}
            alignItems="center"
          >
            <Typography variant="ui-md-medium" color="textPrimary">
              {hasMultipleZoneSets ? (
                <Trans>Which power zones set do you want to update?</Trans>
              ) : (
                <Trans>Update your power zones?</Trans>
              )}
            </Typography>
            {!hasMultipleZoneSets && (
              <IconButton
                size="small"
                onClick={() => handleZoneInfoClick(savedZoneSets[zoneKeys[0]])}
              >
                <InfoIcon />
              </IconButton>
            )}
          </Box>
          <Box display="flex" pt={1.5} justifyItems="start" width="100%">
            <SelectComponent
              selectedZone={selectedZone}
              onChange={onChange}
              options={options}
              hasMultipleZoneSets={hasMultipleZoneSets}
              savedZoneSets={savedZoneSets}
              handleZoneInfoClick={handleZoneInfoClick}
              zoneKeys={zoneKeys}
            />
          </Box>
        </>
        <Box pt={3} width="100%" mt={1} maxWidth="sm" mb={3}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleSave}
            disabled={!selectedZone}
          >
            <Trans>Save</Trans>
          </Button>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default PowerZoneUpdate;
