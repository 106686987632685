import { t, Trans } from "@lingui/macro";
import { useCallback, useMemo, useState } from "react";
import { useLiveTrack } from "./useLiveTrack";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import {
  BlueSwitch,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
} from "@WahooFitness/redesignr";
import { PullToRefresh, useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import { AddLiveTrackContact } from "./AddLiveTrackContact";
import { MinusCircle } from "@phosphor-icons/react";
import { GlobalHeader } from "../GlobalHeader";

const LiveTrackContacts = () => {
  const { palette } = useTheme();
  const { setDialog, handleClose: handleDialogClose } = useDialogContext();
  const [addContactOpen, setAddContactOpen] = useState(false);
  const {
    liveTrackContacts,
    reloadLiveTrackContacts,
    toggleContactEnabled,
    deleteContact,
    liveTrackUpdateInProgress,
  } = useLiveTrack();

  const handleAddContact = useCallback(() => {
    setAddContactOpen(true);
  }, [setAddContactOpen]);

  const handleCloseAddContact = useCallback(() => {
    setAddContactOpen(false);
  }, [setAddContactOpen]);

  const handleConfirmDelete = useCallback(
    (contactId: number) => {
      setDialog({
        open: true,
        title: t`Remove auto-share email?`,
        body: t`Are you sure you want to remove this auto-share email? This cannot be undone.`,
        actions: [
          { text: t`Cancel` },
          {
            text: t`Remove`,
            color: "error",
            action: () => {
              deleteContact(contactId);
              handleDialogClose();
            },
          },
        ],
      });
    },
    [deleteContact, handleDialogClose, setDialog]
  );

  const contactsList = useMemo((): MenuListItemType[] => {
    return (liveTrackContacts ?? []).map((contact) => ({
      id: `${contact.id}`,
      content: (
        <Box display="flex" flexDirection="row" gap={2}>
          <IconButton
            onClick={() => handleConfirmDelete(contact.id)}
            sx={{ height: 24, width: 24 }}
            disabled={liveTrackUpdateInProgress}
          >
            <MinusCircle
              size={24}
              weight="fill"
              color={liveTrackUpdateInProgress ? palette.action.disabled : palette.error.main}
            />
          </IconButton>
          <Typography overflow="hidden" textOverflow="ellipsis">
            {contact.email}
          </Typography>
        </Box>
      ),
      variant: MenuListItemVariant.NoAction,
      actionComponent: (
        <IconButton
          onClick={() => toggleContactEnabled(contact.id)}
          disabled={liveTrackUpdateInProgress}
        >
          <BlueSwitch checked={contact.enabled} disabled={liveTrackUpdateInProgress} />
        </IconButton>
      ),
    }));
  }, [
    handleConfirmDelete,
    liveTrackContacts,
    liveTrackUpdateInProgress,
    palette.action.disabled,
    palette.error.main,
    toggleContactEnabled,
  ]);

  return (
    <>
      <PullToRefresh onRefresh={reloadLiveTrackContacts} />
      <GlobalHeader
        title={t`Live Track auto-share`}
        headerAction={
          <Button onClick={handleAddContact} disabled={liveTrackUpdateInProgress}>
            <Trans>Add</Trans>
          </Button>
        }
      />
      <Box p={2} display="flex" flexDirection="column" gap={2}>
        <Typography color={palette.text.primary} variant="prose-sm" align="center">
          <Trans>
            When enabled, your Live Track URL are automatically shared with selected contacts as
            soon as you start recording.
          </Trans>
        </Typography>
        {contactsList.length === 0 && (
          <Box m={3} display="flex" flexDirection="column" gap={2} alignItems="center">
            <Typography color={palette.text.primary} variant="prose-sm" align="center">
              <Trans>No recipient added</Trans>
            </Typography>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleAddContact}
              disabled={liveTrackUpdateInProgress}
            >
              <Trans>Add recipient</Trans>
            </Button>
          </Box>
        )}
        {contactsList.length > 0 && <MenuList disableGutters listItems={contactsList} />}
      </Box>
      <AddLiveTrackContact open={addContactOpen} handleClose={handleCloseAddContact} />
    </>
  );
};

export { LiveTrackContacts };
