import { SettingsRemoteConfigKey } from "@/services/SettingsDefaultRemoteConfig";
import { useAnalyticsContext } from "@WahooFitness/wahoo-offline-mfe";
import { useCallback } from "react";

// We wrap the context remote config getters so we can have type safe
// remote config keys that have local defaults.
export const useSettingsRemoteConfig = () => {
  const { getRemoteConfigString, getRemoteConfigObject, getRemoteConfigBoolean } =
    useAnalyticsContext();

  const getSettingsRemoteConfigString = useCallback(
    (key: SettingsRemoteConfigKey) => {
      return getRemoteConfigString(key);
    },
    [getRemoteConfigString]
  );

  const getSettingsRemoteConfigObject = useCallback(
    <T = any>(key: SettingsRemoteConfigKey) => {
      return getRemoteConfigObject<T>(key);
    },
    [getRemoteConfigObject]
  );

  const getSettingsRemoteConfigBoolean = useCallback(
    (key: SettingsRemoteConfigKey) => {
      return !!getRemoteConfigBoolean(key);
    },
    [getRemoteConfigBoolean]
  );

  return {
    getSettingsRemoteConfigString,
    getSettingsRemoteConfigObject,
    getSettingsRemoteConfigBoolean,
  };
};
