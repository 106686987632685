import { t } from "@lingui/macro";
import StrengthArrow from "@/assets/StrengthArrow.png";
import WeaknessArrow from "@/assets/WeaknessArrow.png";
import { FitnessProfiles, UserProfiles } from "@WahooFitness/cloud-client-ts";
import { useCallback, useEffect, useMemo } from "react";
import {
  useConfigContext,
  useOfflineSWR,
  useCloudContext,
  useNativeMessagingContext,
  useDrawerContext,
} from "@WahooFitness/wahoo-offline-mfe";
import { useNavigate } from "react-router";
import { useLogEvent } from "@/services/Analytics/useLogEvent";
import useLocalizedFitnessData from "./useLocalizedFitnessData";
import { FitnessProfileAlgorithm, WahooWorkoutTypeFamily } from "@WahooFitness/cloud-client-types";
import HowWeCalculateDrawer from "./HowWeCalculateDrawer";
import { ClickTarget, EventType, SourcePage } from "@/services/Analytics/EventTypes";
import useDismiss from "@/hooks/useDismiss";
import useRiderType from "../RiderType/useRiderType";
import EstimateDrawer from "./EstimateDrawer";
import { useSettingsVersionedFeatures } from "@/hooks/useSettingsVersionedFeatures";

const useFitnessProfile = () => {
  const { wahooToken } = useConfigContext();
  const { getCloudClient } = useCloudContext();
  const { addRefreshListener, removeRefreshListener } = useNativeMessagingContext();
  const { logEvent } = useLogEvent();

  const { setDrawer } = useDrawerContext();
  const navigate = useNavigate();

  const { indoorRiderTypeId: systmRiderTypeId } = useRiderType();
  const { getRiderTypeById, getRiderTypeStrengthById, getRiderTypeWeaknessById } =
    useLocalizedFitnessData();
  const fitnessProfilesClient = useMemo(() => getCloudClient(FitnessProfiles), [getCloudClient]);

  const { data: userDismissedRiderTypeAlert, dismiss: dismissRiderTypeAlert } = useDismiss(
    "userDismissedRiderTypeAlert"
  );

  const {
    data: userFitnessProfilesData,
    isLoading: userFitnessProfilesIsLoading,
    error: userFitnessProfilesError,
    mutate: mutateFitnessProfile,
  } = useOfflineSWR(["userFitnessProfiles", wahooToken], ([_key, token]) =>
    fitnessProfilesClient.get(token)
  );

  useEffect(() => {
    const refreshListener = addRefreshListener(mutateFitnessProfile);
    return () => removeRefreshListener(refreshListener);
  }, [addRefreshListener, mutateFitnessProfile, removeRefreshListener]);

  const logFitnessProfileEvent = useCallback(
    (clickTarget: ClickTarget, eventType = EventType.click) => {
      logEvent("athlete_profile", {
        event_type: eventType,
        click_target: clickTarget,
        source_page_name: SourcePage.fitness_profile,
      });
    },
    [logEvent]
  );

  const handleDismissRiderTypeAlert = useCallback(() => {
    logFitnessProfileEvent(ClickTarget.rider_type_mismatch_alert, EventType.dismiss);
    dismissRiderTypeAlert();
  }, [dismissRiderTypeAlert, logFitnessProfileEvent]);

  const userProfilesClient = useMemo(() => getCloudClient(UserProfiles), [getCloudClient]);
  const { data: userProfilesData } = useOfflineSWR(
    ["trainingPreferences", wahooToken],
    ([_key, token]) => userProfilesClient.get(token)
  );

  const hasCyclingTrainingPreference = useMemo(
    () => userProfilesData?.workout_type_family_ids.includes(WahooWorkoutTypeFamily.Cycling),
    [userProfilesData?.workout_type_family_ids]
  );

  const cyclingFitnessProfilesData = useMemo(
    () =>
      userFitnessProfilesData?.find(
        (profile) => profile.workout_type_family_id === WahooWorkoutTypeFamily.Cycling
      ),
    [userFitnessProfilesData]
  );

  const riderTypeData = useMemo(
    () => getRiderTypeById(cyclingFitnessProfilesData?.fitness_type_id),
    [cyclingFitnessProfilesData?.fitness_type_id, getRiderTypeById]
  );

  const riderTypeStrength = useMemo(
    () => getRiderTypeStrengthById(cyclingFitnessProfilesData?.fitness_strength_id),
    [cyclingFitnessProfilesData?.fitness_strength_id, getRiderTypeStrengthById]
  );
  const riderTypeWeakness = useMemo(
    () => getRiderTypeWeaknessById(cyclingFitnessProfilesData?.fitness_weakness_id),
    [cyclingFitnessProfilesData?.fitness_weakness_id, getRiderTypeWeaknessById]
  );
  const riderStrength = useMemo(() => {
    if (riderTypeStrength) {
      return {
        name: riderTypeStrength?.name,
        description: riderTypeStrength?.description,
        icon: StrengthArrow,
      };
    }
  }, [riderTypeStrength]);
  const riderWeakness = useMemo(() => {
    if (riderTypeWeakness) {
      return {
        name: riderTypeWeakness?.name,
        description: riderTypeWeakness?.description,
        icon: WeaknessArrow,
      };
    }
  }, [riderTypeWeakness]);

  const riderTypeIcon = useMemo(() => riderTypeData?.icon, [riderTypeData?.icon]);
  const riderTypeIconFilled = useMemo(() => riderTypeData?.iconFilled, [riderTypeData?.iconFilled]);
  const riderTypeName = useMemo(() => riderTypeData?.name, [riderTypeData?.name]);
  const hasNoCyclingFitnessProfile = useMemo(
    () => !cyclingFitnessProfilesData?.fitness_type_id,
    [cyclingFitnessProfilesData?.fitness_type_id]
  );

  const profileDescription = useMemo(() => {
    if (riderTypeData?.description) return riderTypeData.description;
    if (hasNoCyclingFitnessProfile) {
      return t`Your Athlete Profile gives you a complete view of your strengths and weaknesses, 
      revealing what you are capable of now and giving you insights into how you’ll perform in 
      different conditions.`;
    }
    return "";
  }, [hasNoCyclingFitnessProfile, riderTypeData?.description]);

  const handleQuestionnaireClick = useCallback(() => {
    logFitnessProfileEvent(ClickTarget.start_quiz);
    hasNoCyclingFitnessProfile ? navigate("/fourdp") : navigate("/fourdp?skipEducation");
  }, [hasNoCyclingFitnessProfile, logFitnessProfileEvent, navigate]);

  const { enabled: showQuestionnaireCard } = useSettingsVersionedFeatures(
    "FOURDP_QUIZ_MIN_PROFILE_VERSION"
  );

  const { enabled: show4DPEducation } = useSettingsVersionedFeatures(
    "FOURDP_EDUCATION_MIN_PROFILE_MIN_VERSION"
  );

  const openInfoDrawer = useCallback(() => {
    logFitnessProfileEvent(ClickTarget.how_we_calculate);
    setDrawer({
      open: true,
      title: t`How we calculate?`,
      hidePuller: true,
      children: <HowWeCalculateDrawer />,
      elevation: 4,
    });
  }, [setDrawer, logFitnessProfileEvent]);

  const isRiderTypeEstimate = useMemo(
    () =>
      cyclingFitnessProfilesData?.fitness_profile_algorithm_id &&
      [FitnessProfileAlgorithm.FourDPQuiz, FitnessProfileAlgorithm.HalfMontyAssessment].includes(
        cyclingFitnessProfilesData.fitness_profile_algorithm_id
      ),
    [cyclingFitnessProfilesData?.fitness_profile_algorithm_id]
  );

  const openEstimateExplanationDrawer = useCallback(() => {
    logFitnessProfileEvent(ClickTarget.estimate_chip);
    setDrawer({
      open: true,
      hidePuller: true,
      title: t`Why is this an estimate?`,
      children: (
        <EstimateDrawer algorithmId={cyclingFitnessProfilesData?.fitness_profile_algorithm_id} />
      ),
    });
  }, [cyclingFitnessProfilesData?.fitness_profile_algorithm_id, logFitnessProfileEvent, setDrawer]);

  const hasRiderTypeMismatch = useMemo(
    // right now the IDs match 1:1 this shouldn't change, but it could.
    () => cyclingFitnessProfilesData?.fitness_type_id !== systmRiderTypeId,
    [cyclingFitnessProfilesData?.fitness_type_id, systmRiderTypeId]
  );

  return {
    userFitnessProfilesIsLoading,
    userFitnessProfilesError,
    mutateFitnessProfile,
    cyclingFitnessProfilesData,
    riderTypeName,
    riderTypeIcon,
    riderTypeIconFilled,
    hasNoCyclingFitnessProfile,
    profileDescription,
    userFitnessProfilesData,
    handleQuestionnaireClick,
    showQuestionnaireCard,
    riderStrength,
    riderWeakness,
    hasCyclingTrainingPreference,
    openInfoDrawer,
    isRiderTypeEstimate,
    userDismissedRiderTypeAlert,
    handleDismissRiderTypeAlert,
    hasRiderTypeMismatch,
    openEstimateExplanationDrawer,
    show4DPEducation,
  };
};

export default useFitnessProfile;
