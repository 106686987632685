import { useState } from "react";
import FourDPQuizContainer from "@/components/4dpQuiz/FourDPQuizContainer";
import { FourDPQuizResultsType } from "@WahooFitness/cloud-client-types";
import FourDPQuizResults from "@/components/4dpQuiz/Results/FourDPQuizResults";
import FourDpPages from "@/components/4dpQuiz/FourDPPages";
import PowerZoneUpdate from "@/components/4dpQuiz/PowerZoneUpdate/PowerZoneUpdate";
import FourDPEducation from "@/components/4dpEducation/FourDPEducation";
import { useSearchParams } from "react-router-dom";
import { useSettingsVersionedFeatures } from "@/hooks/useSettingsVersionedFeatures";

function FourDPIndexPage() {
  const [searchParams] = useSearchParams();
  const { enabled: show4DPEducation } = useSettingsVersionedFeatures(
    "FOURDP_EDUCATION_MIN_PROFILE_MIN_VERSION"
  );
  const skipEducation = searchParams.has("skipEducation") || !show4DPEducation;
  const [page, setPage] = useState<FourDpPages>(
    skipEducation ? FourDpPages.quiz : FourDpPages.education
  );
  const [results, setResults] = useState<FourDPQuizResultsType>();
  const [finishedEducation, setFinishedEducation] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  switch (page) {
    case FourDpPages.quiz:
      return (
        <FourDPQuizContainer
          setResults={setResults}
          setError={setError}
          setPage={setPage}
          skipEducation={skipEducation}
        />
      );
    case FourDpPages.results:
      return <FourDPQuizResults results={results} setPage={setPage} error={error} />;
    case FourDpPages.zones:
      return <PowerZoneUpdate results={results} />;
    case FourDpPages.education:
    default:
      return (
        <FourDPEducation
          isStandAlone={false}
          setPage={setPage}
          finishedEducation={finishedEducation}
          setFinishedEducation={setFinishedEducation}
        />
      );
  }
}

export default FourDPIndexPage;
