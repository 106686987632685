import { Box, List, ListItem, PaletteColor, Typography, useTheme } from "@mui/material";
import { useCallback } from "react";
import { t } from "@lingui/macro";
import { Zone } from "@/components/Zones/useZones";
import { DotMark } from "@carbon/icons-react";

type Props = {
  zones: Zone[];
};

const ZoneViewComponent = ({ zones }: Props) => {
  const { palette } = useTheme();
  const getZoneText = useCallback((zone: Zone, boundary: "top" | "bottom") => {
    if (boundary === "top" && zone.top === "max") {
      return t`max`;
    }
    const value = boundary === "top" ? (zone.top as number) : zone.bottom;
    return value;
  }, []);

  return (
    <List sx={{ width: "100%" }} disablePadding>
      {zones.map((zone, index) => {
        const zoneColor = (
          palette[`${zones.length}zonesZone${index + 1}` as keyof typeof palette] as PaletteColor
        ).main;
        return (
          <ListItem key={index} disableGutters>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
              gap={1}
            >
              <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                <DotMark size={24} color={zoneColor} />
                <Typography variant="prose-base" noWrap>
                  {zone.name}
                </Typography>
              </Box>
              <Box display="flex" gap={0.5} alignItems="center">
                <Typography
                  variant="prose-base"
                  textAlign="right"
                  data-testid={`zoneMin${index + 1}`}
                >
                  {getZoneText(zone, "bottom")} - {getZoneText(zone, "top")}
                </Typography>
              </Box>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ZoneViewComponent;
