import { ZoneSet } from "@/components/Zones/useZones";
import { t } from "@lingui/macro";
import { FormControl, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import {
  RedesignrRadioGroup,
  RedesignrFormControlLabel,
  RedesignrRadio,
  RedesignrFormControlLabelContent,
  InfoIcon,
} from "@WahooFitness/redesignr";

interface SelectComponentProps {
  selectedZone: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: Array<{ key: string; text: { title: string } }>;
  hasMultipleZoneSets: boolean;
  savedZoneSets: Record<number, ZoneSet>;
  handleZoneInfoClick: (zoneSet: any) => void;
  zoneKeys: number[];
}

const SelectComponent = ({
  selectedZone,
  onChange,
  options,
  hasMultipleZoneSets,
  savedZoneSets,
  handleZoneInfoClick,
  zoneKeys,
}: SelectComponentProps) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" gap={2}>
      <FormControl sx={{ width: "100%" }}>
        <RedesignrRadioGroup
          name="single-select-radio-button-group"
          onChange={onChange}
          value={selectedZone}
        >
          {hasMultipleZoneSets ? (
            options.map((option) => (
              <Box key={option.key} display="flex" flexDirection="row" alignItems="center">
                <RedesignrFormControlLabel
                  sx={{ width: "100%" }}
                  key={option.key}
                  value={option.key}
                  control={<RedesignrRadio />}
                  label={
                    <RedesignrFormControlLabelContent align="left" label={option.text.title} />
                  }
                />
                {option.key !== "none" ? (
                  <IconButton
                    onClick={() => handleZoneInfoClick(savedZoneSets[Number(option.key)])}
                  >
                    <InfoIcon />
                  </IconButton>
                ) : (
                  option.key === "none" && <Box width="48px" />
                )}
              </Box>
            ))
          ) : (
            <>
              <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                <RedesignrFormControlLabel
                  sx={{ width: "100%" }}
                  key={zoneKeys[0]}
                  value={zoneKeys[0]}
                  control={<RedesignrRadio />}
                  label={<RedesignrFormControlLabelContent align="center" label={t`Yes`} />}
                />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                <RedesignrFormControlLabel
                  sx={{ width: "100%" }}
                  key={"no"}
                  value="none"
                  control={<RedesignrRadio />}
                  label={<RedesignrFormControlLabelContent align="center" label={t`No`} />}
                />
              </Box>
            </>
          )}
        </RedesignrRadioGroup>
      </FormControl>
    </Box>
  );
};

export default SelectComponent;
