import React, { useCallback, useState } from "react";
import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

const GlobalDialog: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const {
    dialog: { open, dialogComponent, title = "", body = "", actions, dialogProps = {} },
    handleClose,
  } = useDialogContext();

  const handleAction = useCallback(async (action?: () => void) => {
    if (action) {
      setIsDisabled(true);
      await action();
      setIsDisabled(false);
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth {...dialogProps}>
      {dialogComponent ? (
        dialogComponent
      ) : (
        <Box display="flex" flexDirection="column" p={2} alignItems={"center"} gap={2}>
          <Box display="flex" flexDirection="column" alignItems={"center"} gap={1}>
            <DialogTitle sx={{ padding: 0 }}>
              <Typography variant="prose-md-medium">{title}</Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: 0, textAlign: "center" }}>
              {typeof body === "string" ? (
                <DialogContentText>
                  <Typography variant="prose-base">{body}</Typography>
                </DialogContentText>
              ) : (
                body
              )}
            </DialogContent>
          </Box>
          <DialogActions sx={{ width: "100%", padding: 0 }}>
            <Box display="flex" width="100%" gap={1}>
              {actions?.map((action) => (
                <Button
                  key={action.text}
                  variant="outlined"
                  size="large"
                  color={action.color || "primary"}
                  onClick={action.action ? () => handleAction(action.action) : handleClose}
                  disabled={isDisabled}
                  fullWidth
                >
                  {action.text}
                </Button>
              ))}
            </Box>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};

export default GlobalDialog;
