import { useWsmPlugin } from "@/hooks/useWsmPlugin";
import { plural, t } from "@lingui/macro";
import { useTheme } from "@mui/material";
import {
  LiveTrackContacts,
  LiveTrackLinks,
  UserShareSettings,
} from "@WahooFitness/cloud-client-ts";
import { UserShareSettingsType } from "@WahooFitness/cloud-client-types";
import {
  postEvent,
  useCloudContext,
  useConfigContext,
  useOfflineSWR,
  useUserContext,
  useGuardedUpdater,
  useNativeMessagingContext,
} from "@WahooFitness/wahoo-offline-mfe";
import WSMSensorConnectionState from "@WahooFitness/wsm-native/dist/esm/types/connection_state";
import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import EmailValidator from "email-validator";

export type NativeContact = { name: string; email: string };

const useLiveTrack = () => {
  const { addEventResponseListener, removeEventResponseListener } = useNativeMessagingContext();
  const { palette } = useTheme();
  const { getCloudClient } = useCloudContext();
  const { wahooToken } = useConfigContext();
  const { currentUser, mutateUser } = useUserContext();
  const { update, updateInProgress } = useGuardedUpdater();

  const [nativeContacts, setNativeContacts] = useState<NativeContact[]>([]);

  const handleGetNativeContacts = useCallback(() => {
    postEvent("getNativeContacts", {});
  }, []);

  useEffect(() => {
    const listener = addEventResponseListener("getNativeContacts", async (eventResponse) => {
      if (!eventResponse.eventResponseData) {
        return;
      }
      setNativeContacts(
        eventResponse.eventResponseData.filter((contact: NativeContact) =>
          EmailValidator.validate(contact.email)
        )
      );
    });
    return () => {
      removeEventResponseListener(listener);
    };
  }, [addEventResponseListener, removeEventResponseListener]);

  const clearNativeContacts = useCallback(() => {
    setNativeContacts([]);
  }, []);

  const askForLiveTrackPermission = useCallback(() => {
    postEvent("requestPermission", { permissionType: "batteryUnrestricted" });
  }, []);

  const liveTrackLinksClient = getCloudClient(LiveTrackLinks);
  const {
    data: liveTrackLinks,
    isLoading: liveTrackLinksIsLoading,
    mutate: reloadLiveTrackLinks,
  } = useOfflineSWR(["live-track-links", wahooToken], () => {
    return liveTrackLinksClient.get(wahooToken);
  });

  const addExpiringLink = useCallback(
    () =>
      update(
        () => liveTrackLinksClient.create(wahooToken, new Date(Date.now() + 12 * 60 * 60 * 1000)),
        {
          afterUpdate: () => reloadLiveTrackLinks(),
          errorMessage: t`There was a problem creating your Live Track link. Check your network and try again.`,
        }
      ),
    [liveTrackLinksClient, reloadLiveTrackLinks, update, wahooToken]
  );

  const deleteLink = useCallback(
    (linkId: number) => {
      if (!liveTrackLinks || !liveTrackLinks.some((link) => link.id === linkId)) {
        return;
      }
      return update(() => liveTrackLinksClient.delete(wahooToken, linkId), {
        errorMessage: t`There was a problem deleting your Live Track link. Check your network and try again.`,
      });
    },
    [liveTrackLinks, liveTrackLinksClient, update, wahooToken]
  );

  const liveTrackContactsClient = getCloudClient(LiveTrackContacts);
  const {
    data: liveTrackContacts,
    isLoading: liveTrackContactsIsLoading,
    mutate: reloadLiveTrackContacts,
  } = useOfflineSWR(["live-track-contacts", wahooToken], async () => {
    const contacts = await liveTrackContactsClient.get(wahooToken);
    return contacts.sort((a, b) => a.email.localeCompare(b.email));
  });

  const toggleContactEnabled = useCallback(
    async (contactId: number) => {
      const contact = liveTrackContacts?.find((contact) => contact.id === contactId);
      if (!contact) {
        return;
      }
      return update(
        () =>
          liveTrackContactsClient.update(wahooToken, contactId, {
            email: contact.email,
            enabled: !contact.enabled,
          }),
        {
          afterUpdate: () => reloadLiveTrackContacts(),
          errorMessage: t`There was a problem updating your auto-share contact. Check your network and try again.`,
        }
      );
    },
    [liveTrackContacts, liveTrackContactsClient, reloadLiveTrackContacts, update, wahooToken]
  );

  const addContacts = useCallback(
    async (emails: string[]) => {
      return await update(
        () => {
          return Promise.all(
            emails.map(async (email) => {
              return liveTrackContactsClient.create(wahooToken, {
                email: email,
              });
            })
          );
        },
        {
          afterUpdate: () => reloadLiveTrackContacts(),
          errorMessage: t`There was a problem adding your auto-share contact. Check your network and try again.`,
        }
      );
    },
    [liveTrackContactsClient, reloadLiveTrackContacts, update, wahooToken]
  );

  const deleteContact = useCallback(
    async (contactId: number) => {
      return await update(() => liveTrackContactsClient.delete(wahooToken, contactId), {
        afterUpdate: () => reloadLiveTrackContacts(),
        errorMessage: t`There was a problem removing your auto-share contact. Check your network and try again.`,
      });
    },
    [liveTrackContactsClient, reloadLiveTrackContacts, update, wahooToken]
  );

  const { sensorManagerPlugin } = useWsmPlugin();
  const { data: liveTrackStatus, isLoading: liveTrackStatusIsLoading } = useSWR(
    sensorManagerPlugin && ["live_track_status"],
    () => sensorManagerPlugin?.getLiveTrackStatus(),
    { refreshInterval: 10000 }
  );

  const liveTrackStatusDisplay = useMemo((): { message: string; color?: string } => {
    if (liveTrackStatusIsLoading) {
      return { message: t`Checking status...` };
    }
    if (
      !currentUser?.share_setting?.livetrack_enabled ||
      !liveTrackStatus?.isEnabled ||
      !liveTrackStatus?.isFeatureEnabled
    ) {
      return { message: t`Disabled`, color: palette.error.main };
    } else if (!liveTrackStatus?.isPermitted) {
      return { message: t`Not permitted`, color: palette.error.main };
    } else if (liveTrackStatus?.tracking) {
      if (liveTrackStatus?.tracking.isCommsDown) {
        return { message: t`No network connection`, color: palette.error.main };
      }
      if (
        !liveTrackStatus?.tracking.isLocal &&
        liveTrackStatus?.tracking?.elemnt?.sensorConnectionState !==
          WSMSensorConnectionState.CONNECTED
      ) {
        return { message: t`ELEMNT disconnected`, color: palette.error.main };
      }
      if (liveTrackStatus?.tracking.secSinceLastSendOk < 60) {
        return { message: t`Updated less than a minute ago`, color: palette.info.main };
      }
      const minutes = Math.floor(liveTrackStatus?.tracking.secSinceLastSendOk / 60);
      return {
        message: plural(minutes, {
          one: "Updated # minute ago",
          other: "Updated # minutes ago",
        }),
        color: palette.info.main,
      };
    } else {
      return { message: t`Ready` };
    }
  }, [
    liveTrackStatusIsLoading,
    currentUser?.share_setting?.livetrack_enabled,
    liveTrackStatus?.isEnabled,
    liveTrackStatus?.isFeatureEnabled,
    liveTrackStatus?.isPermitted,
    liveTrackStatus?.tracking,
    palette.error.main,
    palette.info.main,
  ]);

  const shareSettingsClient = getCloudClient(UserShareSettings);
  const updateShareSettings = useCallback(
    async (shareSettings: UserShareSettingsType) => {
      if (!currentUser) {
        return;
      }
      return update(() => shareSettingsClient.update(wahooToken, currentUser.id, shareSettings), {
        afterUpdate: async (result) => {
          mutateUser({ ...currentUser, share_setting: result });
        },
        errorMessage: t`There was a problem updating your Live Track settings. Check your network and try again.`,
      });
    },
    [currentUser, mutateUser, shareSettingsClient, update, wahooToken]
  );

  const toggleLiveTrackEnabled = useCallback(async () => {
    const newEnabled = !currentUser?.share_setting.livetrack_enabled;
    const result = await updateShareSettings({
      livetrack_enabled: !currentUser?.share_setting.livetrack_enabled,
    });
    if (!result) {
      return;
    }
    if (!newEnabled && liveTrackLinks?.length) {
      await Promise.all(liveTrackLinks.map(async (link) => deleteLink(link.id)));
      reloadLiveTrackLinks();
    }
  }, [
    currentUser?.share_setting.livetrack_enabled,
    deleteLink,
    liveTrackLinks,
    reloadLiveTrackLinks,
    updateShareSettings,
  ]);

  const toggleOptOutEnabled = useCallback(() => {
    return updateShareSettings({
      livetrack_opt_out: !currentUser?.share_setting.livetrack_opt_out,
    });
  }, [currentUser?.share_setting.livetrack_opt_out, updateShareSettings]);

  const handleShareLink = useCallback(async () => {
    const createLinkResult = await addExpiringLink();
    if (createLinkResult) {
      postEvent("shareLiveTrackLink", { liveTrackLink: createLinkResult.url });
    }
  }, [addExpiringLink]);

  const liveTrackUpdateInProgress = useMemo(() => {
    return updateInProgress || liveTrackContactsIsLoading || liveTrackLinksIsLoading;
  }, [liveTrackContactsIsLoading, liveTrackLinksIsLoading, updateInProgress]);

  return {
    liveTrackLinks,
    reloadLiveTrackLinks,
    liveTrackContacts,
    reloadLiveTrackContacts,
    toggleLiveTrackEnabled,
    toggleOptOutEnabled,
    toggleContactEnabled,
    addContacts,
    deleteContact,
    handleShareLink,
    liveTrackStatus,
    liveTrackUpdateInProgress,
    liveTrackStatusDisplay,
    askForLiveTrackPermission,
    nativeContacts,
    handleGetNativeContacts,
    clearNativeContacts,
  };
};

export { useLiveTrack };
