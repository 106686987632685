import { Plural, t, Trans } from "@lingui/macro";
import {
  BlueSwitch,
  ChevronRightIcon,
  LiveTrackIcon,
  MenuList,
  MenuListItemType,
  MenuListItemVariant,
  FancyIcon,
} from "@WahooFitness/redesignr";
import { useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import { useMemo } from "react";
import { useLiveTrack } from "./useLiveTrack";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { GlobalHeader } from "../GlobalHeader";

const LiveTrack = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { currentUser } = useUserContext();
  const {
    liveTrackContacts,
    toggleLiveTrackEnabled,
    toggleOptOutEnabled,
    handleShareLink,
    liveTrackStatus,
    liveTrackStatusDisplay,
    liveTrackUpdateInProgress,
    askForLiveTrackPermission,
  } = useLiveTrack();

  const liveTrackHeader = useMemo(
    () => (
      <Box width="100%" px={2} display="flex" flexDirection="column" alignItems="center">
        <FancyIcon icon={<LiveTrackIcon />} size="medium" color="info" />
        <Typography
          color={palette.text.primary}
          variant="prose-base-bold"
          mt={2}
          px={2}
          textAlign="center"
        >
          <Trans>Live Track</Trans>
        </Typography>
        <Typography color={palette.text.primary} variant="prose-sm" mt={2} textAlign="center">
          <Trans>
            With Live Track, your friends and family can follow your real-time location while you
            record activities on your ELEMNT bike computer.
          </Trans>
        </Typography>
        <Typography color={palette.text.primary} variant="prose-sm" mt={2} textAlign="center">
          <Trans>
            Important: your phone must be turned on with Bluetooth enabled and in range of your
            device to maintain a connection.
          </Trans>
        </Typography>
      </Box>
    ),
    [palette.text.primary]
  );

  const liveTrackConfigItems = useMemo<MenuListItemType[]>(
    () => [
      {
        id: "live-track-header",
        content: "",
        secondaryContent: liveTrackHeader,
        variant: MenuListItemVariant.NoAction,
      },
      {
        id: "live-track-enabled",
        content: t`Live Track`,
        variant: MenuListItemVariant.Action,
        disabled: liveTrackUpdateInProgress,
        secondaryContent: !liveTrackStatus?.isPermitted ? (
          <Typography
            variant="prose-sm"
            color={liveTrackStatusDisplay.color ?? palette.text.secondary}
          >
            {liveTrackStatusDisplay.message}
          </Typography>
        ) : undefined,
        actionComponent: liveTrackStatus?.isPermitted ? (
          <BlueSwitch
            disabled={liveTrackUpdateInProgress}
            checked={currentUser?.share_setting.livetrack_enabled}
            data-testid="live_track_enabled"
          />
        ) : (
          <Box p={1}>
            <Button variant="outlined" onClick={askForLiveTrackPermission}>
              <Trans>Request permission</Trans>
            </Button>
          </Box>
        ),
        action: liveTrackStatus?.isPermitted ? toggleLiveTrackEnabled : undefined,
      },
      {
        id: "live-track-contacts",
        content: t`Auto-share`,
        variant: MenuListItemVariant.Action,
        disabled: liveTrackUpdateInProgress,
        actionComponent: (
          <>
            <Plural value={liveTrackContacts?.length ?? 0} one="# contact" other="# contacts" />{" "}
            <ChevronRightIcon sx={{ marginLeft: 1 }} />
          </>
        ),
        action: () => navigate("/live-track/contacts"),
      },
      {
        id: "live-track-links",
        content: t`Public link`,
        secondaryContent: (
          <Typography
            variant="prose-sm"
            color={liveTrackStatusDisplay.color ?? palette.text.secondary}
          >
            {liveTrackStatusDisplay.message}
          </Typography>
        ),
        variant: MenuListItemVariant.Action,
        disabled: liveTrackUpdateInProgress,
        actionComponent: (
          <Box p={1}>
            <Button
              variant="outlined"
              onClick={handleShareLink}
              disabled={
                !liveTrackStatus?.isEnabled ||
                !liveTrackStatus?.isFeatureEnabled ||
                !liveTrackStatus?.isPermitted ||
                liveTrackUpdateInProgress
              }
            >
              <Trans>Share link</Trans>
            </Button>
          </Box>
        ),
        hidden: !currentUser?.share_setting.livetrack_enabled || !liveTrackStatus?.isPermitted,
      },
    ],
    [
      askForLiveTrackPermission,
      currentUser?.share_setting.livetrack_enabled,
      handleShareLink,
      liveTrackContacts?.length,
      liveTrackHeader,
      liveTrackStatus?.isEnabled,
      liveTrackStatus?.isFeatureEnabled,
      liveTrackStatus?.isPermitted,
      liveTrackStatusDisplay.color,
      liveTrackStatusDisplay.message,
      liveTrackUpdateInProgress,
      navigate,
      palette.text.secondary,
      toggleLiveTrackEnabled,
    ]
  );

  const nearbyConfigItems = useMemo<MenuListItemType[]>(
    () => [
      {
        id: "live-track-opt-out",
        content: t`Make me visible to everyone`,
        secondaryContent: t`When enabled, your first name, last name initial and location will be shown to other ELEMNT riders.`,
        variant: MenuListItemVariant.Action,
        disabled: liveTrackUpdateInProgress,
        actionComponent: (
          <BlueSwitch
            disabled={liveTrackUpdateInProgress}
            checked={!currentUser?.share_setting.livetrack_opt_out}
            data-testid="live_track_enabled"
          />
        ),
        action: toggleOptOutEnabled,
        hidden: !currentUser?.share_setting.livetrack_enabled || !liveTrackStatus?.isPermitted,
      },
    ],
    [
      currentUser?.share_setting.livetrack_enabled,
      currentUser?.share_setting.livetrack_opt_out,
      liveTrackStatus?.isPermitted,
      liveTrackUpdateInProgress,
      toggleOptOutEnabled,
    ]
  );

  return (
    <>
      <GlobalHeader title={t`Live Track`} />
      <Box p={2} display="flex" flexDirection="column" gap={2}>
        <Box>
          <MenuList disableGutters listItems={liveTrackConfigItems} />
          <Box mx={2}>
            <Typography variant="prose-sm">
              <Trans>Shared links will automatically expire after 12 hours of inactivity.</Trans>
            </Typography>
          </Box>
        </Box>
        <MenuList disableGutters listItems={nearbyConfigItems} />
      </Box>
    </>
  );
};

export { LiveTrack };
