import { useCallback, useMemo } from "react";
import {
  useAnalyticsContext,
  useUserContext,
  hasMinProfile,
} from "@WahooFitness/wahoo-offline-mfe";
import { Profile } from "@WahooFitness/cloud-client-types";
import { FlaggedFeature } from "./types/FlaggedFeature";
import { useSettingsRemoteConfig } from "./useSettingsRemoteConfig";
import { SettingsRemoteConfigKey } from "@/services/SettingsDefaultRemoteConfig";

const useFlaggedFeatures = () => {
  const { currentUser, userIsLoading } = useUserContext();
  const { remoteConfigIsLoading } = useAnalyticsContext();
  const {
    getSettingsRemoteConfigString,
    getSettingsRemoteConfigObject,
    getSettingsRemoteConfigBoolean,
  } = useSettingsRemoteConfig();

  const checkMinProfileFeature = useCallback(
    (remoteConfigKey: SettingsRemoteConfigKey) =>
      hasMinProfile(
        getSettingsRemoteConfigString(remoteConfigKey) as Profile,
        currentUser?.app?.profile
      ),
    [currentUser?.app?.profile, getSettingsRemoteConfigString]
  );

  const featureCheckers = useMemo<Record<FlaggedFeature, () => boolean>>(() => {
    return {
      [FlaggedFeature.ElemntConfig]: () => checkMinProfileFeature("ELEMNT_CONFIG_MIN_PROFILE"),
      [FlaggedFeature.AutoShareSummary]: () =>
        checkMinProfileFeature("SUMMARY_AUTO_SHARE_MIN_PROFILE"),
      [FlaggedFeature.PaceZones]: () => checkMinProfileFeature("PACEZONES_MIN_PROFILE"),
      [FlaggedFeature.ElemntMultisportPage]: () =>
        checkMinProfileFeature("ELEMNT_MULTISPORT_PAGE_MIN_PROFILE"),
      [FlaggedFeature.ElemntPedalsPage]: () =>
        checkMinProfileFeature("ELEMNT_PEDAL_PAGE_MIN_PROFILE"),
      [FlaggedFeature.ElemntPlannedWorkouts]: () =>
        checkMinProfileFeature("ELEMNT_PLANNED_WORKOUTS_MIN_PROFILE"),
      [FlaggedFeature.StravaLiveSegments]: () =>
        checkMinProfileFeature("STRAVA_LIVE_SEGMENTS_MIN_PROFILE"),
      [FlaggedFeature.ElemntAlertCountWarning]: () =>
        getSettingsRemoteConfigBoolean("ELEMNT_ALERT_COUNT_WARNING_ENABLED"),
      [FlaggedFeature.RouteToPlayr]: () => checkMinProfileFeature("ROUTE_TO_PLAYR"),
      [FlaggedFeature.CuratedRoutes]: () => checkMinProfileFeature("CURATED_ROUTES_MIN_PROFILE"),
      [FlaggedFeature.UpdatedPaywall]: () => checkMinProfileFeature("UPDATED_PAYWALL_MIN_PROFILE"),
      [FlaggedFeature.LiveTrackConfig]: () =>
        checkMinProfileFeature("LIVE_TRACK_CONFIG_MIN_PROFILE"),
      [FlaggedFeature.ShowSuggestedWorkouts]: () =>
        checkMinProfileFeature("SUGGESTED_WORKOUTS_MIN_PROFILE"),
      [FlaggedFeature.WorkoutAlerts]: () => checkMinProfileFeature("WORKOUT_ALERTS_MIN_PROFILE"),
    };
  }, [checkMinProfileFeature, getSettingsRemoteConfigBoolean]);

  const curatedWorkoutsList = useMemo(
    () => getSettingsRemoteConfigObject<number[]>("CURATED_WORKOUTS_LIST"),
    [getSettingsRemoteConfigObject]
  );

  const checkIsFeatureEnabled = useCallback(
    (feature: FlaggedFeature) => featureCheckers[feature](),
    [featureCheckers]
  );

  const featuresAreLoading = useMemo(
    () => remoteConfigIsLoading || userIsLoading,
    [remoteConfigIsLoading, userIsLoading]
  );

  const fitnessTestId = useMemo(
    () => getSettingsRemoteConfigString("FITNESS_TEST_ID"),
    [getSettingsRemoteConfigString]
  );

  return {
    curatedWorkoutsList: curatedWorkoutsList || [],
    checkIsFeatureEnabled,
    featuresAreLoading,
    fitnessTestId,
  };
};
export default useFlaggedFeatures;
