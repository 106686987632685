import { Box } from "@mui/system";
import { Dispatch, SetStateAction } from "react";
import { FourDPQuizResultsType, UserType } from "@WahooFitness/cloud-client-types";
import { Button, Typography, useTheme } from "@mui/material";
import { t } from "@lingui/macro";
import useFourDPQuiz from "./useFourDPQuiz";
import CustomStepper from "../AthleteOnboarding/CustomStepper";
import ErrorBoundary from "../ErrorBoundary";
import Loader from "./Results/Loader";
import { GlobalHeader } from "../GlobalHeader";

interface Props {
  user: UserType;
  setResults: Dispatch<SetStateAction<FourDPQuizResultsType | undefined>>;
  setError: Dispatch<SetStateAction<string | undefined>>;
  setPage: Dispatch<SetStateAction<number>>;
  skipEducation: boolean;
}

function FourDPQuiz({ user, setResults, setError, setPage, skipEducation }: Props) {
  const { palette } = useTheme();

  const {
    activeStep,
    steps,
    handleNext,
    handleBack,
    currentStepHasValue,
    currentStepValid,
    quizLoading,
    quizError,
    buttonName,
  } = useFourDPQuiz(user, setResults, setPage, setError, skipEducation);

  if (!steps) {
    return;
  }

  return (
    <ErrorBoundary
      isLoading={quizLoading}
      error={quizError}
      loadingComponent={<Loader message={t`Organizing the minions`} />}
    >
      <GlobalHeader title="" backAction={handleBack} disableBoxShadow={true} />
      <Box height="100%" sx={{ boxShadow: `0 0 0 100vmax ${palette.background.paperElevation4}` }}>
        <>
          <Box
            display="flex"
            position="absolute"
            left={0}
            pl={2}
            py={1}
            sx={{ backgroundColor: palette.background.paperElevation4 }}
          >
            <CustomStepper steps={steps} activeStep={activeStep} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            sx={{ backgroundColor: palette.background.paperElevation4 }}
          >
            <Box display="flex" flexDirection="column" px={2} pt={5} rowGap={1.5}>
              <Typography variant="ui-4xl-bold">{steps[activeStep].headline}</Typography>
              <Typography variant="prose-base" color="text.secondary">
                {steps[activeStep].subheader}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" pt={2} width="100%" px={2}>
              {steps[activeStep].component}
            </Box>
            <Box p={3} width="100%" mt={1} maxWidth="sm">
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={handleNext}
                disabled={!currentStepHasValue || !currentStepValid}
              >
                {buttonName}
              </Button>
            </Box>
          </Box>
        </>
      </Box>
    </ErrorBoundary>
  );
}

export default FourDPQuiz;
