import { Box, Paper } from "@mui/material";
import { useCallback } from "react";
import ErrorBoundary from "../ErrorBoundary";
import TextBoxWithHeader from "../StyledComponents/TextBoxWithHeader";
import { RiderTypeGraphWrapper } from "./RiderTypeGraph/RiderTypeGraphWrapper";
import useRiderType from "./useRiderType";
import { getLocalizedRiderTypeData, getLocalizedRiderTypeWeakness } from "./LocalizedRiderTypes";
import { t } from "@lingui/macro";
import { MenuListItem, MenuListItemVariant } from "@WahooFitness/redesignr";
import { GlobalHeader } from "../GlobalHeader";

const RiderType = () => {
  const {
    indoorRiderTypeName,
    indoorRiderTypeDescription,
    indoorRiderTypeWeaknessName,
    indoorRiderTypeWeaknessSummary,
    indoorRiderTypeWeaknessDescription,
    indoorRiderTypeStrengthName,
    indoorRiderTypeStrengthSummary,
    indoorRiderTypeStrengthDescription,
    userWorkoutBikingProfilesError,
    userWorkoutBikingProfilesLoading,
    mutateWorkoutBikingProfile,
  } = useRiderType();

  const handleReload = useCallback(() => {
    mutateWorkoutBikingProfile();
  }, [mutateWorkoutBikingProfile]);

  const localizedRiderTypeData = getLocalizedRiderTypeData();
  const localizedRiderTypeWeakness = getLocalizedRiderTypeWeakness();

  return (
    <ErrorBoundary
      error={userWorkoutBikingProfilesError}
      isLoading={userWorkoutBikingProfilesLoading}
      onReload={handleReload}
    >
      <GlobalHeader title={t`Rider type`} />
      <Box mx={2} pt={2}>
        <Paper>
          <TextBoxWithHeader
            header={t`Your 4DP Profile, Rider Type, strength & weakness`}
            content={t`Only SYSTM's Full Frontal test provides incredible and unique insight into your abilities, helping you understand who you are as a cyclist right now. The training plans and workouts within SYSTM will be customized to your profile, ensuring your training is precisely tailored for you and you alone. As you train toward your goals, all of these aspects - including your Rider Type - can change so be sure to re-test on a regular basis (roughly every 6 weeks for Half Monty or every 12 weeks for Full Frontal).`}
          />
          {indoorRiderTypeName && (
            <>
              <TextBoxWithHeader
                header={t`Rider type: You are ${localizedRiderTypeData[indoorRiderTypeName]?.prefixedName}`}
                content={indoorRiderTypeDescription || ""}
              />

              <Box mx={2}>
                <RiderTypeGraphWrapper />
              </Box>

              <TextBoxWithHeader
                header={t`Strength: ${indoorRiderTypeStrengthName}`}
                content={indoorRiderTypeStrengthSummary || ""}
                secondaryContent={indoorRiderTypeStrengthDescription || ""}
              />
              {indoorRiderTypeWeaknessName && (
                <TextBoxWithHeader
                  header={t`Weakness: ${localizedRiderTypeWeakness[indoorRiderTypeWeaknessName]}`}
                  content={indoorRiderTypeWeaknessSummary || ""}
                  secondaryContent={indoorRiderTypeWeaknessDescription || ""}
                />
              )}
              <Paper elevation={1}>
                <MenuListItem
                  id="learnRiderTypes"
                  content={t`Learn about Rider Types`}
                  variant={MenuListItemVariant.ExternalLink}
                  hasDivider={false}
                  linkColor="info.main"
                  linkLocation="https://wahoosystm.com/RiderTypeLearnMore"
                />
              </Paper>
            </>
          )}
          {!indoorRiderTypeName && (
            <Paper elevation={1}>
              <MenuListItem
                id="learn4DP"
                content={t`Learn about 4DP®`}
                variant={MenuListItemVariant.ExternalLink}
                hasDivider={false}
                linkColor="info.main"
                linkLocation="https://wahoosystm.com/FourDpLearnMore"
              />
            </Paper>
          )}
        </Paper>
      </Box>
    </ErrorBoundary>
  );
};

export default RiderType;
