import { useSubscriptionContext } from "@WahooFitness/wahoo-offline-mfe";

function useMembership() {
  const { userSubscription, subscriptionError, subscriptionIsLoading } = useSubscriptionContext();

  return {
    userSubscription,
    subscriptionError,
    subscriptionIsLoading,
  };
}

export default useMembership;
