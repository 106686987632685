import { ConnectedAppListItem } from "./useThirdPartySharing";
import { useCallback, useEffect, useMemo } from "react";
import { t } from "@lingui/macro";
import { MenuListItemVariant } from "@WahooFitness/redesignr";
import {
  useDialogContext,
  useConfigContext,
  useNativeMessagingContext,
  postAuthorizeShareService,
  postDeauthorizeShareService,
  postRequestShareServiceStatus,
} from "@WahooFitness/wahoo-offline-mfe";
import { ChevronRight } from "@carbon/icons-react";
import Box from "@mui/system/Box";
import HealthConnectIcon from "@/assets/HealthConnect.png";
import AppleHealthIcon from "@/assets/AppleHealth.png";
import { DialogContentText, Typography } from "@mui/material";

const WAHOO_APP_IOS_FITNESS_APP_ID = 30;
const WAHOO_APP_ANDROID_FITNESS_APP_ID = 31;

const useNativeHealth = () => {
  const { fitnessAppId } = useConfigContext();
  const { nativeShareServiceStatus } = useNativeMessagingContext();
  const { id, name, icon, description, notEnabled, secondaryText } = useMemo(() => {
    if (fitnessAppId === WAHOO_APP_IOS_FITNESS_APP_ID) {
      return {
        id: "appleHealth",
        name: t`Apple Health`,
        icon: AppleHealthIcon,
        description: t`The Apple Health app gives you a common place to store all your health and fitness data.`,
        notEnabled: t`Privacy settings do not allow for data sharing. To change Apple Health permissions,
        open the Health app > Profile > Apps and turn on "Wahoo".`,
        secondaryText: undefined,
      };
    }
    if (fitnessAppId === WAHOO_APP_ANDROID_FITNESS_APP_ID) {
      return {
        id: "healthConnect",
        name: t`Health Connect`,
        icon: HealthConnectIcon,
        description: t`Health Connect stores your health and fitness data, giving you a simple way to sync the different apps on your phone.`,
        notEnabled: undefined,
        secondaryText: t`This will allow your data to be viewed in Google Fit`,
      };
    }
    return {
      id: undefined,
      name: undefined,
      icon: undefined,
      description: undefined,
      notEnabled: undefined,
      secondaryText: undefined,
    };
  }, [fitnessAppId]);

  useEffect(() => {
    if (id) {
      postRequestShareServiceStatus(id);
    }
  }, [id]);

  const { setDialog, handleClose } = useDialogContext();

  const handleNativeHealthDialog = useCallback(() => {
    if (!id) {
      return;
    }
    const authorizedOnly =
      nativeShareServiceStatus?.status === "authorized" &&
      nativeShareServiceStatus?.capabilities?.length === 0;
    setDialog({
      open: true,
      title: authorizedOnly ? t`Enable ${name}?` : t`Authorize ${name}?`,
      body: (
        <DialogContentText>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="prose-base">{description}</Typography>
            {authorizedOnly && notEnabled && (
              <Typography variant="prose-base">{notEnabled}</Typography>
            )}
          </Box>
        </DialogContentText>
      ),
      actions: [
        { text: t`Cancel` },
        {
          text: authorizedOnly ? t`Enable` : t`Authorize`,
          color: "info",
          action: () => {
            postAuthorizeShareService(id);
            handleClose();
          },
        },
      ],
    });
  }, [
    description,
    handleClose,
    id,
    name,
    nativeShareServiceStatus?.capabilities?.length,
    nativeShareServiceStatus?.status,
    notEnabled,
    setDialog,
  ]);

  const addNativeHealth = useCallback(
    (connectedApps: ConnectedAppListItem[] | undefined) => {
      if (!connectedApps) {
        return;
      }
      if (id && ["authorized", "notAuthorized"].includes(nativeShareServiceStatus?.status ?? "")) {
        return [
          {
            authorized:
              nativeShareServiceStatus?.status === "authorized" &&
              nativeShareServiceStatus.capabilities &&
              nativeShareServiceStatus.capabilities?.length > 0
                ? { share_summaries: true, auto_share_summaries: true }
                : null, // authorized, but we don't show the capabilities,
            nativeHealth: true,
            supportedCapabilities: [],
            id,
            icon: (
              <Box
                overflow="hidden"
                borderRadius={2}
                border={1}
                borderColor="divider"
                height={24}
                width={24}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <img width="24" height="24" alt={name} src={icon} />
              </Box>
            ),
            content: name,
            secondaryContent: secondaryText,
            variant: MenuListItemVariant.Action,
            actionComponent: <ChevronRight size={24} />,
            action: handleNativeHealthDialog,
            handleDeauth: () => {
              postDeauthorizeShareService(id);
            },
          } as ConnectedAppListItem,
          ...connectedApps,
        ];
      } else {
        return connectedApps;
      }
    },
    [
      handleNativeHealthDialog,
      icon,
      id,
      name,
      nativeShareServiceStatus?.capabilities,
      nativeShareServiceStatus?.status,
      secondaryText,
    ]
  );

  return { addNativeHealth };
};

export default useNativeHealth;
