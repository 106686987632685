import { OverflowMenuHorizontal } from "@carbon/icons-react";
import { t, Trans } from "@lingui/macro";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import useEditMaps from "./useEditMaps";
import useMaps from "./useMaps";
import useNavUpOnDeviceDisconnect from "../useNavUpOnDeviceDisconnect";
import MapsEmbed from "./MapsEmbed";
import { GlobalHeader } from "@/components/GlobalHeader";

const Maps = ({ appToken }: { appToken: string }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  useNavUpOnDeviceDisconnect(appToken);

  const { mapsSynced, mapPack, mutateMapPack, addMapUpdateListener, removeMapUpdateListener } =
    useMaps(appToken);

  useEffect(() => {
    if (!mapsSynced) return;
    addMapUpdateListener("Maps", () => {
      mutateMapPack();
    });
    return () => removeMapUpdateListener("Maps");
  }, [addMapUpdateListener, mapsSynced, mutateMapPack, removeMapUpdateListener]);

  const tilePacks = useMemo(() => mapPack?.tilePacks, [mapPack]);
  const {
    editing,
    onDeleteClick,
    disableEditing,
    enableEditing,
    handleMapSelect,
    pendingDeleteTilePackIds,
  } = useEditMaps(appToken, tilePacks || []);

  const handleEditClick = useCallback(() => {
    setMenuAnchorEl(null);
    enableEditing();
  }, [enableEditing]);

  const navigate = useNavigate();
  const navigateToUpdatePage = useCallback(() => {
    navigate(`/device-config/${appToken}/maps/update`);
  }, [appToken, navigate]);

  const handleUpdateAllClick = useCallback(() => {
    navigateToUpdatePage();
    closeMenu();
  }, [closeMenu, navigateToUpdatePage]);

  return (
    <>
      <GlobalHeader
        title={t`Manage maps`}
        headerAction={
          editing ? (
            <Button variant="text" onClick={onDeleteClick}>{t`Delete`}</Button>
          ) : (
            <IconButton onClick={openMenu}>
              <OverflowMenuHorizontal size={24} />
            </IconButton>
          )
        }
        backText={editing ? t`Done` : undefined}
        backAction={editing ? disableEditing : undefined}
      />
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        p={2}
        justifyContent="space-between"
      >
        <MapsEmbed
          appToken={appToken}
          onUpdateAllClick={navigateToUpdatePage}
          editing={editing}
          onMapSelect={handleMapSelect}
          pendingDeleteTilePackIds={pendingDeleteTilePackIds}
        />
        <Menu open={menuOpen} onClose={closeMenu} anchorEl={menuAnchorEl}>
          <MenuItem onClick={handleEditClick}>
            <Trans>Edit</Trans>
          </MenuItem>
          <MenuItem onClick={handleUpdateAllClick}>
            <Trans>Update all</Trans>
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default Maps;
