import { ChartRadial } from "@carbon/icons-react";
import { Trans } from "@lingui/macro";
import { alpha, Box, Typography, useTheme } from "@mui/material";

function HowWeCalculateDrawer() {
  const { palette } = useTheme();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2} pb={4} gap={3}>
      <Box
        p={2}
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={alpha(palette.info.main, 0.3)}
      >
        <ChartRadial size={32} />
      </Box>

      <Typography variant="prose-base">
        <Trans>
          This chart visualizes your Athlete Profile and compares how each metric ranks to one
          another for your specific demographic. It factors in your height, weight, age, sex and
          fitness level to determine your abilities. If a value falls in the modest range, it
          highlights areas for improvement. If the value falls in the exceptional range, it
          highlights your strengths as a rider.
        </Trans>
      </Typography>
    </Box>
  );
}

export default HowWeCalculateDrawer;
