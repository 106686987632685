import { useState, useCallback, ChangeEvent, Dispatch, SetStateAction } from "react";
import { Responses } from "../useFourDPQuiz";

interface Props {
  initialVal?: number | "skip";
  setCurrentStepHasValue: (val: boolean) => void;
  setCurrentStepValid: (val: boolean) => void;
  setResponses: Dispatch<SetStateAction<Responses | undefined>>;
  questionId: number;
  min: number;
  max: number;
}

function useNumericEntry({
  initialVal,
  setCurrentStepHasValue,
  setCurrentStepValid,
  setResponses,
  questionId,
  min,
  max,
}: Props) {
  const [val, setVal] = useState(initialVal === "skip" ? undefined : initialVal);
  const [error, setError] = useState("");
  const [skipChecked, setSkipChecked] = useState(initialVal === "skip");

  const checkIsValid = useCallback(
    (value?: number | "skip") => {
      if (value === "skip") {
        setCurrentStepValid(true);
        setCurrentStepHasValue(true);
      } else if (value) {
        setCurrentStepHasValue(true);
        if (value >= min && value <= max) {
          setCurrentStepValid(true);
          setError("");
        } else {
          setCurrentStepValid(false);
          setError(`Value must be between ${min} and ${max}`);
        }
      } else {
        setCurrentStepHasValue(false);
      }
    },
    [max, min, setCurrentStepHasValue, setCurrentStepValid]
  );

  const onChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const newVal = +ev.target.value;
      if (newVal) {
        setVal(newVal);
        setCurrentStepHasValue(true);
        checkIsValid(newVal);
      } else {
        setCurrentStepHasValue(false);
        setVal(undefined);
      }
      setResponses((prev: any) => {
        prev[questionId] = [newVal];
        return prev;
      });
    },
    [checkIsValid, questionId, setCurrentStepHasValue, setResponses]
  );

  const handleSkipToggle = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (ev.target.checked) {
        setSkipChecked(true);
        checkIsValid("skip");
        setVal(0);
        setResponses((prev: any) => {
          prev[questionId] = ["skip"];
          return prev;
        });
      } else {
        setSkipChecked(false);
        checkIsValid(val);
        setResponses((prev: any) => {
          prev[questionId] = [val];
          return prev;
        });
      }
    },
    [checkIsValid, questionId, setResponses, val]
  );

  return {
    val,
    onChange,
    skipChecked,
    error,
    handleSkipToggle,
  };
}

export default useNumericEntry;
