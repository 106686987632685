import { useCallback, useEffect, useMemo } from "react";
import {
  PaceZoneAlgorithmLookups,
  PaceZones as PaceZonesClient,
} from "@WahooFitness/cloud-client-ts";
import { PaceZonesType, PaceZoneThresholdAlgorithmType } from "@WahooFitness/cloud-client-types";
import { clearNulls } from "@/services/clearNulls";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { StoredZones, Zone, ZoneSet } from "./useZones";
import { t } from "@lingui/macro";
import {
  useUserContext,
  useCloudContext,
  useConfigContext,
  useNativeMessagingContext,
  useOfflineSWR,
} from "@WahooFitness/wahoo-offline-mfe";
import { copyZones } from "./ZonesUtils";
import { useUnitFormatter } from "@/hooks/useUnitFormatter";
import { useSettingsRemoteConfig } from "@/hooks/useSettingsRemoteConfig";

const usePaceZones: () => StoredZones = () => {
  const { workoutProfiles, updateProfile, refetchProfiles } = useWorkoutProfilesContext();

  const { userIsLoading, userError, mutateUser } = useUserContext();

  const { getCloudClient } = useCloudContext();
  const { getSettingsRemoteConfigObject } = useSettingsRemoteConfig();
  const { subtractPaceSecondFromMps, addPaceSecondToMps } = useUnitFormatter();
  const { wahooToken } = useConfigContext();
  const { addRefreshListener, removeRefreshListener } = useNativeMessagingContext();

  const paceZonesClient = useMemo(() => getCloudClient(PaceZonesClient), [getCloudClient]);
  const paceZoneAlgorithmLookupClient = useMemo(
    () => getCloudClient(PaceZoneAlgorithmLookups),
    [getCloudClient]
  );

  const {
    data: paceZoneData,
    error: paceZoneError,
    isLoading: paceZoneLoading,
    mutate: mutatePaceZones,
  } = useOfflineSWR(["getPaceZones", wahooToken], async ([_key, wahooToken]) => {
    return clearNulls(await paceZonesClient.get(wahooToken));
  });

  useEffect(() => {
    const refreshListener = addRefreshListener(mutatePaceZones);
    return () => removeRefreshListener(refreshListener);
  }, [addRefreshListener, mutatePaceZones, removeRefreshListener]);

  const extractZonesForDisplay = useCallback(
    (zoneSet: PaceZonesType) => {
      const zoneCount =
        zoneSet.zone_count ||
        Object.entries(zoneSet).filter(([key, value]) => key.startsWith("zone_") && value !== null)
          .length;
      return Array.from(Array(zoneCount).keys()).map((key) => {
        const prevKey = `zone_${key}` as keyof PaceZonesType;
        const currKey = `zone_${key + 1}` as keyof PaceZonesType;
        const top = key === zoneCount - 1 ? ("max" as Zone["top"]) : Number(zoneSet[currKey]) || 0;
        const bottom = key ? subtractPaceSecondFromMps(Number(zoneSet[prevKey])) : 0;
        return {
          bottom,
          top,
          name: t`Zone ${key + 1}`,
        };
      });
    },
    [subtractPaceSecondFromMps]
  );

  const savedZoneSets: Record<number, ZoneSet> = useMemo(() => {
    const zoneSetMap = {} as Record<number, ZoneSet>;
    if (!paceZoneData) {
      return zoneSetMap;
    }
    for (const zoneSetIndex in paceZoneData) {
      const zoneSet = paceZoneData[zoneSetIndex];
      const zones = extractZonesForDisplay(zoneSet);
      zoneSetMap[zoneSet.id] = {
        ...zoneSet,
        zones,
        displayIndex: +zoneSetIndex,
        name: zoneSet.name || t`Set ${+zoneSetIndex + 1}`,
      };
    }
    return zoneSetMap;
  }, [paceZoneData, extractZonesForDisplay]);

  const extractZonesForSave = useCallback((zoneSet: ZoneSet) => {
    const {
      zones,
      zone_1: _zone_1,
      zone_2: _zone_2,
      zone_3: _zone_3,
      zone_4: _zone_4,
      zone_5: _zone_5,
      zone_6: _zone_6,
      zone_7: _zone_7,
      zone_8: _zone_8,
      zone_9: _zone_9,
      zone_10: _zone_10,
      ...rest
    } = zoneSet;
    const update: Omit<ZoneSet, "zones"> = rest || {};
    zones.forEach((zone, index) => {
      if (zone.top !== "max") {
        (update[`zone_${index + 1}` as keyof Omit<ZoneSet, "zones">] as number) = zone.top;
      }
    });
    update.zone_count = zones.length;
    return update;
  }, []);

  const updatedAt = useMemo(() => {
    const updatedAtMap = {} as Record<number, Date | undefined>;
    if (!paceZoneData) {
      return updatedAtMap;
    }
    for (const zoneSet of paceZoneData) {
      updatedAtMap[zoneSet.id] = (zoneSet.updated_at && new Date(zoneSet.updated_at)) || undefined;
    }
    return updatedAtMap;
  }, [paceZoneData]);

  const updateZoneSet = useCallback(
    async (zoneSet: ZoneSet) => {
      const update = extractZonesForSave(zoneSet);
      if (wahooToken && zoneSet.id) {
        const newZoneSet = await paceZonesClient.put(wahooToken, zoneSet.id, update);
        mutatePaceZones(
          paceZoneData?.map((set) => {
            if (set.id === zoneSet.id) {
              return { ...set, ...update };
            }
            return set;
          })
        );
        return newZoneSet;
      }
    },
    [extractZonesForSave, mutatePaceZones, paceZoneData, paceZonesClient, wahooToken]
  );

  const createZoneSet = useCallback(
    async (zoneSet: ZoneSet) => {
      const update = extractZonesForSave(zoneSet);
      if (wahooToken) {
        const newZoneSet = await paceZonesClient.post(wahooToken, update);
        const newZoneSets = [...(paceZoneData || []), newZoneSet];
        mutatePaceZones(newZoneSets, { revalidate: false });
        mutateUser();
        return newZoneSet;
      }
    },
    [extractZonesForSave, mutatePaceZones, mutateUser, paceZoneData, paceZonesClient, wahooToken]
  );

  const saveZoneSet = useCallback(
    async (zoneSet: ZoneSet) => {
      if (zoneSet.id === -1) {
        return createZoneSet(zoneSet);
      } else {
        return updateZoneSet(zoneSet);
      }
    },
    [updateZoneSet, createZoneSet]
  );

  const primaryZoneSetId = useMemo(
    () => paceZoneData?.find((zone) => zone.primary)?.id,
    [paceZoneData]
  );

  const deleteZoneSet = useCallback(
    async (zoneSetId: number) => {
      await Promise.all(
        workoutProfiles
          .filter((profile) => profile.pace_zone_id === zoneSetId)
          .map(async (profile) => await updateProfile(profile.id, "pace_zone_id", primaryZoneSetId))
      );
      await paceZonesClient.delete(wahooToken, zoneSetId);
      if (paceZoneData) {
        mutatePaceZones(paceZoneData.filter((zoneSet) => zoneSet.id !== zoneSetId));
        mutateUser();
      }
      refetchProfiles();
    },
    [
      mutatePaceZones,
      mutateUser,
      paceZoneData,
      paceZonesClient,
      primaryZoneSetId,
      refetchProfiles,
      updateProfile,
      wahooToken,
      workoutProfiles,
    ]
  );

  const clearAlgorithmInfo = useCallback(
    (zoneSets: Record<number, ZoneSet>, _zoneSetId: number) => {
      return zoneSets;
    },
    []
  );

  const autoCalcZoneSet = useCallback(
    async (currentZoneSet: ZoneSet, params: PaceZoneThresholdAlgorithmType) => {
      if (wahooToken) {
        const newZoneSet = await paceZoneAlgorithmLookupClient.postThresholdPace(wahooToken, {
          threshold_speed: params.threshold_speed,
        });
        return {
          ...currentZoneSet,
          ...newZoneSet,
          threshold_speed: newZoneSet.threshold_speed,
          zones: extractZonesForDisplay(newZoneSet),
        };
      }
      return undefined;
    },
    [extractZonesForDisplay, paceZoneAlgorithmLookupClient, wahooToken]
  );

  const maxZoneValue = Infinity;

  const zoneCountChoices = useMemo(
    () => getSettingsRemoteConfigObject<number[]>("PACE_ZONE_COUNT_CHOICES") ?? [6, 7, 8],
    [getSettingsRemoteConfigObject]
  );

  const autoCalculateVariant = "threshold_speed";

  const profileField = "pace_zone_id";

  const reloadZones = useCallback(() => mutateUser(), [mutateUser]);

  const maxAutoCalculateValues = {};

  const zonesSetIsValid: (zoneSet: ZoneSet, zoneCount: number) => boolean = useCallback(
    (zoneSet: ZoneSet, zoneCount: number) => {
      return (
        zoneSet.zones.every(
          (zone, index, array) => index === 0 || zone.bottom > array[index - 1].bottom
        ) && zoneCountChoices?.includes(zoneCount)
      );
    },
    [zoneCountChoices]
  );

  const createValidZones = useCallback(
    (zoneSetId: number, startingIndex: number, zoneSets: Record<number, ZoneSet>) => {
      const correctedZones = copyZones(zoneSets);
      const zoneSet = correctedZones[zoneSetId].zones;
      zoneSet[startingIndex - 1].top = addPaceSecondToMps(zoneSet[startingIndex].bottom);

      // ensure zones are in ascending order (in meters/sec). Assumes only the zone at startingIndex
      // has been changed, and the zones were in a valid state before the change
      for (let i = startingIndex; i < zoneSet.length; i++) {
        if (zoneSet[i].bottom <= zoneSet[i - 1].bottom) {
          zoneSet[i].bottom = subtractPaceSecondFromMps(+zoneSet[i - 1].bottom);
          zoneSet[i - 1].top = +zoneSet[i - 1].bottom;
          // if the value at the starting index was just corrected, there is no need to continue.
          // The values above the starting index can't be less than the value at the starting index if the starting index value was lowered.
          if (i === startingIndex) {
            break;
          }
        }
        // if the value at startingIndex was already above the value below it, it may have been raised, so the values above it need to be checked
        else if (i === startingIndex) {
          continue;
        }
        // if i is an index above startingIndex, and the value was already above the value below it, then everything should already be ascending
        else {
          break;
        }
      }
      return correctedZones;
    },
    [addPaceSecondToMps, subtractPaceSecondFromMps]
  );

  return {
    savedZoneSets,
    updatedAt,
    saveZoneSet,
    maxZoneValue,
    zoneCountChoices,
    autoCalculateVariant,
    primaryZoneSetId,
    profileField,
    autoCalcZoneSet,
    deleteZoneSet,
    clearAlgorithmInfo,
    error: userError || paceZoneError,
    isLoading: userIsLoading || paceZoneLoading,
    reloadZones,
    maxAutoCalculateValues,
    zonesSetIsValid,
    createValidZones,
    extractZonesForDisplay,
  };
};

export default usePaceZones;
