import { Trans } from "@lingui/macro";
import { AppBar, Box, Chip, Stack, Toolbar, Typography, useTheme } from "@mui/material";
import { PLATFORM_ENUM } from "@WahooFitness/cloud-client-types";
import { InfoIcon } from "@WahooFitness/redesignr";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";

function ResultsHeaderBar({
  riderType,
  showHeaderBar,
  riderTypeIcon,
  handleEstimateClick,
}: {
  riderType?: string;
  showHeaderBar?: boolean;
  riderTypeIcon?: JSX.Element;
  handleEstimateClick?: () => void;
}) {
  const { platform } = useConfigContext();
  const { palette } = useTheme();
  return (
    <AppBar
      color={showHeaderBar ? undefined : "transparent"}
      sx={{
        boxShadow: "none",
        transition: "all .5s ease",
        WebkitTransition: "all .5s ease",
        MozTransition: "all .5s ease",
        borderBottom: showHeaderBar ? `1px solid ${palette.dividerSubtle}` : "none",
      }}
    >
      <Toolbar
        variant={platform === PLATFORM_ENUM.ios ? "ios" : "regular"}
        sx={{ justifyContent: "center" }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          paddingY={2}
          paddingX={1}
          maxWidth="sm"
        >
          {showHeaderBar && (
            <>
              <Typography variant="ui-4xl-bold">{riderType}</Typography>
              <Box ml={1.5} justifyContent="center" height={24}>
                {riderTypeIcon}
              </Box>
              <Chip
                label={
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <Typography variant="prose-xs-medium" color="text.primary">
                      <Trans>Estimate</Trans>
                    </Typography>
                    <InfoIcon fontSize="small" color="primary" />
                  </Stack>
                }
                size="small"
                sx={{
                  borderRadius: 0.5,
                  backgroundColor: "action.selected",
                  color: "text.primary",
                  maxWidth: "fit-content",
                  marginLeft: "auto",
                }}
                clickable
                onClick={handleEstimateClick}
              />
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default ResultsHeaderBar;
