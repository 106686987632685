import ProfileCard from "@/components/FitnessProfile/ProfileCard";
import { t, Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import FitnessAssessment from "@/assets/FitnessAssessment.png";

function FitnessTestDrawer() {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={2} pb={4} gap={3}>
      <Typography variant="prose-base" color="text.primary">
        <Trans>
          These values are estimates based on your questionnaire answers, not actual training data.
          For the most accurate results, complete the Fitness Assessment in the SYSTM App. You can
          access this from your Athlete Profile anytime.
        </Trans>
      </Typography>

      <ProfileCard
        useCardStyles={false}
        icon={FitnessAssessment}
        title={t`Fitness Assessment`}
        tag={t`Most accurate`}
        time={t`59 min`}
        description={t`Take Full Frontal test to get the most accurate results of your Athlete Profile.`}
      />
    </Box>
  );
}

export default FitnessTestDrawer;
