import { motion } from "motion/react";

function SprintGraph({ activeStep, stepId }: { activeStep: number; stepId: number }) {
  return (
    <>
      <motion.path
        d="M12 288C12.0011 198.5 37.5 53.5 101 53.5C164.5 53.5 250.5 288 344.001 288"
        stroke="url(#paint0_linear_3516_20250)"
        strokeWidth="24"
        strokeLinecap="round"
        strokeLinejoin="round"
        initial="hidden"
        variants={{
          show: {
            pathLength: [0.001, 1],
            pathOffset: [0.0001],
            opacity: 1,
            transition: { duration: 2, ease: [0.25, 0.1, 0.25, 1] },
          },
          hidden: {
            pathLength: [1, 0.001],
            pathOffset: [0.0001, 0.9999],
            opacity: 0,
            transition: { pathLength: { opacity: 0.2 } },
          },
        }}
        animate={activeStep === stepId ? "show" : "hidden"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_3516_20250"
          x1="176.092"
          y1="291.832"
          x2="176.092"
          y2="53.2905"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC2E4" />
          <stop offset="1" stopColor="#FF36A4" />
        </linearGradient>
      </defs>
    </>
  );
}

export default SprintGraph;
