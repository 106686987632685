import { t } from "@lingui/macro";
import { useCallback, useMemo } from "react";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";
import useSubscription, { SubscriptionState } from "@/hooks/useSubscription";
import Membership from "../components/Membership/MembershipDrawerContents";
import { useNavigate } from "react-router";
import { useDrawerContext } from "@WahooFitness/wahoo-offline-mfe";

const usePaywall = () => {
  const { subscriptionState } = useSubscription();
  const { checkIsFeatureEnabled } = useFlaggedFeatures();
  const { setDrawer, handleClose: handleCloseDrawer } = useDrawerContext();
  const navigate = useNavigate();

  const showMembershipDrawer = useCallback(
    (analyticsParams?: { [key: string]: string | number | undefined }) => {
      const membershipDrawerTitle =
        subscriptionState === SubscriptionState.CanTrial
          ? t`Free Wahoo X Trial`
          : t`Wahoo X Membership`;
      setDrawer({
        open: true,
        title: membershipDrawerTitle,
        hidePuller: true,
        children: <Membership onClose={handleCloseDrawer} analyticsParams={analyticsParams} />,
      });
    },
    [handleCloseDrawer, setDrawer, subscriptionState]
  );

  const updatedPaywallEnabled = useMemo(
    () => checkIsFeatureEnabled(FlaggedFeature.UpdatedPaywall),
    [checkIsFeatureEnabled]
  );

  const showPaywall = useCallback(
    ({
      handleClose,
      analyticsParams,
    }: {
      handleClose: () => void;
      analyticsParams?: { [key: string]: string | number | undefined };
    }) => {
      if (!updatedPaywallEnabled) {
        showMembershipDrawer(analyticsParams);
      } else {
        navigate("/paywall");
      }
      handleClose();
    },
    [navigate, showMembershipDrawer, updatedPaywallEnabled]
  );

  return { showPaywall };
};

export default usePaywall;
