import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

function Loader({ message }: { message: string }) {
  const { palette } = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      gap={2}
      bgcolor={palette.background.paperElevation4}
      pb={20}
    >
      <Typography variant="prose-2xl-medium" color="text.primary" textAlign="center">
        {message}
      </Typography>
      <>
        <svg width={44} height={44}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor={palette["4dpFtp"].main} />
              <stop offset="31.5%" stopColor={palette["4dpMap"].main} />
              <stop offset="74%" stopColor={palette["4dpAc"].main} />
              <stop offset="100%" stopColor={palette["4dpNm"].main} />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
      </>
    </Box>
  );
}

export default Loader;
