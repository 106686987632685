import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Stack,
  useTheme,
  Box,
} from "@mui/material";
import { ChevronDownIcon } from "@WahooFitness/redesignr";
import { useState } from "react";

type FitnessProfileAccordionProps = {
  title?: string;
  name?: string;
  icon: string;
  isSmallScreen: boolean;
  defaultExpanded: boolean;
  disabled?: boolean;
  details: JSX.Element;
};
const FitnessProfileAccordion = ({
  title,
  name,
  icon,
  isSmallScreen,
  defaultExpanded,
  disabled = false,
  details,
}: FitnessProfileAccordionProps) => {
  const { palette } = useTheme();

  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const handleChangeExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      display="flex"
      flex={1}
      width="100%"
      alignSelf={{ xs: "center", sm: isExpanded ? "stretch" : "flex-start" }}
    >
      <Accordion
        sx={{
          width: "100%",
          backgroundColor: palette.background.paperElevation4,
          px: 0,
          mx: isSmallScreen ? 2 : 0,
          borderRadius: 2,
          "&.Mui-expanded": {
            my: 0,
            mx: isSmallScreen ? 2 : 0,
          },
          ":last-of-type": {
            borderBottom: `1px solid ${palette.divider}`,
            borderRadius: 2,
          },
          "&.MuiAccordion-rounded.Mui-disabled": {
            backgroundColor: palette.background.paperElevation4,
            color: palette.text.primary,
          },
        }}
        variant="outlined"
        expanded={isExpanded}
        onChange={handleChangeExpanded}
        disableGutters
        disabled={disabled}
      >
        <AccordionSummary
          expandIcon={disabled ? null : <ChevronDownIcon />}
          sx={{
            "&.Mui-disabled": {
              opacity: 1,
            },
          }}
        >
          <img src={icon} alt={name} style={{ height: 44, width: 44 }} />
          <Stack ml={1.5} justifyContent="center">
            <Typography variant="prose-sm" color="text.secondary">
              {title}
            </Typography>
            <Typography variant="prose-md">{name}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{details}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FitnessProfileAccordion;
