import { plural, t, Trans } from "@lingui/macro";
import { KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import { useLiveTrack } from "./useLiveTrack";
import EmailValidator from "email-validator";
import FullScreenDialog from "../FullScreenDialog";
import { useDialogContext } from "@WahooFitness/wahoo-offline-mfe";
import { CloseFilled } from "@carbon/icons-react";

const AddLiveTrackContact = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  const { setDialog, handleClose: handleDialogClose } = useDialogContext();

  const {
    addContacts,
    liveTrackUpdateInProgress,
    nativeContacts,
    handleGetNativeContacts,
    clearNativeContacts,
  } = useLiveTrack();

  const [email, setEmail] = useState<string>("");

  const addNativeContacts = useCallback(async () => {
    if (nativeContacts.length === 0) {
      return;
    }
    const result = await addContacts(nativeContacts.map((contact) => contact.email));
    if (!result) {
      return;
    }
    setEmail("");
    clearNativeContacts();
    handleClose();
  }, [addContacts, clearNativeContacts, handleClose, nativeContacts]);

  useEffect(() => {
    if (nativeContacts.length > 0) {
      setDialog({
        open: true,
        title: t`Add contacts`,
        body: plural(nativeContacts.length, {
          one: "Are you sure you want to import # contact?",
          other: "Are you sure you want to import # contacts?",
        }),
        actions: [
          {
            text: t`Cancel`,
            action: () => {
              clearNativeContacts();
            },
          },
          {
            text: t`Import`,
            color: "info",
            action: () => {
              addNativeContacts();
            },
          },
        ],
      });
    }
  }, [addNativeContacts, clearNativeContacts, handleDialogClose, nativeContacts.length, setDialog]);

  useEffect(() => {
    if (nativeContacts.length === 0) {
      handleDialogClose();
    }
  }, [handleDialogClose, nativeContacts.length]);

  const error = useMemo(() => {
    return email.length === 0 || EmailValidator.validate(email)
      ? null
      : t`That doesn't look like a valid email`;
  }, [email]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const handleAddContact = useCallback(async () => {
    if (error || email.length === 0) {
      return;
    }
    if (!(await addContacts([email]))) {
      return;
    }
    setEmail("");
    handleClose();
  }, [addContacts, email, error, handleClose]);

  const handleCancel = useCallback(() => {
    setEmail("");
    handleClose();
  }, [handleClose]);

  const handleKeyboardCommands: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === "Enter") {
        if (!error && email.length > 0) {
          handleAddContact();
        }
        event.stopPropagation();
      }
    },
    [email.length, error, handleAddContact]
  );

  const handleClearClick = useCallback(() => {
    setEmail("");
  }, []);

  return (
    <FullScreenDialog
      slideDirection="up"
      open={open}
      handleClose={handleCancel}
      headerProps={{
        title: t`Add recipient`,
        backAction: handleCancel,
        backText: t`Cancel`,
        headerAction: (
          <Button
            disabled={liveTrackUpdateInProgress || !!error || email.length === 0}
            onClick={handleAddContact}
          >
            <Trans>Add</Trans>
          </Button>
        ),
      }}
    >
      <Box m={2} display="flex" flexDirection="column" gap={3}>
        <Button
          disabled={liveTrackUpdateInProgress}
          variant="outlined"
          size="large"
          fullWidth
          onClick={handleGetNativeContacts}
        >
          <Trans>Use email from Contacts</Trans>
        </Button>
        <Divider>
          <Typography
            variant="prose-sm"
            color="textSecondary"
            sx={{ marginLeft: 2, marginRight: 2 }}
          >
            <Trans>Or enter email manually</Trans>
          </Typography>
        </Divider>
        <TextField
          value={email}
          onChange={handleChange}
          error={!!error}
          label={t`Email address`}
          helperText={error ?? ""}
          onKeyUp={handleKeyboardCommands}
          variant="outlined"
          disabled={liveTrackUpdateInProgress}
          type="email"
          InputProps={{
            endAdornment: email ? (
              <InputAdornment position="end">
                <CloseFilled size={24} onClick={handleClearClick} />
              </InputAdornment>
            ) : undefined,
          }}
        />
      </Box>
    </FullScreenDialog>
  );
};

export { AddLiveTrackContact };
