import { Trans } from "@lingui/macro";
import { Alert, AlertTitle } from "@mui/material";
import { Box } from "@mui/system";

function RiderTypeMismatchAlert({ onClose }: { onClose: () => void }) {
  return (
    <Box mx={2}>
      <Alert icon={false} onClose={onClose} severity="info">
        <AlertTitle>
          <Trans>Noticing a different Rider Type than the one in SYSTM?</Trans>
        </AlertTitle>
        <Trans>
          No, you didn&apos;t suddenly transform overnight! We&apos;ve just upgraded to a smarter,
          more accurate algorithm that better captures your true abilities.
        </Trans>
      </Alert>
    </Box>
  );
}

export default RiderTypeMismatchAlert;
