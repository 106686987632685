import { Box, useTheme } from "@mui/material";
import { t } from "@lingui/macro";

function PotentialArrow() {
  const { palette, typography } = useTheme();
  return (
    <Box display="flex" alignItems="flex-end">
      <svg
        width="174"
        height="60"
        viewBox="0 0 174 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <text
          fill={palette.text.primary}
          x="50%"
          y="90%"
          dominantBaseline="end"
          textAnchor="middle"
          style={{
            fontSize: typography["prose-xs"].fontSize,
            fontWeight: typography["prose-xs"].fontWeight,
            fontFamily: typography["prose-xs"].fontFamily,
          }}
        >
          {t`FTP POTENTIAL`}
        </text>
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M174 41L87 0L0 41H27V60H143V41H174Z"
          fill="url(#paint0_linear_3335_2067)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3335_2067"
            x1="87"
            y1="60"
            x2="87"
            y2="0"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="rgba(254, 243, 155, 0.3)" />
            <stop offset="1" stopColor="rgba(254, 207, 18, 1)" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

export default PotentialArrow;
