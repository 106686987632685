import { PropsWithChildren, useCallback } from "react";
import { Box, DialogContentText, Typography } from "@mui/material";
import OnboardingNavbar from "./OnboardingNavbar";
import { useDialogContext, postRoute } from "@WahooFitness/wahoo-offline-mfe";
import { Trans, t } from "@lingui/macro";

function OnboardingLayout({ children }: PropsWithChildren) {
  const { handleClose, setDialog } = useDialogContext();
  const openConfirmationDialog = useCallback(async () => {
    setDialog({
      open: true,
      title: t`Exit Setup?`,
      body: (
        <DialogContentText>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="prose-base">
              <Trans>
                Are you sure you want to exit the KICKR RUN setup? Please complete the setup for an
                optimal experience and to receive <b>KEY SAFETY INSTRUCTIONS</b>. For your safety,
                key features of your KICKR RUN are disabled until setup is complete.
              </Trans>
            </Typography>
            <Typography variant="prose-base">
              <Trans>
                To continue setting up later, visit the KICKR Run page in the Devices tab.
              </Trans>
            </Typography>
          </Box>
        </DialogContentText>
      ),
      actions: [
        { text: t`Cancel`, action: handleClose },
        {
          text: t`Exit`,
          action: () => {
            postRoute("home");
            handleClose();
          },
        },
      ],
    });
  }, [handleClose, setDialog]);
  return (
    <Box
      id="onboarding-layout"
      display="flex"
      flexDirection="column"
      overflow="auto"
      height="100%"
      alignItems="center"
    >
      <OnboardingNavbar onClose={openConfirmationDialog} />
      {children}
    </Box>
  );
}

export default OnboardingLayout;
