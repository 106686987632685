import { motion } from "motion/react";

function BreakawayGraph({ activeStep, stepId }: { activeStep: number; stepId: number }) {
  return (
    <>
      <motion.path
        d="M12 290C12 290 51.0597 246.622 51.0597 174H339"
        stroke="url(#paint2_linear_3516_20250)"
        strokeWidth="24"
        strokeLinecap="round"
        strokeLinejoin="round"
        initial="hidden"
        variants={{
          show: {
            pathLength: [0.001, 1],
            pathOffset: [0.0001],
            opacity: 1,
            transition: { duration: 2, ease: [0.25, 0.1, 0.25, 1] },
          },
          hidden: {
            pathLength: [1, 0.001],
            pathOffset: [0.0001, 0.9999],
            opacity: 0,
            transition: { pathLength: { opacity: 0.2 } },
          },
        }}
        animate={activeStep === stepId ? "show" : "hidden"}
      />
      <defs>
        <linearGradient
          id="paint2_linear_3516_20250"
          x1="175.5"
          y1="290"
          x2="175.5"
          y2="174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEF39B" />
          <stop offset="1" stopColor="#FECF12" />
        </linearGradient>
      </defs>
    </>
  );
}

export default BreakawayGraph;
