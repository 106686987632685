import { t } from "@lingui/macro";
import ErrorBoundary from "../ErrorBoundary";
import MainSportQuestion from "../Onboarding/TrainingPreferences/MainSportQuestion";
import useTrainingPreferences from "./useTrainingPreferences";
import { Box } from "@mui/material";
import { useUserContext } from "@WahooFitness/wahoo-offline-mfe";
import SuggestedWorkoutsPreferenceToggle from "./SuggestedWorkoutsPreferenceToggle";
import { GlobalHeader } from "../GlobalHeader";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";
import { useMemo } from "react";

const TrainingPreferences: React.FC = () => {
  const { sportOptionListItems } = useTrainingPreferences();
  const { checkIsFeatureEnabled } = useFlaggedFeatures();
  const { currentUser } = useUserContext();

  const showSuggestedWorkouts = useMemo(
    () => checkIsFeatureEnabled(FlaggedFeature.ShowSuggestedWorkouts),
    [checkIsFeatureEnabled]
  );

  return (
    <ErrorBoundary isLoading={false} error={false} onReload={() => {}}>
      <GlobalHeader title={t`Training preferences`} />
      <Box pt={2}>
        <MainSportQuestion sportOptionListItems={sportOptionListItems} />
        {showSuggestedWorkouts && currentUser && (
          <SuggestedWorkoutsPreferenceToggle user={currentUser} />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default TrainingPreferences;
