import { motion } from "motion/react";

export const FourDPLogoWithCopySvg = ({
  activeStep,
  stepId,
}: {
  activeStep: number;
  stepId: number;
}) => {
  return (
    <motion.svg
      width="100%"
      height="100%"
      viewBox="0 0 284 284"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial="hidden"
      variants={{
        show: {
          pathLength: [0.001, 1],
          pathOffset: [0.0001],
          opacity: 1,
          transition: { duration: 2, ease: [0.25, 0.1, 0.25, 1] },
        },
        hidden: {
          pathLength: [1, 0.001],
          pathOffset: [0.0001, 0.9999],
          opacity: 0,
          transition: { pathLength: { opacity: 0.2 } },
        },
      }}
      animate={activeStep === stepId ? "show" : "hidden"}
    >
      <rect width="284" height="284" rx="140" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.769 6.81596C128.023 0.853455 150.896 14.059 156.859 36.3113C162.822 58.5635 149.616 81.4361 127.362 87.3986C100.477 94.6023 83.3362 120.02 85.6604 146.818C76.3076 129.28 55.8056 119.837 35.7828 125.202C17.763 130.03 5.40412 145.439 3.59982 162.953C-7.02462 93.3696 36.1923 25.4582 105.769 6.81596Z"
        fill="url(#paint0_linear_2605_102598)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.792 35.7938C153.962 17.7679 138.543 5.40708 121.02 3.61027C190.602 -7.04891 258.527 36.1537 277.169 105.722C283.129 127.964 269.929 150.827 247.685 156.787C225.441 162.747 202.577 149.547 196.617 127.305C189.42 100.449 164.041 83.3217 137.273 85.6167C154.749 76.2402 164.148 55.7785 158.792 35.7938Z"
        fill="url(#paint1_linear_2605_102598)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.273 277.169C247.874 258.521 291.086 190.548 280.375 120.937C278.589 138.463 266.229 153.889 248.2 158.719L248.156 158.731L248.111 158.739C225.288 162.673 211.796 154.732 204.085 145.84C201.638 143.019 199.783 140.114 198.396 137.487C200.586 164.178 183.476 189.444 156.689 196.621C134.445 202.581 121.244 225.444 127.205 247.687C133.165 269.929 156.029 283.129 178.273 277.169Z"
        fill="url(#paint2_linear_2605_102598)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.3704 156.696C94.5775 183.592 120.023 200.731 146.836 198.373C129.333 207.732 119.914 228.207 125.272 248.204C130.103 266.23 145.527 278.589 163.052 280.377C93.4402 291.084 25.467 247.875 6.81786 178.279C0.857625 156.036 14.0581 133.173 36.3021 127.213C58.5461 121.253 81.4101 134.453 87.3704 156.696Z"
        fill="url(#paint3_linear_2605_102598)"
      />
      <path
        d="M178.71 54.4364L177.443 53.6768L187.091 45.8933L188.338 46.6411L186.02 58.8184L184.753 58.0589L185.444 54.6306L181.408 52.2117L178.71 54.4364ZM182.431 51.3674L185.706 53.3306L186.807 47.8695L186.729 47.8228L182.431 51.3674Z"
        fill="#191C1E"
      />
      <path
        d="M196.714 53.5846L197.489 52.604L204.335 58.0162L203.56 58.9968L200.69 56.7277L194.249 64.8756L193.143 64.0017L199.584 55.8538L196.714 53.5846Z"
        fill="#191C1E"
      />
      <path
        d="M211.411 65.9192L212.312 65.0534L218.357 71.3483L217.455 72.2141L214.921 69.5748L207.429 76.7688L206.453 75.7524L213.945 68.5584L211.411 65.9192Z"
        fill="#191C1E"
      />
      <path
        d="M215.89 86.3997L215.017 85.208L226.929 81.7773L227.788 82.9506L220.927 93.2746L220.054 92.0829L222.021 89.1911L219.24 85.3962L215.89 86.3997ZM220.51 85.0149L222.767 88.0948L225.9 83.4886L225.847 83.4153L220.51 85.0149Z"
        fill="#191C1E"
      />
      <path
        d="M236.624 104.193L235.944 102.959C237.193 101.882 237.281 100.251 236.535 98.897C235.516 97.0456 233.282 96.3818 230.634 97.84C227.987 99.2982 227.354 101.541 228.373 103.392C229.119 104.746 230.544 105.544 232.122 105.064L232.802 106.298C230.503 107.071 228.348 106.079 227.219 104.028C225.782 101.42 226.751 98.4217 229.976 96.6455C233.201 94.8693 236.254 95.6532 237.69 98.2611C238.819 100.312 238.506 102.664 236.624 104.193Z"
        fill="#191C1E"
      />
      <path
        d="M232.281 113.912L243.077 109.571L243.603 110.878L238.247 113.032L238.297 113.159L245.604 115.855L246.29 117.563L239.596 114.988L235.494 121.904L234.858 120.323L238.368 114.551L236.307 113.812L232.806 115.22L232.281 113.912Z"
        fill="#191C1E"
      />
      <path
        d="M41.2703 168.009L52.3046 164.315L54.5342 170.974L53.3489 171.371L51.5666 166.048L47.8382 167.296L49.505 172.274L48.3197 172.671L46.6529 167.693L42.9029 168.948L44.714 174.358L43.5287 174.755L41.2703 168.009Z"
        fill="#191C1E"
      />
      <path
        d="M61.4216 186.401L50.9651 191.507L50.3668 190.282L55.7946 180.575L55.7448 180.473L47.5348 184.481L46.9165 183.215L57.373 178.11L57.9713 179.335L52.533 189.072L52.5829 189.174L60.8133 185.156L61.4216 186.401Z"
        fill="#191C1E"
      />
      <path
        d="M56.4864 200.544L54.5024 197.551L64.2015 191.122L66.2734 194.247C68.1443 197.07 67.4308 199.942 64.4188 201.939C61.3878 203.948 58.4453 203.499 56.4864 200.544ZM56.3228 198.035L57.4781 199.778C59.0728 202.183 61.2974 202.372 63.6654 200.802C66.0143 199.245 66.6881 197.136 65.1812 194.862L63.9381 192.987L56.3228 198.035Z"
        fill="#191C1E"
      />
      <path
        d="M77.6893 208.887L78.6148 209.95L72.8047 215.01C71.0052 216.577 68.5138 216.381 66.7152 214.316C64.9165 212.25 65.0645 209.756 66.8641 208.188L72.6741 203.128L73.5995 204.191L67.8752 209.176C66.5898 210.296 66.433 211.985 67.7092 213.45C68.9854 214.915 70.6796 214.992 71.965 213.872L77.6893 208.887Z"
        fill="#191C1E"
      />
      <path
        d="M74.9382 222.671L82.6026 213.915L85.561 216.505C87.6131 218.301 87.5411 220.247 86.1864 221.794C85.1684 222.957 83.8497 223.411 82.4008 222.815L81.2142 228.165L79.9829 227.087L81.1337 222.046C81.0573 221.986 80.9846 221.923 80.9076 221.855L78.9923 220.179L75.9984 223.599L74.9382 222.671ZM79.8306 219.221L81.7117 220.868C83.114 222.095 84.2226 221.933 85.1432 220.881C86.0638 219.83 86.1229 218.658 84.7035 217.415L82.8395 215.784L79.8306 219.221Z"
        fill="#191C1E"
      />
      <path
        d="M88.2711 233.575L94.685 223.865L100.545 227.736L99.8556 228.779L95.1717 225.685L93.0045 228.966L97.385 231.859L96.696 232.902L92.3155 230.009L90.1358 233.308L94.8956 236.453L94.2066 237.496L88.2711 233.575Z"
        fill="#191C1E"
      />
      <path
        d="M54.0853 95.6205C53.5529 95.3909 53.0275 95.4062 52.5092 95.6663C51.9909 95.9264 51.5551 96.3613 51.202 96.971C50.9439 97.4168 50.7901 97.8486 50.7407 98.2665C50.6931 98.681 50.7415 99.0548 50.8857 99.3879C51.0318 99.7176 51.2654 99.9755 51.5866 100.162C51.8554 100.317 52.1235 100.387 52.3909 100.371C52.657 100.35 52.9128 100.275 53.1583 100.146C53.4006 100.015 53.6272 99.8616 53.8383 99.6862C54.046 99.5089 54.2286 99.341 54.386 99.1827L55.2536 98.3195C55.4743 98.0971 55.7383 97.8604 56.0456 97.6094C56.3549 97.3552 56.6998 97.1413 57.0804 96.9678C57.4597 96.7891 57.8676 96.697 58.3041 96.6916C58.7406 96.6861 59.1948 96.8201 59.6668 97.0934C60.211 97.4086 60.6201 97.8359 60.8941 98.3755C61.1701 98.9117 61.28 99.5269 61.2238 100.221C61.1695 100.912 60.9184 101.644 60.4704 102.418C60.0527 103.139 59.5747 103.696 59.0363 104.089C58.4999 104.478 57.9362 104.703 57.3452 104.764C56.7562 104.821 56.1749 104.71 55.6015 104.43L56.3305 103.172C56.7297 103.359 57.1184 103.413 57.4966 103.335C57.8734 103.251 58.2221 103.072 58.5428 102.798C58.862 102.519 59.1374 102.18 59.369 101.78C59.6386 101.314 59.8052 100.853 59.869 100.395C59.9294 99.9354 59.8863 99.5186 59.7395 99.1447C59.5895 98.7689 59.3309 98.4747 58.9638 98.2621C58.6294 98.0684 58.3033 98.0043 57.9853 98.0696C57.6673 98.135 57.3545 98.2821 57.0468 98.511C56.7391 98.7399 56.4336 99.0029 56.1303 99.2999L55.0587 100.334C54.3769 100.99 53.6933 101.425 53.008 101.641C52.3226 101.857 51.6407 101.769 50.9622 101.376C50.3984 101.049 49.995 100.612 49.752 100.064C49.5076 99.5112 49.419 98.9083 49.4863 98.2556C49.5521 97.5978 49.7711 96.9476 50.1431 96.3052C50.519 95.6562 50.9695 95.1467 51.4946 94.7767C52.0164 94.4048 52.5627 94.1871 53.1334 94.1237C53.7061 94.057 54.2512 94.1626 54.7687 94.4405L54.0853 95.6205Z"
        fill="#191C1E"
      />
      <path
        d="M66.7998 92.1302L57.6278 84.9693L60.0474 81.8702C60.6092 81.1506 61.1983 80.6638 61.8147 80.4098C62.4304 80.1505 63.044 80.0817 63.6554 80.2034C64.2668 80.3251 64.8427 80.597 65.3831 81.0189C65.9235 81.4408 66.3284 81.9347 66.5978 82.5007C66.8695 83.0636 66.9584 83.676 66.8645 84.338C66.7676 84.9976 66.4406 85.6842 65.8835 86.3978L64.1492 88.6191L63.1639 87.8499L64.8702 85.6644C65.2549 85.1717 65.4786 84.7097 65.5416 84.2782C65.6045 83.8468 65.5436 83.4509 65.3589 83.0904C65.1735 82.7246 64.8972 82.3984 64.5299 82.1116C64.1627 81.8249 63.7807 81.6372 63.3838 81.5484C62.9893 81.4567 62.5922 81.4974 62.1926 81.6708C61.79 81.8418 61.394 82.1766 61.0047 82.6752L59.4802 84.6279L67.667 91.0196L66.7998 92.1302Z"
        fill="#191C1E"
      />
      <path
        d="M76.9066 80.0326L68.8062 71.6786L71.629 68.9415C72.2817 68.3087 72.9255 67.9008 73.5605 67.7177C74.1929 67.5319 74.7969 67.5345 75.3725 67.7255C75.9482 67.9166 76.4747 68.2582 76.9519 68.7504C77.4292 69.2426 77.7518 69.7766 77.9197 70.3525C78.0876 70.9283 78.069 71.5267 77.8639 72.1477C77.6561 72.766 77.2287 73.3889 76.5814 74.0165L74.2971 76.2314L73.4112 75.3177L75.6628 73.1344C76.1088 72.7019 76.4045 72.2886 76.5499 71.8944C76.698 71.4976 76.7172 71.1202 76.6075 70.7622C76.4979 70.3989 76.281 70.05 75.9566 69.7155C75.6323 69.381 75.2863 69.1493 74.9186 69.0204C74.5509 68.8915 74.1639 68.8922 73.7574 69.0226C73.3482 69.1502 72.9179 69.4329 72.4665 69.8706L70.688 71.5951L77.9182 79.0517L76.9066 80.0326ZM77.2 72.467L82.8947 74.2263L81.7199 75.3654L76.0579 73.5744L77.2 72.467Z"
        fill="#191C1E"
      />
      <path
        d="M82.6539 59.4027L89.7691 68.6102L88.6541 69.4718L81.5389 60.2643L82.6539 59.4027Z"
        fill="#191C1E"
      />
      <path
        d="M98.5196 48.9337L104.474 58.9309L103.303 59.6287L93.1797 55.0244L93.0821 55.0825L97.7576 62.9319L96.547 63.653L90.5921 53.6558L91.7636 52.9579L101.918 57.5702L102.016 57.5121L97.3285 49.6432L98.5196 48.9337Z"
        fill="#191C1E"
      />
      <path
        d="M107.557 45.7537L107.081 44.5979L115.151 41.2745L115.627 42.4303L112.244 43.8237L116.199 53.4275L114.896 53.9641L110.941 44.3603L107.557 45.7537Z"
        fill="#191C1E"
      />
      <path
        d="M151.893 247.75L151.387 236.124L155.451 235.947C157.88 235.842 159.076 237.177 159.15 238.88C159.215 240.379 158.358 241.076 157.371 241.392L157.376 241.505C158.446 241.527 159.761 242.471 159.841 244.31C159.918 246.058 158.841 247.447 156.093 247.567L151.893 247.75ZM153.246 246.439L156.039 246.318C157.878 246.238 158.546 245.39 158.502 244.368C158.45 243.187 157.452 242.207 155.931 242.273L153.07 242.398L153.246 246.439ZM153.017 241.172L155.628 241.058C156.899 241.003 157.864 240.164 157.81 238.938C157.766 237.917 157.049 237.129 155.505 237.196L152.849 237.312L153.017 241.172Z"
        fill="black"
      />
      <path
        d="M165.595 246.98L163.277 235.577L167.13 234.794C169.803 234.251 171.307 235.487 171.716 237.503C172.024 239.017 171.586 240.342 170.233 241.132L173.769 245.319L172.165 245.645L168.853 241.674C168.76 241.699 168.665 241.718 168.565 241.738L166.071 242.245L166.976 246.7L165.595 246.98ZM165.817 240.998L168.267 240.5C170.093 240.129 170.636 239.149 170.358 237.779C170.079 236.409 169.183 235.652 167.335 236.028L164.907 236.521L165.817 240.998Z"
        fill="black"
      />
      <path
        d="M178.809 244.098L174.802 233.173L181.395 230.755L181.826 231.928L176.555 233.861L177.909 237.553L182.838 235.745L183.269 236.918L178.34 238.726L179.702 242.439L185.057 240.475L185.488 241.648L178.809 244.098Z"
        fill="black"
      />
      <path
        d="M191.064 239.424L189.772 240.141L187.856 227.894L189.128 227.188L198.514 235.285L197.223 236.002L194.599 233.69L190.487 235.975L191.064 239.424ZM190.267 234.667L193.605 232.813L189.426 229.128L189.346 229.172L190.267 234.667Z"
        fill="black"
      />
      <path
        d="M203.203 232.467L195.978 223.345L197.083 222.47L200.667 226.995L200.774 226.911L201.287 219.14L202.73 217.997L202.159 225.146L209.955 227.119L208.619 228.177L202.089 226.449L201.965 228.634L204.308 231.592L203.203 232.467Z"
        fill="black"
      />
      <path
        d="M214.461 223.205L213.46 224.291L207.8 213.263L208.785 212.193L220.237 216.938L219.236 218.024L216.02 216.651L212.831 220.111L214.461 223.205ZM212.212 218.938L214.8 216.13L209.677 213.942L209.615 214.009L212.212 218.938Z"
        fill="black"
      />
      <path
        d="M224.286 212.118L212.732 208.647L213.491 207.433L222.816 210.396L222.877 210.299L216.154 203.175L216.997 201.826L226.347 204.749L226.407 204.653L219.66 197.567L220.419 196.353L228.599 205.219L227.828 206.453L218.604 203.715L218.556 203.792L225.057 210.885L224.286 212.118Z"
        fill="black"
      />
      <path
        d="M233.148 196.874L232.608 198.249L223.334 190.025L223.864 188.67L236.258 188.939L235.719 190.314L232.223 190.201L230.506 194.581L233.148 196.874ZM229.504 193.713L230.898 190.158L225.33 189.977L225.296 190.062L229.504 193.713Z"
        fill="black"
      />
      <path
        d="M226.009 183.704L226.357 182.128L232.357 180.147L232.386 180.014L227.777 175.692L228.125 174.117L233.899 179.697L238.582 180.73L238.278 182.106L233.596 181.073L226.009 183.704Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2605_102598"
          x1="159.55"
          y1="26.1278"
          x2="46.7461"
          y2="143.663"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF36A4" />
          <stop offset="1" stopColor="#FFC2E4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2605_102598"
          x1="246.522"
          y1="112.019"
          x2="210.311"
          y2="-14.4541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8400" />
          <stop offset="1" stopColor="#FFCEA1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2605_102598"
          x1="271.915"
          y1="117.932"
          x2="133.478"
          y2="247.855"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEF39B" />
          <stop offset="1" stopColor="#FECF12" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2605_102598"
          x1="6.29817"
          y1="127.838"
          x2="121.238"
          y2="272.389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#29D4FF" />
          <stop offset="1" stopColor="#A7EDFF" />
        </linearGradient>
      </defs>
    </motion.svg>
  );
};
