import { Trans } from "@lingui/macro";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { InfoIcon } from "@WahooFitness/redesignr";

type Props = {
  icon?: JSX.Element;
  name?: string;
  handleEstimateClick?: () => void;
};
function ResultsHeader({ icon, name, handleEstimateClick }: Props) {
  return (
    <Stack direction="column" alignItems="center" px={2} width="100%" gap={1.2} pt={4}>
      <Typography variant="ui-xl-medium">
        <Trans>Wahoo!</Trans>
      </Typography>
      <Typography variant="ui-md-medium">
        <Trans>We estimated your Athlete Profile as: </Trans>
      </Typography>

      <Stack direction="column" alignItems="center" spacing={1.5} pt={4}>
        <Box width={64} height={64} ml={1.5}>
          {icon}
        </Box>
        <Typography variant="ui-4xl-bold">{name}</Typography>

        <Chip
          label={
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography variant="prose-xs-medium" color="text.primary">
                <Trans>Estimate</Trans>
              </Typography>
              <InfoIcon fontSize="small" color="primary" />
            </Stack>
          }
          size="small"
          sx={{
            borderRadius: 0.5,
            backgroundColor: "action.selected",
            color: "text.primary",
            maxWidth: "fit-content",
          }}
          clickable
          onClick={handleEstimateClick}
        />
      </Stack>
    </Stack>
  );
}

export default ResultsHeader;
