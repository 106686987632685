import { t } from "@lingui/macro";
import ProfileCard from "./ProfileCard";
import FitnessAssessment from "@/assets/FitnessAssessment.png";
import useFitnessAssessmentClickHandler from "@/hooks/useFitnessAssessmentClickHandler";

function FitnessAssessmentCard() {
  const { handleFitnessTestClick } = useFitnessAssessmentClickHandler();
  return (
    <ProfileCard
      icon={FitnessAssessment}
      title={t`Fitness Assessment`}
      tag={t`Most accurate`}
      time={t`59 min`}
      buttonText={t`View workout`}
      description={t`A workout designed to measure your power in different intensity and time to precisely identify all 4 metrics.`}
      onClick={handleFitnessTestClick}
    />
  );
}

export default FitnessAssessmentCard;
