import { t, Trans } from "@lingui/macro";
import { Box, Typography, useTheme } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import PlanCard from "./PlanCard";
import routesImage from "@/assets/Routes.png";
import trainingPlansImage from "@/assets/TrainingPlans.png";
import curatedRouteImageDark from "@/assets/CuratedRoute-dark.png";
import curatedRouteImageLight from "@/assets/CuratedRoute-light.png";
import TheShovelPlanGraph from "./WorkoutGraphs/TheShovelPlanGraph";
import HalfHourOfPowerPlanGraph from "./WorkoutGraphs/HalfHourOfPowerPlanGraph";
import EqualizerPlanGraph from "./WorkoutGraphs/EqualizerPlanGraph";
import ButterPlanGraph from "./WorkoutGraphs/ButterPlanGraph";
import AVeryDarkPlacePlanGraph from "./WorkoutGraphs/AVeryDarkPlacePlanGraph";
import TrainingPlansDialog from "./TrainingPlansDialog/TrainingPlansDialog";
import { postRoute } from "@WahooFitness/wahoo-offline-mfe";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";
import { MenuList, MenuListItemType, MenuListItemVariant } from "@WahooFitness/redesignr";
import { WatsonHealth3DCurveAutoColon } from "@carbon/icons-react";
import { GlobalHeader } from "../GlobalHeader";
import { ReactRouterLinkWrapper } from "../MenuList/ReactRouterLinkWrapper";

const PlanLanding = () => {
  const goHome = useCallback(() => postRoute("home"), []);

  const [trainingPlansDialogIsOpen, setTrainingPlansDialogIsOpen] = useState(false);

  const handleCloseTrainingPlansDialog = useCallback(() => {
    setTrainingPlansDialogIsOpen(false);
  }, []);

  const { checkIsFeatureEnabled } = useFlaggedFeatures();

  const curatedRoutesEnabled = useMemo(
    () => checkIsFeatureEnabled(FlaggedFeature.CuratedRoutes),
    [checkIsFeatureEnabled]
  );

  const { palette } = useTheme();

  const myLibraryListItems = useMemo(() => {
    return [
      {
        id: "myRoutes",
        content: t`My routes`,
        variant: MenuListItemVariant.InternalLink,
        linkLocation: "/routes",
        linkComponent: ReactRouterLinkWrapper,
        icon: <WatsonHealth3DCurveAutoColon size={24} color={palette.info.main} />,
      } as MenuListItemType,
    ];
  }, [palette.info.main]);

  const routeCard = useMemo(
    () =>
      curatedRoutesEnabled ? (
        <PlanCard
          title={t`Routes by Wahoo`}
          description={t`Our favorite routes to simulate indoors on a KICKR.`}
          imageElement={
            <img
              src={palette.mode === "dark" ? curatedRouteImageDark : curatedRouteImageLight}
              height={200}
              width="auto"
            />
          }
          linkLocation={"/routes/curated"}
          linkType="internal"
        />
      ) : (
        <PlanCard
          title={t`Routes`}
          description={t`Add routes and navigate with ease on ELEMNT.`}
          imageElement={
            <img
              src={routesImage}
              height={269}
              width="auto"
              style={{ transform: "translateY(27px)" }}
            />
          }
          linkLocation={"/routes"}
          linkType="internal"
        />
      ),
    [curatedRoutesEnabled, palette.mode]
  );

  return (
    <>
      <GlobalHeader title={t`Plan`} backAction={goHome} disableBackButton />
      <Box display="flex" flexDirection="column" p={2}>
        {curatedRoutesEnabled && (
          <Box pb={4}>
            <Typography variant="prose-lg-bold">
              <Trans>My Library</Trans>
            </Typography>
            <MenuList listItems={myLibraryListItems} disableGutters />
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={2}>
          {curatedRoutesEnabled && (
            <Typography variant="prose-lg-bold">
              <Trans>Explore</Trans>
            </Typography>
          )}
          <PlanCard
            title={t`Workouts`}
            description={t`Developed by world-class coaches to help you reach your goals.`}
            imageElement={
              <Box display="flex" flexDirection="column" gap={1} bgcolor="background.default">
                <TheShovelPlanGraph />
                <AVeryDarkPlacePlanGraph />
                <ButterPlanGraph />
                <EqualizerPlanGraph />
                <HalfHourOfPowerPlanGraph />
              </Box>
            }
            linkLocation={"/workouts"}
            linkType="internal"
          />
          {routeCard}
          <PlanCard
            title={t`Training Plans`}
            description={t`Tailored to your goals and lifestyle in the Wahoo SYSTM app.`}
            imageElement={<img src={trainingPlansImage} height={200} width="auto" />}
            onClick={() => setTrainingPlansDialogIsOpen(true)}
          />
        </Box>
        <TrainingPlansDialog
          open={trainingPlansDialogIsOpen}
          onClose={handleCloseTrainingPlansDialog}
        />
      </Box>
    </>
  );
};

export default PlanLanding;
