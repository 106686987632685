import { t } from "@lingui/macro";
import { Box } from "@mui/material";
import useNavUpOnDeviceDisconnect from "../useNavUpOnDeviceDisconnect";
import UpdateMapsEmbed from "./UpdateMapsEmbed";
import { GlobalHeader } from "@/components/GlobalHeader";

const UpdateMaps = ({ appToken = "" }: { appToken: string }) => {
  useNavUpOnDeviceDisconnect(appToken);

  return (
    <>
      <GlobalHeader title={t`Update maps`} />
      <Box width="100%" height="100%" display="flex" flexDirection="column" p={2} gap={4}>
        <UpdateMapsEmbed appToken={appToken} />
      </Box>
    </>
  );
};

export default UpdateMaps;
