import { Trans } from "@lingui/macro";
import { Stack, Typography } from "@mui/material";

const ProfileDescription = ({
  hasNoCyclingFitnessProfile,
  profileDescription,
}: {
  hasNoCyclingFitnessProfile: boolean;
  profileDescription: string;
}) => {
  return (
    <Stack spacing={1.5} mt={1} maxWidth="md" px={2} mb={2.5}>
      {hasNoCyclingFitnessProfile && (
        <Typography variant="ui-md-bold" color="text.primary">
          <Trans>You don&apos;t have an Athlete Profile yet</Trans>
        </Typography>
      )}
      <Typography
        variant="prose-base"
        color={hasNoCyclingFitnessProfile ? "text.secondary" : "text.primary"}
      >
        {profileDescription}
      </Typography>
    </Stack>
  );
};

export default ProfileDescription;
