export enum EventType {
  click = "click",
  dismiss = "dismiss",
}

export enum ClickTarget {
  continue = "continue_button",
  save = "save_button",
  discard_results = "discard_results_button",
  see_results = "see_results_button",
  back = "back_button",
  how_we_calculate = "how_we_calculate_button",
  start_quiz = "start_quiz",
  athlete_profile = "athlete_profile",
  fitness_assessment = "fitness_assessment",
  rider_type_mismatch_alert = "rider_type_mismatch_alert",
  estimate_chip = "estimate_chip",
  next = "next_button",
  start_questionnaire = "start_questionnaire",
  question_icon = "question_icon",
  close = "close_button",
}

export enum SourcePage {
  fitness_profile = "fitness_profile",
  fourdp_quiz = "fourdp_quiz",
  fourdp_quiz_results = "fourdp_quiz_results",
  fourdp_quiz_powerzones = "fourdp_quiz_powerzones",
  settings = "settings",
  fourdp_education = "fourdp_education",
}

export type Event = {
  event_type: EventType;
  click_target?: ClickTarget;
  source_page_name: SourcePage;
  component_app_id: number;
  fitness_app_id: number;
};

export type StepperEvent = Event & {
  step_name?: string;
  step_ordering: number;
};

export type AnyEvent = StepperEvent;
