import { useHeaderContext } from "@/hooks/useHeaderContext";
import { useEffect, useMemo } from "react";

type GlobalHeaderType = {
  title?: string | JSX.Element;
  backAction?: () => void;
  headerAction?: React.ReactElement;
  hasOwnHeader?: boolean;
  showCloseIcon?: boolean;
  backText?: string;
  maxTitleWidths?: Array<{
    mediaQuery: string;
    maxWidth: string | number;
  }>;
  isTransparent?: boolean;
  isFixed?: boolean;
  disableBackButton?: boolean;
  disableBoxShadow?: boolean;
};

export const GlobalHeader: React.FC<GlobalHeaderType> = (globalHeaderProps: GlobalHeaderType) => {
  const { setNavHeader } = useHeaderContext();
  const headerProps = useMemo(() => {
    return globalHeaderProps;
  }, [globalHeaderProps]);

  useEffect(() => {
    setNavHeader(headerProps);
  });

  return null;
};
