import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { formatISO } from "date-fns";
import {
  AnswerListType,
  AnswerRangeType,
  FourDPQuizResponseType,
  FourDPQuizResultsType,
  FourDPQuizType,
  ImperialMetricDisplayPreference,
  QuestionType,
  SelectionMechanism,
  UserType,
} from "@WahooFitness/cloud-client-types";
import DateOfBirthInput from "../User/AboutYou/DateOfBirthInput";
import { OnboardingWeightInput } from "../AthleteOnboarding/StepComponents/OnboardingWeightInput";
import { OnboardingHeightInput } from "../AthleteOnboarding/StepComponents/OnboardingHeightInput";
import { StepContent } from "../AthleteOnboarding/CustomStepper";
import useAboutYou from "../User/AboutYou/useAboutYou";
import NumericEntry from "./NumericEntry/NumericEntry";
import SingleSelect from "./SingleSelect/SingleSelect";
import MultipleSelect from "./MultiSelect/MultipleSelect";
import Ranking from "./Ranking/Ranking";
import useFourDPQuizClient from "./useFourDPQuizClient";
import FourDPPages from "./FourDPPages";
import { useLogEvent } from "@/services/Analytics/useLogEvent";
import { ClickTarget, SourcePage } from "@/services/Analytics/EventTypes";
import { t } from "@lingui/macro";
import { useHeaderContext } from "@/hooks/useHeaderContext";

export type Responses = Record<string, Array<string | Date | number | null>>;

function useFourDPQuiz(
  user: UserType,
  setResults: Dispatch<SetStateAction<FourDPQuizResultsType | undefined>>,
  setPage: Dispatch<SetStateAction<number>>,
  setError: Dispatch<SetStateAction<string | undefined>>,
  skipEducation: boolean
) {
  const { navigateBack } = useHeaderContext();
  const { updateUserField } = useAboutYou(user);
  const [activeStep, setActiveStep] = useState(0);
  const [currentStepValid, setCurrentStepValid] = useState(true);
  const [currentStepHasValue, setCurrentStepHasValue] = useState(false);
  const [userUnitPreference, setUserUnitPreference] = useState(
    user.display_preference?.speed_distance ?? ImperialMetricDisplayPreference.metric
  );
  const { quizData, quizLoading, quizError, getQuizResults } = useFourDPQuizClient();
  const { logEvent } = useLogEvent();
  const buttonName =
    activeStep === (quizData?.questions.length || 0) - 1 ? t`See results` : t`Continue`;

  /**
   * Pre-populate the user profile questions (age, height, weight, sex) with the values that are in cloud
   */
  const setInitialResponses = useCallback(
    (quizData: FourDPQuizType) => {
      return quizData.questions.reduce((acc, cur) => {
        switch (cur.answers.measurement) {
          case "dob":
            if (user.birth) {
              acc[cur.question_id] = [new Date(user.birth + "T00:00:00")];
            }
            break;
          case "weight":
            if (user.weight) {
              acc[cur.question_id] = [user.weight];
            }
            break;
          case "height":
            if (user.height) {
              acc[cur.question_id] = [user.height];
            }
            break;
          case "sex":
            if (user.gender !== undefined && user.gender !== null) {
              acc[cur.question_id] = [user.gender];
            }
            break;
          case "no_measurement":
          default:
            if (cur.answers.selection_mechanism === SelectionMechanism.exactly_all_ordered) {
              acc[cur.question_id] = (cur.answers as AnswerListType).possibility_list.map(
                (i) => i.key
              );
            }
        }
        return acc;
      }, {} as Responses);
    },
    [user.birth, user.gender, user.height, user.weight]
  );

  const [responses, setResponses] = useState<Responses>();

  useEffect(() => {
    if (!quizLoading && !quizError && quizData) {
      setResponses(setInitialResponses(quizData));
    }
  }, [quizLoading, quizError, quizData, setInitialResponses]);

  /**
   * Turns a questionnaire question into a step for the stepper
   * if the question has a `measurement` then we use a custom component
   * otherwise we look at the `selectionMechanism` to determine which component to render
   * @param question
   * @returns StepContent
   */
  const quizToStep = useCallback(
    (question: QuestionType): StepContent => {
      let Component = (
        <SingleSelect
          key={question.question_id}
          options={[]}
          initialVal={responses?.[question.question_id]?.[0] as string}
          setCurrentStepHasValue={setCurrentStepHasValue}
          setResponses={setResponses}
          questionId={question.question_id}
        />
      );
      let onContinue = () => {};
      switch (question.answers.measurement) {
        case "dob":
          Component = (
            <DateOfBirthInput
              textFieldOnlyPicker
              value={
                responses?.[question?.question_id]?.[0]
                  ? new Date(responses[question.question_id][0]!)
                  : null
              }
              onChange={(value: Date | null) => {
                setCurrentStepHasValue(!!value);
                setResponses((prev) => {
                  if (value && prev) {
                    prev[question.question_id] = [value];
                  }
                  return prev;
                });
              }}
            />
          );
          onContinue = () => {
            if (responses?.[question.question_id]) {
              updateUserField(
                formatISO(responses[question.question_id][0] as Date, { representation: "date" }),
                "birth"
              );
            }
          };
          break;
        case "weight":
          Component = (
            <OnboardingWeightInput
              userWeight={Number(responses?.[question.question_id])}
              isWeightValid={currentStepValid}
              setIsWeightValid={setCurrentStepValid}
              userUnitPreference={userUnitPreference}
              setUserUnitPreference={setUserUnitPreference}
              setUserWeight={(weight: string) => {
                setCurrentStepHasValue(!!weight);
                setResponses((prev) => {
                  if (prev) {
                    prev[question.question_id] = [+weight];
                  }
                  return prev;
                });
              }}
            />
          );
          onContinue = () => {
            updateUserField(responses?.[question.question_id][0], "weight");
            updateUserField(userUnitPreference, "metric_weight");
          };
          break;
        case "height":
          Component = (
            <OnboardingHeightInput
              setUserHeight={(height: string | null) => {
                setCurrentStepHasValue(!!height);
                setResponses((prev) => {
                  if (height && prev) {
                    prev[question.question_id] = [+height];
                  }
                  return prev;
                });
              }}
              userHeight={String(responses?.[question.question_id])}
              userHeightUnit={userUnitPreference}
              setUserHeightUnit={setUserUnitPreference}
              setIsHeightValid={setCurrentStepValid}
            />
          );
          onContinue = () => {
            updateUserField(responses?.[question.question_id][0], "height");
            updateUserField(userUnitPreference, "metric_speed_distance");
          };
          break;
        case "sex":
          Component = (
            <SingleSelect
              key={question.question_id}
              initialVal={responses?.[question.question_id]?.[0] as string}
              setCurrentStepHasValue={setCurrentStepHasValue}
              options={(question.answers as AnswerListType).possibility_list}
              setResponses={setResponses}
              skipText={question.answers.skip_answer_text}
              questionId={question.question_id}
              center
            />
          );
          onContinue = () => {
            updateUserField(responses?.[question.question_id][0], "gender");
          };
          break;
        case "no_measurement":
        default:
          if (question.answers.selection_mechanism === SelectionMechanism.numeric_entry) {
            const possibilities = (question.answers as AnswerRangeType).possibility_range;
            Component = (
              <NumericEntry
                key={question.question_id}
                min={possibilities.start}
                max={possibilities.stop}
                skipText={question.answers.skip_answer_text}
                initialVal={responses?.[question.question_id]?.[0] as number}
                setCurrentStepValid={setCurrentStepValid}
                setCurrentStepHasValue={setCurrentStepHasValue}
                setResponses={setResponses}
                questionId={question.question_id}
              />
            );
          } else if (question.answers.selection_mechanism === SelectionMechanism.exactly_one) {
            const possibilities = (question.answers as AnswerListType).possibility_list;
            Component = (
              <SingleSelect
                key={question.question_id}
                initialVal={responses?.[question.question_id]?.[0] as string}
                setCurrentStepHasValue={setCurrentStepHasValue}
                options={possibilities}
                setResponses={setResponses}
                skipText={question.answers.skip_answer_text}
                questionId={question.question_id}
              />
            );
          } else if (question.answers.selection_mechanism === SelectionMechanism.at_least_one) {
            const possibilities = (question.answers as AnswerListType).possibility_list;
            Component = (
              <MultipleSelect
                key={question.question_id}
                initialVal={responses?.[question.question_id] as Array<string>}
                setCurrentStepHasValue={setCurrentStepHasValue}
                options={possibilities}
                setResponses={setResponses}
                skipText={question.answers.skip_answer_text}
                questionId={question.question_id}
              />
            );
          } else if (
            question.answers.selection_mechanism === SelectionMechanism.exactly_all_ordered
          ) {
            const possibilities = (question.answers as AnswerListType).possibility_list;
            Component = (
              <Ranking
                key={question.question_id}
                initialVal={responses?.[question.question_id] as Array<string>}
                options={possibilities}
                setResponses={setResponses}
                skipText={question.answers.skip_answer_text}
                questionId={question.question_id}
              />
            );
          }
      }
      return {
        stepId: question.question_id,
        stepName: question.title,
        headline: question.title,
        subheader: question.subtext,
        component: Component,
        onContinue,
        disabled: !responses?.[question.question_id] || !currentStepValid,
      };
    },
    [currentStepValid, responses, updateUserField, userUnitPreference]
  );

  const steps = useMemo(() => {
    if (quizData && JSON.stringify(responses)) {
      return quizData.questions.map(quizToStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizData, quizToStep, JSON.stringify(responses)]);

  const sanitizeResponses = useCallback(
    (responses: Record<string, Array<string | Date | number>>) => {
      return quizData?.questions.reduce(
        (acc, cur) => {
          if (responses[cur.question_id][0] === "skip") {
            return acc;
          }
          switch (cur.answers.measurement) {
            case "dob":
              acc[String(cur.question_id)] = [
                (responses[cur.question_id][0] as Date).toISOString().replace(/T.*Z/, ""),
              ];
              return acc;
            case "weight":
            case "height":
            case "sex":
              acc[String(cur.question_id)] = [+responses[cur.question_id][0]];
              return acc;
            case "no_measurement":
            default:
              acc[String(cur.question_id)] = responses[cur.question_id];
              return acc;
          }
        },
        {} as FourDPQuizResponseType["answers"]
      );
    },
    [quizData?.questions]
  );

  const logFourDpQuizEvent = useCallback(
    (clickTarget: ClickTarget) => {
      logEvent("4dp_quiz", {
        click_target: clickTarget,
        source_page_name: SourcePage.fourdp_quiz,
        step_name: steps?.[activeStep]?.stepName ?? undefined,
        step_ordering: activeStep + 1,
        questionnaire_id: quizData?.questionnaire_id,
      });
    },
    [activeStep, logEvent, quizData?.questionnaire_id, steps]
  );

  const submitQuiz = useCallback(async () => {
    if (responses) {
      const sanitizedResponses = sanitizeResponses(
        responses as Record<string, Array<string | Date | number>>
      );
      const responseObject: FourDPQuizResponseType = {
        questionnaire_id: quizData?.questionnaire_id || "",
        answers: sanitizedResponses || {},
        language_code: "en",
        user_id: user.id,
      };
      const response = await getQuizResults(responseObject);
      if (response.error) {
        setError(response.error);
      }
      if (response.results) {
        setResults(response.results);
      }
    }
  }, [
    getQuizResults,
    quizData?.questionnaire_id,
    responses,
    sanitizeResponses,
    setError,
    setResults,
    user.id,
  ]);

  const handleNext = useCallback(async () => {
    if (steps?.[activeStep].onContinue) {
      logFourDpQuizEvent(ClickTarget.continue);
      steps[activeStep].onContinue!();
    }
    if (steps?.length && activeStep === steps.length - 1) {
      logFourDpQuizEvent(ClickTarget.see_results);
      submitQuiz();
      setPage(FourDPPages.results);
    } else {
      logFourDpQuizEvent(ClickTarget.continue);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [activeStep, logFourDpQuizEvent, setPage, steps, submitQuiz]);

  const handleBack = useCallback(() => {
    logFourDpQuizEvent(ClickTarget.back);
    // TODO: This doesn't get called if user does a back swipe on iOS
    if (activeStep === 0) {
      skipEducation ? navigateBack() : setPage(FourDPPages.education);
    } else {
      setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
    }
  }, [activeStep, logFourDpQuizEvent, navigateBack, setPage, skipEducation]);

  useEffect(() => {
    setCurrentStepHasValue(!!responses?.[activeStep]);
  }, [responses, activeStep]);

  return {
    activeStep,
    steps,
    handleNext,
    handleBack,
    currentStepHasValue,
    currentStepValid,
    responses,
    quizLoading,
    quizError,
    submitQuiz,
    buttonName,
  };
}

export default useFourDPQuiz;
