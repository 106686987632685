import GlobalDialog from "@/components/GlobalDialog";
import GlobalDrawer from "@/components/GlobalDrawer";
import GlobalSnackbar from "@/components/GlobalSnackbar";
import Header from "@/components/Header";
import {
  useConfigContext,
  useNativeMessagingContext,
  useUserContext,
  hasMinProfile,
} from "@WahooFitness/wahoo-offline-mfe";
import { Box, Container } from "@mui/material";
import { useEffect, useLayoutEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router";
import { AnimatedOutlet } from "@/components/Settings/AnimatedOutlet";
import ErrorBoundary from "@/components/ErrorBoundary";
import { Profile } from "@WahooFitness/cloud-client-types";
import { HeaderContextProvider } from "@/contexts/HeaderContext";

function RouteWrapper() {
  const { globalBottomPadding } = useConfigContext();
  const { currentUser } = useUserContext();

  // This let's the native chassis change the page
  const { setOnNavigation } = useNativeMessagingContext();
  const navigate = useNavigate();
  useEffect(() => {
    setOnNavigation(() => navigate);
  }, [navigate, setOnNavigation]);

  const ref = useRef<HTMLElement>();
  const location = useLocation();
  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    ref.current?.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0, interactive-widget=resizes-content"
          // interactive-widget=resizes-content pushes buttons at the bottom up when a soft keyboard is shown; probably works just on Android
        />
      </Helmet>

      <Box
        display="flex"
        flexDirection="column"
        height="100dvh"
        overflow="hidden"
        bgcolor="background.default"
      >
        <ErrorBoundary showError={hasMinProfile(Profile.alpha, currentUser?.app?.profile)}>
          <HeaderContextProvider>
            <Header />
            <Box
              overflow="auto"
              display="flex"
              flexDirection="column"
              flexGrow={1}
              flexShrink={1}
              paddingBottom={globalBottomPadding}
              ref={ref}
            >
              <Container maxWidth="sm" disableGutters sx={{ height: "100%" }}>
                <AnimatedOutlet />
              </Container>
            </Box>
          </HeaderContextProvider>
        </ErrorBoundary>
      </Box>
      <GlobalDialog />
      <GlobalSnackbar />
      <GlobalDrawer />
    </>
  );
}

export default RouteWrapper;
