import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Trans } from "@lingui/macro";
import { Close, Wifi } from "@carbon/icons-react";
import { alpha, Avatar, Link, Typography, useTheme } from "@mui/material";
import WifiNetworks from "../Wifi/WifiNetworks";
import { postClose } from "@WahooFitness/wahoo-offline-mfe";
import usePairedDevice from "@/hooks/usePairedDevice";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "@/components/ErrorBoundary";
import { useBCFirstRunState } from "@/hooks/useBCFirstRunState";
import { WSMBoltAppFirstRunState } from "@WahooFitness/wsm-native/dist/esm/types/bolt_cfg";
import { useSettingsRemoteConfig } from "@/hooks/useSettingsRemoteConfig";

interface WifiOnboardingProps {
  appToken: string;
}

const WifiOnboarding: React.FC<WifiOnboardingProps> = ({ appToken }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { getSettingsRemoteConfigString } = useSettingsRemoteConfig();
  const [connected, setConnected] = useState(false);
  const wifiStatusCallback = useCallback((connected: boolean) => {
    setConnected(connected);
  }, []);

  const wifiRequirementsUrl = useMemo(
    () => getSettingsRemoteConfigString("ELEMNT_WIFI_REQUIREMENTS_URL"),
    [getSettingsRemoteConfigString]
  );

  const { device, isDeviceConnected, deviceError, reloadDevice } = usePairedDevice(appToken);
  const computerName = useMemo(() => device?.productTypeName, [device]);

  const { setStateTransition } = useBCFirstRunState(appToken);
  useEffect(() => {
    setStateTransition({
      desiredState: WSMBoltAppFirstRunState.AppSetupWifi,
      allowedStates: [WSMBoltAppFirstRunState.Complete],
    });
  }, [setStateTransition]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
    >
      <ErrorBoundary isLoading={!isDeviceConnected} error={deviceError} onReload={reloadDevice}>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          overflow="hidden"
        >
          <Box display="flex" justifyContent="flex-end" width="100%" p={2}>
            <Close color={palette.text.primary} size={24} onClick={() => postClose("onboarding")} />
          </Box>
          <Box overflow="scroll">
            <Box width="100%" px={2} display="flex" flexDirection="column" alignItems="center">
              <Avatar
                sx={{ width: "76px", height: "76px", bgcolor: alpha(palette.info.main, 0.1) }}
              >
                <Wifi color={palette.info.main} size={56} />
              </Avatar>
              <Typography variant="prose-lg-bold" mt={2} px={2} textAlign="center">
                <Trans>Connect {computerName} to Wi-Fi</Trans>
              </Typography>
              <Typography variant="prose-sm" mt={2} textAlign="center">
                <Trans>
                  Stay connected to your Wi-Fi network for automatic updates and faster map
                  downloads.
                </Trans>
              </Typography>
            </Box>
            <WifiNetworks appToken={appToken} wifiStatusCallback={wifiStatusCallback} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="100%" p={2}>
          {!connected && (
            <>
              <Typography variant="prose-xs-medium" textAlign="center">
                <Trans>Don&apos;t see your Wi-Fi?</Trans>
              </Typography>
              <Typography
                variant="prose-xs-medium"
                textAlign="center"
                sx={{ pb: 1 }}
                color={palette.info.main}
              >
                <Link href={wifiRequirementsUrl} target="_blank">
                  <Trans>Learn more about network requirements for {computerName}.</Trans>
                </Link>
              </Typography>
            </>
          )}
          <Button
            fullWidth
            variant="contained"
            size="large"
            disabled={!connected}
            onClick={() => navigate("../firmware")}
          >
            <Trans>Continue</Trans>
          </Button>
        </Box>
      </ErrorBoundary>
    </Box>
  );
};

export { WifiOnboarding };
