import { Box, Typography, useTheme } from "@mui/material";
import { motion } from "motion/react";
import { EducationStepContent } from "./useFourDPEducation";

const variants = {
  hidden: { opacity: 0 },
  inactive: { opacity: 0.2, y: 0 },
  active: { opacity: 1, y: 0 },
};

function AnimatedFourDPGraphComponent({
  steps,
  activeStep,
}: {
  steps: EducationStepContent[];
  activeStep: number;
}) {
  const { palette } = useTheme();

  const getState = (currentStep: number, targetStep: number) => {
    if (currentStep === targetStep) return "active";
    if (currentStep > targetStep) return "inactive";
    return "hidden";
  };

  return (
    <Box
      component={motion.div}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      <Box width="100%" position="relative" height="100%">
        <Box
          width="100%"
          position="absolute"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          marginTop={1}
        >
          {steps
            .filter((s) => s.power)
            .map((s) => (
              <Box
                key={s.stepId}
                borderBottom={
                  getState(activeStep, s.stepId) === "active" ||
                  getState(activeStep, s.stepId) === "inactive"
                    ? `1px dashed ${palette.text.disabled}`
                    : "none"
                }
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                initial="hidden"
                component={motion.div}
                variants={variants}
                animate={getState(activeStep, s.stepId)}
                marginBottom={s.stepId === 3 ? 0 : 5}
              >
                <Typography
                  key={s.stepId}
                  variant={getState(activeStep, s.stepId) === "active" ? "ui-sm-bold" : "prose-xs"}
                >
                  {s.power?.toUpperCase()}
                </Typography>
                {s.endAdornment}
              </Box>
            ))}
        </Box>

        <motion.svg
          width="100%"
          height="100%"
          viewBox="0 0 356 302"
          fill="none"
          xmlns="http://www.w3.org/2000/motion.svg"
        >
          {steps.filter((steps) => steps.component).map((step) => ({ ...step.component }))}
        </motion.svg>
      </Box>
    </Box>
  );
}

export default AnimatedFourDPGraphComponent;
