import { SetNavHeaderType } from "@/components/Header";
import { createContext, useState } from "react";
import { useNavigate } from "react-router";

interface IHeaderContextProps {
  setSetNavHeader: (setNavHeader: SetNavHeaderType) => void;
  setNavHeader: SetNavHeaderType;
  // We need to use this instead of navigate(-1) to control when page animations happen
  navigateBack: () => void;
  setBackButtonPressedListener: (backButtonPressed: (wentBack: boolean) => void) => void;
}

export const HeaderContext = createContext<IHeaderContextProps | undefined>(undefined);

export const HeaderContextProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [setNavHeader, setSetNavHeader] = useState<SetNavHeaderType>(() => () => {});
  const [backButtonPressedListener, setBackButtonPressedListener] = useState<
    (backButtonPressed: boolean) => void
  >(() => () => {});
  const navigateBack = () => {
    // Set a state when the user presses back button in the app
    navigate(-1);
    backButtonPressedListener(true);
  };
  return (
    <HeaderContext.Provider
      value={{
        setNavHeader,
        setSetNavHeader,
        navigateBack,
        setBackButtonPressedListener,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
