import FourDPEducation from "@/components/4dpEducation/FourDPEducation";
import { useSearchParams } from "react-router-dom";

const FourDPEducationPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const isStandAlone = searchParams.has("standAlone");
  return <FourDPEducation isStandAlone={isStandAlone} />;
};

export default FourDPEducationPage;
