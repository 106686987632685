import { useCallback, useState, KeyboardEvent, useRef, useMemo, useEffect } from "react";
import { Alert, Box } from "@mui/material";
import { WahooWorkoutType, WorkoutProfileResponseType } from "@WahooFitness/cloud-client-types";
import CustomizeProfileSection from "./CustomizeProfileSection";
import useEditWorkoutProfile from "./useEditWorkoutProfile";
import { useWorkoutProfilesContext } from "@/hooks/useWorkoutProfilesContext";
import { getWorkoutTypeIcon } from "@/services/getWorkoutTypeIcon";
import ZonesSection from "./ZonesSection";
import { AutoSelectTextField } from "@WahooFitness/redesignr";
import { Trans, t } from "@lingui/macro";
import { getWorkoutTypeNames } from "../AddWorkoutProfile/WorkoutTypeNames";
import Wrapper from "./EditWorkoutProfileWrapper";
import WorkoutSettingsSection from "./WorkoutSettingsSection";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDialogContext, useNativeMessagingContext } from "@WahooFitness/wahoo-offline-mfe";
import ElemntSection from "./ElemntSection";
import SensorsSection from "./SensorsSection";
import useFlaggedFeatures from "@/hooks/useFlaggedFeatures";
import useAssociatedDevices from "@/hooks/useAssociatedDevices";
import { FlaggedFeature } from "@/hooks/types/FlaggedFeature";
import { GlobalHeader } from "@/components/GlobalHeader";

interface Props {
  title?: string;
  profile?: WorkoutProfileResponseType;
  backAction?: () => void;
  headerAction?: React.ReactElement;
  hasOwnHeader?: boolean;
  warningDialogOpen?: boolean;
  onDiscardWorkoutProfile?: () => void;
  onContinueEditing?: () => void;
}

const EditWorkoutProfile = ({
  title,
  profile,
  backAction,
  headerAction,
  hasOwnHeader,
  warningDialogOpen,
  onDiscardWorkoutProfile,
  onContinueEditing,
}: Props) => {
  const [searchParams] = useSearchParams();
  const { workoutProfileNames } = useWorkoutProfilesContext();
  const profileNameRef = useRef<HTMLInputElement>(null);
  const { handleNameChange } = useEditWorkoutProfile(profile);
  const [nameError, setNameError] = useState<string>();

  const workoutTypeNames = getWorkoutTypeNames();
  const workoutTypeName =
    profile?.workout_type_id !== undefined
      ? workoutTypeNames[WahooWorkoutType[profile?.workout_type_id]]
      : "";

  const checkValid = useCallback(() => {
    const newValue = profileNameRef.current?.value.trim();
    if (!newValue) {
      setNameError(t`profile name cannot be blank`);
    } else if (
      profile?.name !== newValue &&
      workoutProfileNames
        .map((name) => name.toLocaleLowerCase())
        .includes(newValue.toLocaleLowerCase())
    ) {
      setNameError(t`name already in use`);
    } else {
      setNameError(undefined);
    }
  }, [profile?.name, workoutProfileNames]);

  const handleNameBlur = useCallback(() => {
    const newValue = profileNameRef.current?.value.trim();
    if (!nameError && newValue) {
      handleNameChange(newValue);
    }
  }, [handleNameChange, nameError]);

  const { addBackPressListener, removeBackPressListener } = useNativeMessagingContext();
  const navigate = useNavigate();

  useEffect(() => {
    const handleNativeBack = () => {
      // Only happens on Android
      handleNameBlur();
      backAction?.();
      navigate(-1);
    };
    addBackPressListener(handleNativeBack);
    return () => {
      removeBackPressListener();
    };
  }, [addBackPressListener, backAction, handleNameBlur, navigate, removeBackPressListener]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        handleNameBlur();
      }
      if (event.key === "Escape" && profileNameRef.current && profile?.name) {
        profileNameRef.current.value = profile.name;
        checkValid();
      }
    },
    [handleNameBlur, checkValid, profile?.name]
  );

  const icon = useMemo(() => {
    const Icon = getWorkoutTypeIcon(profile?.workout_type_id);
    return <Icon fontSize="small" />;
  }, [profile?.workout_type_id]);

  const { setDialog, handleClose } = useDialogContext();

  useEffect(() => {
    if (warningDialogOpen) {
      setDialog({
        open: true,
        title: t`Discard new workout profile?`,
        body: t`Are you sure you want to discard your new workout profile?`,
        actions: [
          { text: t`Discard`, action: onDiscardWorkoutProfile, color: "error" },
          { text: t`Continue editing`, action: onContinueEditing },
        ],
      });
    } else {
      handleClose();
    }
  }, [handleClose, onContinueEditing, onDiscardWorkoutProfile, setDialog, warningDialogOpen]);

  const { hasAssociatedElemnt } = useAssociatedDevices();

  const { checkIsFeatureEnabled, featuresAreLoading } = useFlaggedFeatures();

  return (
    <>
      <GlobalHeader
        title={title || profile?.name || ""}
        backAction={backAction}
        headerAction={headerAction}
        hasOwnHeader={hasOwnHeader}
      />
      <Wrapper hasOwnHeader={hasOwnHeader}>
        <Box display="flex" flexDirection="column" overflow="auto" mb={1}>
          <Box p={2}>
            <Alert
              severity="info"
              variant="filled"
              icon={icon}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Trans>{workoutTypeName} settings applied.</Trans>
            </Alert>
          </Box>
          {profile?.name && (
            <Box p={2}>
              <AutoSelectTextField
                inputRef={profileNameRef}
                fullWidth
                variant="filled"
                label="Workout Profile Name"
                defaultValue={profile?.name}
                onChange={checkValid}
                onBlur={handleNameBlur}
                onKeyUp={handleKeyPress}
                error={!!nameError}
                helperText={nameError}
              />
            </Box>
          )}
          {searchParams.get("dev") === "true" && <CustomizeProfileSection />}
          {hasAssociatedElemnt && <ElemntSection profile={profile} />}
          <WorkoutSettingsSection
            profile={profile}
            hasOwnHeader={hasOwnHeader}
            hasElemntPaired={
              !featuresAreLoading &&
              checkIsFeatureEnabled(FlaggedFeature.ElemntConfig) &&
              hasAssociatedElemnt
            }
          />
          <SensorsSection profile={profile} />
          <ZonesSection profile={profile} />
        </Box>
      </Wrapper>
    </>
  );
};

export default EditWorkoutProfile;
