import { Profile } from "@WahooFitness/cloud-client-types/Profile";

const FALLBACK_URL = "https://support.wahoofitness.com/";

// This is the default remote config for the settings service. This
// will get loaded in if Firebase is not reachable and there are no
// cached remote config settings.
// Make sure to add to this when you create a new remote config key.
//
// Keys where the default min profile is "std" should get removed and
// marked as deprecated in Firebase.
export const settingsDefaultRemoteConfig = {
  CURATED_ROUTES_MIN_PROFILE: Profile.alpha,
  UPDATED_PAYWALL_MIN_PROFILE: Profile.alpha,
  ELEMNT_CONFIG_MIN_PROFILE: Profile.std,
  ELEMNT_MULTISPORT_PAGE_MIN_PROFILE: Profile.alpha,
  ELEMNT_PEDAL_PAGE_MIN_PROFILE: Profile.alpha,
  ELEMNT_PLANNED_WORKOUTS_MIN_PROFILE: Profile.alpha,
  PACEZONES_MIN_PROFILE: Profile.std,
  STRAVA_LIVE_SEGMENTS_MIN_PROFILE: Profile.std,
  SUGGESTED_WORKOUTS_MIN_PROFILE: Profile.std,
  SUMMARY_AUTO_SHARE_MIN_PROFILE: Profile.std,
  WORKOUT_ALERTS_MIN_PROFILE: Profile.std,
  ELEMNT_ALERT_COUNT_WARNING_ENABLED: false,
  LIVE_TRACK_CONFIG_MIN_PROFILE: Profile.beta,
  CURATED_WORKOUTS_LIST: "[]",
  ROUTE_TO_PLAYR: Profile.alpha,
  FITNESS_TEST_ID: "",
  FEATURES_IN_DEV: "{}",
  KICKR_RUN_ONBOARDING_VIDEO: "[]",
  SUPPORTED_SHARING_APPS: "[]",
  HEART_RATE_ZONE_COUNT_CHOICES: "[5]",
  PACE_ZONE_COUNT_CHOICES: "[6, 7, 8]",
  POWER_ZONE_COUNT_CHOICES: "[6, 7, 8]",
  ROAM_1_SETUP_GUIDE_URL: FALLBACK_URL,
  ROAM_2_SETUP_GUIDE_URL: FALLBACK_URL,
  BOLT_1_SETUP_GUIDE_URL: FALLBACK_URL,
  BOLT_2_SETUP_GUIDE_URL: FALLBACK_URL,
  ACE_SETUP_GUIDE_URL: FALLBACK_URL,
  ELEMNT_WIFI_REQUIREMENTS_URL: FALLBACK_URL,
  KICKR_RUN_ONBOARDING_PLAN_GROUP_ID: "",
  SYSTM_TRAINING_PLANS_LINK: "https://systm.wahoofitness.com/training-plans",
  SUPPORTED_PARTNERS_PAGE_URL: FALLBACK_URL,
  STRAVA_LIVE_SEGMENT_SETTINGS_LEARN_MORE_URL: FALLBACK_URL,
  SUMMIT_SEGMENT_SETTINGS_CLIMB_SIZE_URL: FALLBACK_URL,
  SUMMIT_SEGMENT_SETTINGS_LEARN_MORE_URL: FALLBACK_URL,
  FOURDP_QUIZ_MIN_PROFILE_VERSION: '[{ "minProfile": "dev" }]',
  FOURDP_EDUCATION_MIN_PROFILE_MIN_VERSION: '[{ "minProfile": "dev" }]',
  FITNESS_PROFILE_MIN_PROFILE_VERSION: '[{ "minProfile": "dev" }]',
  SUBSCRIPTIONS_MIN_PROFILE_VERSION: '[{ "minProfile": "dev" }]',
};

export type SettingsRemoteConfigKey = keyof typeof settingsDefaultRemoteConfig;
