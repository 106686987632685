import { ViewFilled, ViewOffFilled } from "@carbon/icons-react";
import { t, Trans } from "@lingui/macro";
import { Button, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { WSMBoltWifiNetwork } from "@WahooFitness/wsm-native/dist/esm/types/bolt_wifi";
import React, { useCallback, useState } from "react";
import { getSecurityTypeName } from "./getSecurityTypeName";
import { WifiDialogState } from "./WifiDialogState";
import { SlidingDrawer } from "@WahooFitness/redesignr";
import { useConfigContext } from "@WahooFitness/wahoo-offline-mfe";

interface WifiDialogProps {
  dialogState: WifiDialogState;
  network: WSMBoltWifiNetwork | undefined;
  onJoinNetwork: (networkName: string, password: string, hidden?: boolean) => void;
  onForgetNetwork: (networkName: string) => void;
  onCancel: () => void;
}

const WifiDialog: React.FC<WifiDialogProps> = ({
  dialogState,
  network,
  onJoinNetwork,
  onForgetNetwork,
  onCancel,
}) => {
  const { palette } = useTheme();
  const { globalBottomPadding, platform } = useConfigContext();
  const [networkInput, setNetworkInput] = useState("");
  const [networkPassword, setNetworkPassword] = useState("");

  const [showNetworkPassword, setShowNetworkPassword] = useState(false);
  const handleNetworkNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNetworkInput(event.target.value);
  }, []);
  const handleNetworkPasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNetworkPassword(event.target.value);
  }, []);
  const handleCloseNetworkDialog = useCallback(() => {
    onCancel();
    setNetworkInput("");
    setNetworkPassword("");
    setShowNetworkPassword(false);
  }, [onCancel]);
  const handleConnectNetwork = useCallback(() => {
    if (network === undefined) {
      return;
    }
    onJoinNetwork(network.ssid, networkPassword);
    handleCloseNetworkDialog();
  }, [network, onJoinNetwork, networkPassword, handleCloseNetworkDialog]);
  const handleConnectHiddenNetwork = useCallback(() => {
    onJoinNetwork(networkInput, networkPassword, true);
    handleCloseNetworkDialog();
  }, [onJoinNetwork, networkInput, networkPassword, handleCloseNetworkDialog]);
  const handleForgetNetwork = useCallback(() => {
    if (network === undefined) {
      return;
    }
    onForgetNetwork(network.ssid);
    handleCloseNetworkDialog();
  }, [network, onForgetNetwork, handleCloseNetworkDialog]);
  return (
    <SlidingDrawer
      platform={platform}
      globalBottomPadding={globalBottomPadding}
      open={dialogState !== WifiDialogState.Closed}
      title={
        network === undefined
          ? t`Join other network`
          : dialogState === WifiDialogState.Join
            ? t`Enter password`
            : t`Forget network`
      }
      hidePuller
      hideDivider
      handleOpen={() => {}}
      handleClose={handleCloseNetworkDialog}
    >
      <Box display="flex" flexDirection="column" mx={3} mb={3} mt={1} gap={1} alignItems="center">
        <Paper elevation={4} sx={{ width: "100%", mb: 2, p: 2 }}>
          {network === undefined ? (
            <TextField
              fullWidth
              label={t`Network name`}
              variant="filled"
              type="text"
              sx={{ mb: 1 }}
              value={networkInput}
              onChange={handleNetworkNameChange}
            />
          ) : (
            <Box p={2} px={1} display="flex" flexDirection="column" gap={1}>
              <Typography variant="ui-base">
                <Trans>Network: {network.ssid}</Trans>
              </Typography>
              <Typography variant="ui-base">
                <Trans>Security: {getSecurityTypeName(network.securityType)}</Trans>
              </Typography>
            </Box>
          )}
          {dialogState === WifiDialogState.Join && network?.securityType !== 0 && (
            <TextField
              fullWidth
              label={t`Network password`}
              variant="filled"
              type={showNetworkPassword ? "text" : "password"}
              value={networkPassword}
              onChange={handleNetworkPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNetworkPassword(!showNetworkPassword)}
                      edge="end"
                    >
                      {showNetworkPassword ? <ViewOffFilled size={24} /> : <ViewFilled size={24} />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Paper>
        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={
            dialogState === WifiDialogState.Forget
              ? handleForgetNetwork
              : network === undefined
                ? handleConnectHiddenNetwork
                : handleConnectNetwork
          }
        >
          {dialogState === WifiDialogState.Forget ? <Trans>Forget</Trans> : <Trans>Join</Trans>}
        </Button>
        <Button
          fullWidth
          variant="contained"
          size="large"
          sx={{ color: palette.secondary.contrastText, background: palette.secondary.main }}
          onClick={handleCloseNetworkDialog}
        >
          <Trans>Cancel</Trans>
        </Button>
      </Box>
    </SlidingDrawer>
  );
};

export default WifiDialog;
